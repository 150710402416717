.procedure-navbar {
  background-color:whitesmoke;
  /*background-image: linear-gradient(white, rgba(0,0,0,0.1)); */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  box-shadow: 3px 5px 10px rgba(0,0,0,0.2);
  height:65px;
  
  top:0;
  box-shadow: 5px 5px 5px rgba(0,0,0,0.2);
  z-index:1000;
  
}

.procedure-form-width{
  width: 150px !important;
  height: 40px !important;
  margin-left: 10px;
  margin-right: 10px;
}


.procedure-form{
  display: flex !important;
  flex-flow: row wrap;
  align-items: center;
  width:"900px"
}

.procedure-auto-suggest {
  width: 250px !important;
  position: absolute;
  /* top: 355px; */
  top: 210px;
  margin-left: 170px;
  z-index: 1900;
}

.select-currency .css-26l3qy-menu {
  z-index: 1900;
}

.suggest-display{
background-color: #F8F8F8;
border-radius: 10px;
padding: 0px;
width: 100%;
height: 200px;
overflow: auto;
box-shadow: 1px 1px 1px #BEBEBE;
border: 1px solid #878787;
}

.rt-thead {
  color: rgb(254, 255, 254) !important;
  font-size: 20px;
  background-color: rgb(13, 197, 197);
}

.suggest-item{
padding: 10px;
text-align: left;
font-weight: 600;
}
.suggest-item:hover{
background-color: #DCDCDC;
cursor: pointer;
}