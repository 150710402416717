.leftsidewindow {
    width: 267px;
    height: 274px;

    border-radius: 10px;
    border: 1px solid #C6C6C6;
    ;

    background-color: #FFFFFF;

}

.searchboxindside {
    height: 34px;
    margin-top: 15px;
    border-radius: 30px;
    border: 1px solid #BDBDBD;

}

.approverequset {
    height: 54px;
    font-size: 13px;
    color: white;
    border-radius: 10px;
    border: 1px solid #6D4482;
    background-color: #6D4482;
    text-align: left;
}

.rejectedrequests {
    font-size: 13px;
    height: 54px;
    color: black;
    border-radius: 10px;
    border: 1px solid #6D4482;
    background-color: #F8F8F8;
    text-align: left;
    margin-top: 10px;
}

.patientlistview {

    width: 948px;
    height: 75px;
    margin-left: 10%;
    margin-top: 10px;
    border-radius: 10px;
    border: 1px solid #C6C6C6;

}

.patientlistview {
    display: "flex";
    align-items: "center";
    margin-bottom: "20px";
}


.facilityouter .container {
    width: 100%;
    /* Use the full width of the parent container */
    max-width: 1200px;
    /* Add a maximum width to prevent it from becoming too wide */
    margin: 0 auto;
    /* Center the container horizontally */
    padding: 20px;
    box-sizing: border-box;
    /* Include padding in the width */
}

/* Media query for smaller screens, e.g., tablets */
@media (max-width: 768px) {
    .container {
        padding: 10px;
        /* Reduce padding for smaller screens */
    }
}

/* Media query for even smaller screens, e.g., mobile phones */
@media (max-width: 480px) {
    .container {
        padding: 5px;
        /* Further reduce padding for mobile screens */
    }
}