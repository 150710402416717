body {
    background-color: #f9f9fa
}

.padding {
    padding: 3rem !important;    
}
.padding1 {
    padding: 1rem !important;    
}

.hospdetails{
    background-color: #f9f9fa;
    min-height:20 rem;

}
/* 
.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    box-shadow: 0 1px 20px 0 #9ADFCC;
    border: none;
    margin-bottom: 30px;
    height: 30rem;
    background-color: rgb(255, 255, 255);
} */
.mb-2{
    padding: 20px;
    margin-top: 30px;
}
.m-r-0 {
    margin-right: 0px
}

.m-l-0 {
    margin-left: 0px
}

.user-card-full .user-profile {
    border-radius: 5px 0 0 5px;
    height : 30rem;
}

.bg-c-lite-green {
    background: -webkit-gradient(linear, left top, right top, from(#f29263), to(#ee5a6f));
    background: linear-gradient(to right, #ee5a6f, #f29263)
}

.user-profile {
    padding: 20px 0;
    width:30rem;
}

/* .card-block {
    padding: 20px;

} */

.m-b-25 {
    margin-bottom: 25px
}

.img-radius {
    border-radius: 50% ;
    width : 200px;
    height: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    
}

h6 {
    font-size: 14px
}

/* .card .card-block p {
    line-height: 25px
} */

@media only screen and (min-width: 1400px) {
    p {
        font-size: 14px
    }
}

/* .card-block {
    overflow: hidden;
} */

.b-b-default {
    border-bottom: 1px solid #e0e0e0
}

.m-b-20 {
    margin-bottom: 20px
}

.p-b-5 {
    padding-bottom: 5px !important
}

/* .card .card-block p {
    line-height: 25px
} */

.m-b-10 {
    margin-bottom: 10px
}

.text-muted {
    color: #919aa3 !important
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0
}

.f-w-600 {
    font-weight: 600
}

.m-b-20 {
    margin-bottom: 20px
}

.m-t-40 {
    margin-top: 20px
}

.p-b-5 {
    padding-bottom: 5px !important
}

.m-b-10 {
    margin-bottom: 10px
}

.m-t-40 {
    margin-top: 20px
}

.user-card-full .social-link li {
    display: block
}

.user-card-full .social-link li a {
    font-size: 20px;
    margin: 0 10px 0 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.check-class {
    display: inline-block;
    width: 950px;
    margin-left: 480px;
    position: relative;
    top: -630px;
}
.check-class1 {
    display: flex;
    flex-direction: column;
    width: 950px;
    margin-left: 480px;
    position: relative;
    top: -630px;
}
.p-size{
    font-size: large;
    font-weight: bolder;
}
.span-size{
    font-size: medium;
}