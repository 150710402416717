.enquirycontainer{
    box-shadow: 0px 0px 4px 3px rgba(135, 135, 135, 0.25);
    border:1px solid #C3CAD1;
    background: rgba(255, 255, 255, 1);
border-radius: 10px;
}
.enquiryheader{
    text-align: center;
color: #245693!important;
font-weight: bolder;
margin-top: 10px;
font-size: large;
}
.enquiryinput{
 height:40px;
    border:1px solid #C3CAD1;
    background: rgba(255, 255, 255, 1);
border-radius: .25rem;
width:100%
}
.alerttext:first-letter{
    text-transform: capitalize;
  
  }
.enquiryinputtext{
    height:100px;
    border:1px solid #C3CAD1;
    background: rgba(255, 255, 255, 1);
border-radius: 10px;
width:100%
}
.enquiryimage{
    border:1px solid #C3CAD1;
    background: rgba(255, 255, 255, 1);
    border-radius: .25rem;
    width:100%;
    height: 180px;
    justify-content: center;
    position: relative;
}
.avatarenq{
    
    width: 202.96px;
    height: 147.65px;
    border: 6px solid white;
    background-color: #f0f0f0;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
  }
  .avatar-admission{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .closebutton-admission{
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    color: red;
  }

  .error-validationmsg{
    color: #ffa700;
  background-color: white;
  box-shadow: 4px 4px 4px rgba(0,0,0,0.1);
  padding: 4px;
  border-radius: 4px;
  z-index: 1000;
  width: 90%;
}
.primary-enquiry{
    padding:0 10px 0 10px;
    margin: 0 0 10px 0;
}

.primary-enquiry .react-tel-input{
    padding: 0;
    margin:0;
}
.enquirycontainer span{
    padding:0;
    margin-bottom: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    color: #000000;
}