/*
@File: Drodo Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Top Header Area CSS
** - Navbar Area CSS
** - Search Overlay CSS
** - Main Banner Area CSS
** - About Area CSS
** - Banner Categories Area CSS
** - Categories Area CSS
** - Products Area CSS
** - Products Details Area CSS
** - Cart Area CSS
** - Checkout Area CSS
** - Woocommerce Widget Area CSS
** - Products Promotions Area CSS
** - Feedback Area CSS
** - Why Choose Us Area CSS
** - Hot Deal Area CSS
** - Brands Area CSS
** - Blog Area CSS
** - Blog Details Area CSS
** - Facility Area CSS
** - Gallery Area CSS
** - History Area CSS
** - Doctors Area CSS
** - Funfacts Area CSS
** - Page Title Area CSS
** - Pagination CSS
** - Widget Sidebar CSS
** - Profile Authentication Area CSS
** - 404 Error Area CSS
** - Coming Soon Area CSS
** - Purchase Guide Area CSS
** - Privacy Policy Area CSS
** - Terms Of Service Area CSS
** - Customer Service Area CSS
** - FAQ Area CSS
** - Tracking Order Area CSS
** - Contact Area CSS
** - Footer Area CSS
** - Go Top CSS
** - Modal CSS
*/
/*================================================
Default CSS
=================================================*/
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&display=swap");
:root {
  --fontFamily: 'Open Sans', sans-serif;
  --fontFamily2: 'Hind Siliguri', sans-serif;
  --mainColor: #2a96ff;
  --optionalColor: #696969;
  --whiteColor: #ffffff;
  --blackColor: #111111;
  --fontSize: 15px;
  --transition: .5s;
}

body {
  padding: 0;
  margin: 0;
  font-size: var(--fontSize);
  font-family: var(--fontFamily);
}

.web-ordering .h1 .h2 .h3 .h4 .h5 .h6 h1 h2 h3 h4 h5 h6 {
  font-family: var(--fontFamily2);
  font-weight: 700;
}

.web-ordering a {
  color: var(--blackColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-decoration: none;
  outline: 0 !important;
}

.web-ordering a:hover {
  color: var(--mainColor);
  text-decoration: none;
}

:focus {
  outline: 0 !important;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

img {
  max-width: 100%;
  height: auto;
}

.web-ordering p {
  color: var(--optionalColor);
  font-size: var(--fontSize);
  margin-bottom: 15px;
  line-height: 1.8;
}

p:last-child {
  margin-bottom: 0;
}

.ptb-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.ptb-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.container {
  max-width: 1425px;
  border:5px
}

.bg-f7f8fa {
  background-color: #f7f8fa;
  
}

/*default-btn*/
.default-btn {
  display: inline-block;
  position: relative;
  z-index: 1;
  border: none;
  background-color:#F0A639 ;
  color: var(--whiteColor);
  border-radius: 5px;
  -webkit-box-shadow: unset;
          box-shadow: unset;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-align: center;
 
 width:190px;
  padding: 15px;
  font-size: 15px;
  font-weight: 600;
}
.default-btn:hover {
 
  color: #F0A639  !important;
}
.default-btn-white:hover {
 
  color: #F0A639!important;
}
.my-button:hover {
  background-color: #F0A639;
  color: #ffffff;
  border-color:#F0A639 ;
}

.my-button {
  font-size: 12px !important;
  background-color: #F0A639;
  height: 32px;
  font-weight: 700;
  width: 80px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color:#F0A639 ;
  margin-left: 30%;
  color: #fff;
}




.default-btn i {
  position: absolute;
  left: 15px;
  top: 11px;
  font-size: 19px;
  color: var(--whiteColor);
  line-height: 1;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.default-btn:hover {
  background-color: var(--whiteColor);
  color: #fff;
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.default-btn:hover i {
  color: #fff;
}

/*section-title*/
.section-title {
  margin-bottom: 40px;
  position: relative;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 8px;
}

.section-title::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100px;
  background-color: var(--mainColor);
  height: 1px;
}

.section-title h2 {
  margin-bottom: 0;
  font-size: 30px;
}

/*form-control*/
.form-control {
  height: 50px;
  color: var(--blackColor);
  -webkit-box-shadow: unset !important;
          box-shadow: unset !important;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 3px;
  padding: 1px 0 0 15px;
  font-size: 15px;
  font-weight: 500;
}

.form-control::-webkit-input-placeholder {
  color: #7e7e7e;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.form-control:-ms-input-placeholder {
  color: #7e7e7e;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.form-control::-ms-input-placeholder {
  color: #7e7e7e;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.form-control::placeholder {
  color: #7e7e7e;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.form-control:focus {
  border-color: var(--mainColor);
  background-color: transparent;
}

.form-control:focus::-webkit-input-placeholder {
  color: transparent;
}

.form-control:focus:-ms-input-placeholder {
  color: transparent;
}

.form-control:focus::-ms-input-placeholder {
  color: transparent;
}

.form-control:focus::placeholder {
  color: transparent;
}

textarea.form-control {
  height: auto;
  padding-top: 15px;
}

/*================================================
Top Header Area CSS
=================================================*/
.top-header {
  background-color: #f7f8fa;
  padding-top: 5px;
  padding-bottom: 12px;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.top-header-contact-info {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.top-header-contact-info li {
  display: inline-block;
  margin-right: 15px;
  position: relative;
  padding-left: 24px;
}

.top-header-contact-info li a {
  font-weight: 600;
  display: inline-block;
}

.top-header-contact-info li i {
  position: relative;
  left: 0;
  top: 2.5px;
  color: var(--mainColor);
  font-size: 17px;
}

.top-header-contact-info li:last-child {
  margin-right: 0;
}

.top-header-menu {
  text-align: right;
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.top-header-menu li {
  display: inline-block;
  margin-left: 18px;
  position: relative;
}

.top-header-menu li::before {
  content: '';
  position: absolute;
  left: -14px;
  top: 4.5px;
  width: 1.4px;
  height: 14px;
  background-color: #dfdfdf;
}

.top-header-menu li:first-child {
  margin-left: 0;
}

.top-header-menu li:first-child::before {
  display: none;
}

.top-header-menu li:nth-child(2)::before {
  top: 6.5px;
}

.top-header-menu li .language-switcher {
  position: relative;
}

.top-header-menu li .language-switcher .dropdown-toggle {
  padding: 0;
  border: none;
  background-color: transparent;
  color: var(--blackColor);
  font-weight: 600;
  font-size: 14.5px;
}

.top-header-menu li .language-switcher .dropdown-toggle::after {
  display: none;
}

.top-header-menu li .language-switcher .dropdown-toggle img {
  width: 35px;
  border-radius: 3px;
  border: 2px solid var(--whiteColor);
  position: relative;
  top: -2px;
}

.top-header-menu li .language-switcher .dropdown-toggle span {
  display: inline-block;
  margin-left: 5px;
}

.top-header-menu li .language-switcher .dropdown-toggle span i {
  position: relative;
  font-size: 20px;
  top: 4px;
  margin-left: -3px;
}

.top-header-menu li .language-switcher .dropdown-menu {
  padding: 15px;
  float: unset;
  border: none;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0;
  display: block;
  margin-top: 12px;
  opacity: 0;
  visibility: visible;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
}

.top-header-menu li .language-switcher .dropdown-menu .dropdown-item {
  padding: 0;
  color: var(--blackColor);
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 14.5px;
}

.top-header-menu li .language-switcher .dropdown-menu .dropdown-item::before {
  display: none;
}

.top-header-menu li .language-switcher .dropdown-menu .dropdown-item:last-child {
  margin-bottom: 0;
}

.top-header-menu li .language-switcher .dropdown-menu .dropdown-item img {
  width: 40px;
  border-radius: 5px;
  border: 3px solid var(--whiteColor);
}

.top-header-menu li .language-switcher .dropdown-menu .dropdown-item span {
  display: inline-block;
  margin-left: 7px;
}

.top-header-menu li .language-switcher .dropdown-menu .dropdown-item:hover, .top-header-menu li .language-switcher .dropdown-menu .dropdown-item:focus {
  background-color: transparent !important;
}

.top-header-menu li .language-switcher .dropdown-menu .dropdown-item:active, .top-header-menu li .language-switcher .dropdown-menu .dropdown-item.active {
  color: var(--blackColor);
  background-color: transparent;
}

.top-header-menu li .language-switcher .dropdown-menu.show {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.top-header-menu li .currency-switcher {
  position: relative;
}

.top-header-menu li .currency-switcher .dropdown-toggle {
  padding: 0;
  border: none;
  background-color: transparent;
  color: var(--blackColor);
  font-weight: 600;
  font-size: 14.5px;
}

.top-header-menu li .currency-switcher .dropdown-toggle::after {
  display: none;
}

.top-header-menu li .currency-switcher .dropdown-toggle span {
  display: inline-block;
}

.top-header-menu li .currency-switcher .dropdown-toggle span i {
  position: relative;
  font-size: 20px;
  top: 4px;
  margin-left: -3px;
}

.top-header-menu li .currency-switcher .dropdown-menu {
  padding: 15px;
  float: unset;
  border: none;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0;
  display: block;
  margin-top: 12px;
  opacity: 0;
  visibility: visible;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
}

.top-header-menu li .currency-switcher .dropdown-menu .dropdown-item {
  padding: 0;
  color: var(--blackColor);
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 14.5px;
}

.top-header-menu li .currency-switcher .dropdown-menu .dropdown-item::before {
  display: none;
}

.top-header-menu li .currency-switcher .dropdown-menu .dropdown-item:last-child {
  margin-bottom: 0;
}

.top-header-menu li .currency-switcher .dropdown-menu .dropdown-item span {
  display: inline-block;
}

.top-header-menu li .currency-switcher .dropdown-menu .dropdown-item:hover, .top-header-menu li .currency-switcher .dropdown-menu .dropdown-item:focus {
  background-color: transparent !important;
}

.top-header-menu li .currency-switcher .dropdown-menu .dropdown-item:active, .top-header-menu li .currency-switcher .dropdown-menu .dropdown-item.active {
  color: var(--blackColor);
  background-color: transparent;
}

.top-header-menu li .currency-switcher .dropdown-menu.show {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.top-header-menu li a {
  font-weight: 600;
}

/*================================================
Navbar Area CSS
=================================================*/
.drodo-responsive-nav {
  display: none;
}

.drodo-nav {
  background-color: var(--whiteColor);
  position: fixed;
    width: 100%;
    z-index: 999;
    margin-top: 1.2%;
}

.drodo-nav .container-fluid {
  padding-left: 20px;
  padding-right: 20px;
}

.drodo-nav .navbar {
  position: inherit;
  padding-left: 0;
  padding-right: 0;
}

.drodo-nav .navbar .navbar-brand {
  font-size: inherit;
  line-height: 1;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.drodo-nav .navbar ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.drodo-nav .navbar .navbar-nav {
  margin-left: auto;
  margin-right: auto;
}

.drodo-nav .navbar .navbar-nav .nav-item {
  position: relative;
}

.drodo-nav .navbar .navbar-nav .nav-item a {
  color: var(--blackColor);
  font-size: 16.5px;
  font-weight: 600;
  padding-left: 0;
  padding-right: 0;
  padding-top: 1px;
  /* padding-bottom: 9px; */
  margin-left: 13px;
  margin-right: 13px;
}

.drodo-nav .navbar .navbar-nav .nav-item a i {
  font-size: 18px;
  position: relative;
  top: 2px;
  display: inline-block;
  margin-left: -3px;
  margin-right: -3px;
}

.drodo-nav .navbar .navbar-nav .nav-item a:hover, .drodo-nav .navbar .navbar-nav .nav-item a:focus, .drodo-nav .navbar .navbar-nav .nav-item a.active {
  color: black;
}

.drodo-nav .navbar .navbar-nav .nav-item:last-child a {
  margin-right: 0;
}

.drodo-nav .navbar .navbar-nav .nav-item:first-child a {
  margin-left: 0;
}

.drodo-nav .navbar .navbar-nav .nav-item:hover a, .drodo-nav .navbar .navbar-nav .nav-item.active a {
  color: var(--mainColor);
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  background: var(--whiteColor);
  position: absolute;
  border: none;
  top: 67px;
  left: 0;
  width: 250px;
  z-index: 99;
  display: block;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  padding: 0;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  padding: 13px 20px 11px;
  margin: 0;
  position: relative;
  display: block;
  color: #666666;
  border-bottom: 1px dashed #e5e5e5;
  font-size: 15px;
  font-weight: 600;
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li a i {
  margin: 0;
  position: absolute;
  top: 50%;
  font-size: 20px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 10px;
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover, .drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus, .drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: var(--mainColor);
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #515050;
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover, .drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus, .drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: var(--mainColor);
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: 250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #515050;
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--mainColor);
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #515050;
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--mainColor);
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: 250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #515050;
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--mainColor);
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #515050;
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--mainColor);
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: 250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #515050;
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--mainColor);
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--mainColor);
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--mainColor);
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--mainColor);
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--mainColor);
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: var(--mainColor);
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: var(--mainColor);
}

.drodo-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

.drodo-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.drodo-nav .navbar .navbar-nav .nav-item.megamenu {
  position: inherit;
}

.drodo-nav .navbar .navbar-nav .nav-item.megamenu .container {
  display: block;
  -ms-flex-wrap: unset;
      flex-wrap: unset;
  -webkit-box-align: unset;
      -ms-flex-align: unset;
          align-items: unset;
  -webkit-box-pack: unset;
      -ms-flex-pack: unset;
          justify-content: unset;
}

.drodo-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu {
  width: 100%;
  margin-top: 8px;
  position: absolute;
  top: auto;
  left: 0;
  padding: 30px 0;
  -webkit-transform: unset !important;
          transform: unset !important;
}

.drodo-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .submenu-title {
  color: var(--blackColor);
  position: relative;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 8px;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 25px;
  font-size: 15.5px;
  font-weight: 600;
}

.drodo-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .submenu-title::before {
  width: 30px;
  height: 1px;
  content: '';
  position: absolute;
  left: 0;
  bottom: -1px;
  background-color: var(--mainColor);
}

.drodo-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .submenu-title:first-child {
  margin-top: 0;
}

.drodo-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu a {
  border-bottom: none !important;
}

.drodo-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu li a {
  margin-top: 14px;
  color: #666666;
  padding: 0;
  font-weight: 600;
}

.drodo-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu li a:hover, .drodo-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu li a.active {
  color: var(--mainColor);
}

.drodo-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu .aside-trending-products {
  overflow: hidden;
  position: relative;
  text-align: center;
}

.drodo-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu .aside-trending-products img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.drodo-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu .aside-trending-products .category {
  position: absolute;
  left: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  right: 0;
  bottom: 0;
  background-color: var(--whiteColor);
  padding: 15px;
  margin-left: 10px;
  margin-right: 10px;
}

.drodo-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu .aside-trending-products .category h4 {
  text-transform: uppercase;
  margin-bottom: 0;
  position: relative;
  z-index: 1;
  font-size: var(--fontSize);
  font-weight: 600;
}

.drodo-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu .aside-trending-products .link-btn {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.drodo-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu .aside-trending-products:not(:first-child) {
  margin-top: 15px;
}

.drodo-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu .aside-trending-products:hover img {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.drodo-nav .navbar .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu .aside-trending-products:hover .category {
  bottom: 10px;
}

.drodo-nav .navbar .others-option .option-item {
  display: inline-block;
  margin-left: 15px;
  position: relative;
  z-index: 3;
}

.drodo-nav .navbar .others-option .option-item:first-child {
  margin-left: 0;
}

.drodo-nav .navbar .others-option .option-item .search-btn-box {
  position: relative;
  top: 1px;
}

.drodo-nav .navbar .others-option .option-item .search-btn-box .search-btn {
  font-size: 25px;
  cursor: pointer;
  color: var(--blackColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.drodo-nav .navbar .others-option .option-item .search-btn-box .search-btn:hover {
  color: var(--mainColor);
}

.drodo-nav .navbar .others-option .option-item .cart-btn {
  font-size: 25px;
  position: relative;
  line-height: 1;
}

.drodo-nav .navbar .others-option .option-item .cart-btn a {
  display: inline-block;
  position: relative;
}

.drodo-nav .navbar .others-option .option-item .cart-btn a span {
  position: absolute;
  right: -4px;
  top: -4px;
  width: 14px;
  height: 14px;
  text-align: center;
  line-height: 13.9px;
  border-radius: 50%;
  color: var(--whiteColor);
  background-color: red;
  font-size: 10px;
  font-weight: 600;
}

.drodo-nav .navbar .others-option .option-item .wishlist-btn {
  font-size: 25px;
  position: relative;
  line-height: 1;
  top: 1px;
}

.drodo-nav .navbar .others-option .option-item .wishlist-btn a {
  display: inline-block;
}

.navbar-area.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
          box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  background-color: var(--whiteColor) !important;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
          animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0;
  padding-bottom: 0;
}

@media only screen and (max-width: 991px) {
  .drodo-responsive-nav {
    display: block;
  }
  .drodo-responsive-nav .drodo-responsive-menu {
    position: relative;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .mean-nav {
    margin-top: 51px;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .mean-nav ul {
    font-size: 15px;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .mean-nav ul li a.active {
    color: var(--mainColor);
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .mean-nav ul li li a {
    font-size: 14px;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .navbar-nav {
    overflow-y: scroll;
    height: 306px;
    -webkit-box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .navbar-nav .nav-item.megamenu .dropdown-menu .nav-item {
    overflow: hidden;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .navbar-nav .nav-item.megamenu .dropdown-menu .nav-item .container {
    padding-left: 0;
    padding-right: 0;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .navbar-nav .nav-item.megamenu .dropdown-menu .nav-item .row {
    display: block;
    -ms-flex-wrap: unset;
        flex-wrap: unset;
    margin-left: 0;
    margin-right: 0;
    margin-top: -40px;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .navbar-nav .nav-item.megamenu .dropdown-menu .nav-item .row .col {
    -ms-flex-preferred-size: unset;
        flex-basis: unset;
    -webkit-box-flex: unset;
        -ms-flex-positive: unset;
            flex-grow: unset;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-top: 20px;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .navbar-nav .nav-item.megamenu .dropdown-menu .nav-item .row .col:last-child {
    margin-bottom: 15px;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .navbar-nav .nav-item.megamenu .dropdown-menu .nav-item a {
    width: 100%;
    float: unset;
    display: block;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .navbar-nav .nav-item.megamenu .dropdown-menu .submenu-title {
    color: var(--blackColor);
    position: relative;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 8px;
    text-transform: uppercase;
    margin-bottom: 5px;
    margin-top: 25px;
    margin-left: 15px;
    margin-right: 15px;
    font-size: 13.5px;
    font-weight: 600;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .navbar-nav .nav-item.megamenu .dropdown-menu .submenu-title::before {
    width: 30px;
    height: 1px;
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    background-color: var(--mainColor);
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .navbar-nav .nav-item.megamenu .dropdown-menu .submenu-title:first-child {
    margin-top: 5px;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .navbar-nav .nav-item.megamenu .dropdown-menu a {
    border-bottom: none !important;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .navbar-nav .nav-item.megamenu .dropdown-menu .mean-expand {
    display: none !important;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu {
    width: auto;
    overflow: hidden;
    display: block !important;
    padding-left: 15px;
    padding-right: 15px;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu li a {
    border: none;
    margin-top: 15px;
    color: #666666;
    padding: 0;
    font-weight: 400;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu li a:hover, .drodo-responsive-nav .drodo-responsive-menu.mean-container .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu li a.active {
    color: var(--mainColor);
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu .aside-trending-products {
    overflow: hidden;
    position: relative;
    text-align: center;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu .aside-trending-products img {
    -webkit-transition: var(--transition);
    transition: var(--transition);
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu .aside-trending-products .category {
    position: absolute;
    left: 0;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    right: 0;
    bottom: 0;
    background-color: var(--whiteColor);
    padding: 15px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu .aside-trending-products .category h4 {
    text-transform: uppercase;
    margin-bottom: 0;
    position: relative;
    z-index: 1;
    font-size: var(--fontSize);
    font-weight: 600;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu .aside-trending-products .link-btn {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 3;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu .aside-trending-products:not(:first-child) {
    margin-top: 15px;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu .aside-trending-products:hover img {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .navbar-nav .nav-item.megamenu .dropdown-menu .megamenu-submenu .aside-trending-products:hover .category {
    bottom: 10px;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .navbar-nav .nav-item.megamenu .dropdown-menu .brand-slides {
    margin-top: 0;
    border-top: none;
    border-bottom: none;
    border-left: none;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .navbar-nav .nav-item.megamenu .dropdown-menu .brand-slides .brand-item a {
    display: block !important;
    padding: 20px !important;
    width: auto !important;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .others-option {
    position: absolute;
    right: 50px;
    top: 8px;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .others-option .option-item {
    display: inline-block;
    margin-left: 12px;
    position: relative;
    z-index: 3;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .others-option .option-item:first-child {
    margin-left: 0;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .others-option .option-item .search-btn-box {
    position: relative;
    top: 2px;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .others-option .option-item .search-btn-box .search-btn {
    font-size: 20px;
    cursor: pointer;
    color: var(--blackColor);
    -webkit-transition: var(--transition);
    transition: var(--transition);
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .others-option .option-item .search-btn-box .search-btn:hover {
    color: var(--mainColor);
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .others-option .option-item .cart-btn {
    font-size: 20px;
    position: relative;
    line-height: 1;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .others-option .option-item .cart-btn a {
    display: inline-block;
    position: relative;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .others-option .option-item .cart-btn a span {
    position: absolute;
    right: -3px;
    top: -3px;
    width: 12px;
    height: 12px;
    text-align: center;
    line-height: 11.9px;
    border-radius: 50%;
    color: var(--whiteColor);
    background-color: red;
    font-size: 9px;
    font-weight: 600;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .others-option .option-item .wishlist-btn {
    font-size: 20px;
    position: relative;
    line-height: 1;
    margin-right: -2px;
    top: 1px;
  }
  .drodo-responsive-nav .drodo-responsive-menu.mean-container .others-option .option-item .wishlist-btn a {
    display: inline-block;
  }
  .drodo-responsive-nav .mean-container a.meanmenu-reveal {
    color: var(--blackColor);
  }
  .drodo-responsive-nav .mean-container a.meanmenu-reveal span {
    background: var(--blackColor);
  }
  .drodo-responsive-nav .logo {
    position: relative;
    width: 50%;
    z-index: 999;
  }
  .drodo-nav {
    display: contents;
  }
  .navbar-area {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .navbar-area.is-sticky {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

/*================================================
Search Overlay CSS
=================================================*/
.search-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.search-overlay .search-overlay-layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.search-overlay .search-overlay-layer:nth-child(1) {
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.search-overlay .search-overlay-layer:nth-child(2) {
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-transition: all 0.3s ease-in-out 0.3s;
  transition: all 0.3s ease-in-out 0.3s;
}

.search-overlay .search-overlay-layer:nth-child(3) {
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: all 0.9s ease-in-out 0.6s;
  transition: all 0.9s ease-in-out 0.6s;
}

.search-overlay .search-overlay-close {
  position: absolute;
  top: 40px;
  right: 40px;
  width: 50px;
  z-index: 2;
  text-align: center;
  cursor: pointer;
  padding: 10px;
  -webkit-transition: all 0.9s ease-in-out 1.5s;
  transition: all 0.9s ease-in-out 1.5s;
  opacity: 0;
  visibility: hidden;
}

.search-overlay .search-overlay-close .search-overlay-close-line {
  width: 100%;
  height: 3px;
  float: left;
  margin-bottom: 5px;
  background-color: var(--whiteColor);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.search-overlay .search-overlay-close .search-overlay-close-line:nth-child(1) {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.search-overlay .search-overlay-close .search-overlay-close-line:nth-child(2) {
  margin-top: -7px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.search-overlay .search-overlay-close:hover .search-overlay-close-line {
  background: var(--mainColor);
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.search-overlay .search-overlay-form {
  -webkit-transition: all 0.9s ease-in-out 1.4s;
  transition: all 0.9s ease-in-out 1.4s;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
  z-index: 2;
  max-width: 500px;
  width: 500px;
}

.search-overlay .search-overlay-form form {
  position: relative;
}

.search-overlay .search-overlay-form form .input-search {
  display: block;
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 30px;
  color: var(--blackColor);
  padding: 0 0 0 25px;
}

.search-overlay .search-overlay-form form .input-search::-webkit-input-placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: .5px;
  color: var(--blackColor);
}

.search-overlay .search-overlay-form form .input-search:-ms-input-placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: .5px;
  color: var(--blackColor);
}

.search-overlay .search-overlay-form form .input-search::-ms-input-placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: .5px;
  color: var(--blackColor);
}

.search-overlay .search-overlay-form form .input-search::placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: .5px;
  color: var(--blackColor);
}

.search-overlay .search-overlay-form form .input-search:focus::-webkit-input-placeholder {
  color: transparent;
}

.search-overlay .search-overlay-form form .input-search:focus:-ms-input-placeholder {
  color: transparent;
}

.search-overlay .search-overlay-form form .input-search:focus::-ms-input-placeholder {
  color: transparent;
}

.search-overlay .search-overlay-form form .input-search:focus::placeholder {
  color: transparent;
}

.search-overlay .search-overlay-form form button {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 50px;
  color: var(--whiteColor);
  height: 50px;
  border-radius: 50%;
  background-color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border: none;
  font-size: 25px;
  line-height: 47px;
}

.search-overlay .search-overlay-form form button:hover {
  background-color: var(--blackColor);
  color: var(--whiteColor);
}

.search-overlay.search-overlay-active.search-overlay {
  opacity: 1;
  visibility: visible;
}

.search-overlay.search-overlay-active.search-overlay .search-overlay-layer {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.search-overlay.search-overlay-active.search-overlay .search-overlay-close {
  opacity: 1;
  visibility: visible;
}

.search-overlay.search-overlay-active.search-overlay .search-overlay-form {
  opacity: 1;
  visibility: visible;
}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner {
  padding-top: 70px;
  padding-bottom: 70px;
}

.main-banner-content .sub-title {
  display: block;
  font-weight: 600;
  margin-bottom: 12px;
  color: var(--mainColor);
}

.main-banner-content h1 {
  margin-bottom: 12px;
  font-size: 55px;
  line-height: 1.1;
}

.main-banner-content h1 span {
  color: var(--mainColor);
}

.main-banner-content p {
  font-size: 16px;
}

.main-banner-content .default-btn {
  margin-top: 10px;
}

.single-banner-item {
  background-color: #ebf7fa;
  height: 620px;
}

.banner-content .sub-title {
  display: block;
  font-weight: 600;
  margin-bottom: 12px;
  color: var(--mainColor);
}

.banner-content h1 {
  margin-bottom: 12px;
  font-size: 64px;
  line-height: 1.1;
}

.banner-content h1 span {
  color: var(--mainColor);
}

.banner-content p {
  font-size: 16px;
}

.banner-content .btn-box {
  margin-top: 30px;
}

.banner-content .btn-box .price {
  display: block;
  color: var(--mainColor);
  margin-left: 25px;
  font-size: 20px;
  font-weight: 600;
}

.banner-image {
  text-align: center;
}

.banner-image img {
  width: auto !important;
  display: inline-block !important;
}

.owl-item.active .banner-content .sub-title {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-name: fadeInLeft;
          animation-name: fadeInLeft;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

.owl-item.active .banner-content h1 {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-name: fadeInLeft;
          animation-name: fadeInLeft;
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}

.owl-item.active .banner-content p {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-name: fadeInLeft;
          animation-name: fadeInLeft;
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}

.owl-item.active .banner-content .btn-box {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-name: fadeInLeft;
          animation-name: fadeInLeft;
  -webkit-animation-delay: 1.1s;
          animation-delay: 1.1s;
}

.owl-item.active .banner-image img {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-name: fadeInRight;
          animation-name: fadeInRight;
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}

.home-slides.owl-theme .owl-nav {
  margin-top: 0;
}

.home-slides.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  left: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding: 0;
  margin: 0;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  /* background-color: #dce9ec; */
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--blackColor);
  border-radius: 50%;
  font-size: 25px;
  /* padding-right: 5px !important; */
}

.home-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 20px;
  padding-left: 5px !important;
  padding-right: 0 !important;
}

.home-slides.owl-theme .owl-nav [class*=owl-]:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.home-wrapper-area {
  background-color: #080e32;
  position: relative;
  z-index: 1;
  padding-top: 95px;
  padding-bottom: 95px;
}

.wrapper-content .sub-title {
  display: block;
  margin-bottom: 15px;
  color: var(--mainColor);
  font-size: 15.5px;
  font-weight: 700;
}

.wrapper-content h1 {
  margin-bottom: 20px;
  font-size: 50px;
  color: var(--whiteColor);
  line-height: 1.3;
}

.wrapper-content p {
  color: var(--whiteColor);
  opacity: 0.85;
  max-width: 520px;
}

.wrapper-content .default-btn {
  margin-top: 15px;
}

.wrapper-content .default-btn:hover {
  -webkit-box-shadow: unset !important;
          box-shadow: unset !important;
}

.owl-item.active .wrapper-content .sub-title {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-name: fadeInLeft;
          animation-name: fadeInLeft;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

.owl-item.active .wrapper-content h1 {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-name: fadeInLeft;
          animation-name: fadeInLeft;
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}

.owl-item.active .wrapper-content p {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-name: fadeInLeft;
          animation-name: fadeInLeft;
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}

.owl-item.active .wrapper-content .default-btn {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-name: fadeInLeft;
          animation-name: fadeInLeft;
  -webkit-animation-delay: 1.1s;
          animation-delay: 1.1s;
}

.owl-item.active .wrapper-image img {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-name: fadeInRight;
          animation-name: fadeInRight;
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}

.home-slides-two.owl-theme .owl-nav {
  margin-top: 0;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  margin-left: -90px;
  bottom: 0;
}

.home-slides-two.owl-theme .owl-nav [class*=owl-] {
  background-color: transparent;
  border: 1px solid #9b9dac;
  margin: 0 7px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: #9b9dac;
  font-size: 22px;
  text-align: center;
  line-height: 40px;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-name: fadeInLeft;
          animation-name: fadeInLeft;
  -webkit-animation-delay: 1.3s;
          animation-delay: 1.3s;

  
}

.home-slides-two.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: var(--mainColor);
  border-color: var(--mainColor);
  color: var(--whiteColor);
}

.home-slides-two.owl-theme .owl-dots {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-top: 0;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-name: fadeInRight;
          animation-name: fadeInRight;
  -webkit-animation-delay: 1.3s;
          animation-delay: 1.3s;
       
}

.owl-theme .owl-dots .owl-dot.active {
  background-color: #869791; /* Change the active dot color to blue */
  border-radius: 15px;
}

.home-slides-two.owl-theme .owl-dots .owl-dot span {
  width: 15px;
  height: 15px;
  margin: 0 8px 0 0;
  background: transparent;
  border: 1px solid var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 50%;
  position: relative;
}

.home-slides-two.owl-theme .owl-dots .owl-dot span::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #F0A639;
  border-radius: 50%;
  margin: 2px;
  opacity: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  visibility: hidden;
}

.home-slides-two.owl-theme .owl-dots .owl-dot:hover span, .home-slides-two.owl-theme .owl-dots .owl-dot.active span {
  border-color: var(--mainColor);
}

.home-slides-two.owl-theme .owl-dots .owl-dot:hover span::before, .home-slides-two.owl-theme .owl-dots .owl-dot.active span::before {
  opacity: 1;
  visibility: visible;
}

.banner-area {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 84px;
  padding-bottom: 124px;
  padding-left: 70px;
  padding-right: 70px;
}

.banner-area.bg-1 {
  background-image: url(../../assets/img/main-banner1.jpg);
}

.banner-area.bg-2 {
  background-image: url(../../assets/img/main-banner2.jpg);
}

.banner-area .banner-content {
  max-width: 600px;
}

.banner-area .banner-content p {
  max-width: 350px;
}

.home-slides-three.owl-theme .owl-nav.disabled + .owl-dots {
  text-align: left;
  margin-top: 0;
}

.home-slides-three.owl-theme .owl-dots {
  position: absolute;
  left: 70px;
  bottom: 20px;
}

.home-slides-three.owl-theme .owl-dots .owl-dot {
  margin-left: 3px;
  margin-right: 3px;
}

.home-slides-three.owl-theme .owl-dots .owl-dot span {
  width: 15px;
  height: 15px;
  margin: 0;
  border: 1px solid var(--mainColor);
  background: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 50%;
  position: relative;
}

.home-slides-three.owl-theme .owl-dots .owl-dot span::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 3px;
  background-color:#F0A639;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
}



.home-slides-three.owl-theme .owl-dots .owl-dot:hover span::before, .home-slides-three.owl-theme .owl-dots .owl-dot.active span::before {
  opacity: 1;
  visibility: visible;
}

.megamenu-container {
  position: relative;
}

.megamenu-container .megamenu-title {
  background-color: var(--mainColor);
  text-transform: uppercase;
  color: var(--whiteColor);
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.megamenu-container .megamenu-category {
  border: 1px solid #eeeeee;
  border-top: none;
  margin-top: 5px;
}

.megamenu-container .megamenu-category .nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  display: block !important;
  -ms-flex-wrap: unset;
      flex-wrap: unset;
}

.megamenu-container .megamenu-category .nav .nav-item {
  position: relative;
}

.megamenu-container .megamenu-category .nav .nav-item .nav-link {
  background-color: #f5f5f5;
  border-top: 1px solid #eeeeee;
  color: var(--blackColor);
  position: relative;
  font-size: 15px;
  font-weight: 600;
  padding-left: 20px;
  padding-right: 25px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.megamenu-container .megamenu-category .nav .nav-item .nav-link i {
  position: absolute;
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.megamenu-container .megamenu-category .nav .nav-item .nav-link:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border-color: var(--mainColor);
}

.megamenu-container .megamenu-category .nav .nav-item .dropdown-menu {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  display: block !important;
  float: unset;
  background-color: var(--whiteColor);
  width: 250px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border: none;
  left: 100%;
  top: 0;
  margin: 15px 0 0;
  border-radius: 0;
  padding: 0;
}

.megamenu-container .megamenu-category .nav .nav-item .dropdown-menu .nav-item .nav-link {
  background-color: var(--whiteColor);
}

.megamenu-container .megamenu-category .nav .nav-item .dropdown-menu .nav-item .nav-link:hover {
  background-color: var(--mainColor);
}

.megamenu-container .megamenu-category .nav .nav-item .dropdown-menu.megamenu {
  width: 650px;
  padding: 30px;
}

.megamenu-container .megamenu-category .nav .nav-item .dropdown-menu.megamenu h3 {
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 700;
}

.megamenu-container .megamenu-category .nav .nav-item .dropdown-menu.megamenu .megamenu-nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.megamenu-container .megamenu-category .nav .nav-item .dropdown-menu.megamenu .megamenu-nav li {
  margin-bottom: 11px;
}

.megamenu-container .megamenu-category .nav .nav-item .dropdown-menu.megamenu .megamenu-nav li a {
  display: inline-block;
  font-size: 15px;
  color: var(--optionalColor);
}

.megamenu-container .megamenu-category .nav .nav-item .dropdown-menu.megamenu .megamenu-nav li a:hover {
  color: var(--mainColor);
}

.megamenu-container .megamenu-category .nav .nav-item .dropdown-menu.megamenu .megamenu-nav li:last-child {
  margin-bottom: 0;
}

.megamenu-container .megamenu-category .nav .nav-item:hover .dropdown-menu {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
}

@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes rotatemetwo {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

@keyframes rotatemetwo {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

/*================================================
About Area CSS
=================================================*/
.about-content {
  padding-right: 15px;
}

.about-content .sub-title {
  display: block;
  font-weight: 600;
  margin-bottom: 12px;
  color: var(--mainColor);
}

.about-content h2 {
  margin-bottom: 15px;
  font-size: 30px;
}

.about-content .quotes-box {
  margin-top: 20px;
}

.about-content .quotes-box span {
  display: block;
  color: var(--optionalColor);
  margin-bottom: 15px;
}

.about-content .quotes-box p {
  text-align: center;
  color: var(--blackColor);
  font-weight: 600;
  position: relative;
  z-index: 1;
  padding-top: 30px;
  padding-bottom: 25px;
}

.about-content .quotes-box p::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "\f111";
  color: var(--optionalColor);
  opacity: 0.5;
  line-height: 1;
  font-family: Flaticon;
  font-size: 25px;
}

.about-content .quotes-box p::after {
  position: absolute;
  right: 0;
  bottom: 0;
  content: "\f111";
  color: var(--optionalColor);
  opacity: 0.5;
  line-height: 1;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  font-family: Flaticon;
  font-size: 25px;
}

.about-image {
  text-align: center;
  position: relative;
  z-index: 1;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.about-image .shape {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.about-image .shape img:nth-child(1) {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.about-image .shape img:nth-child(2) {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

/*================================================
Banner Categories Area CSS
=================================================*/
.single-banner-categories-box {
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}

.single-banner-categories-box img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-banner-categories-box .content {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding: 25px;
}

.single-banner-categories-box .content .sub-title {
  display: block;
  margin-bottom: 10px;
  color: var(--optionalColor);
  font-weight: 600;
}

.single-banner-categories-box .content h3 {
  font-size: 25px;
  margin-bottom: 0;
}

.single-banner-categories-box .content .btn-box {
  margin-top: 15px;
}

.single-banner-categories-box .content .btn-box .default-btn {
  font-size: 15px;
}

.single-banner-categories-box .content .btn-box .price {
  display: block;
  color: var(--mainColor);
  margin-left: 15px;
  font-size: 20px;
  font-weight: 600;
}

.single-banner-categories-box:hover img {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.categories-box {
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
}

.categories-box img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.categories-box .link-btn {
  display: block;
  width: 100%;
  height: 100%;
  z-index: 2;
  left: 0;
  top: 0;
  position: absolute;
}

.categories-box .content {
  position: absolute;
  top: 20px;
  left: 20px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: var(--whiteColor);
  -webkit-box-shadow: 1px 1px 3px rgba(136, 136, 136, 0.19);
          box-shadow: 1px 1px 3px rgba(136, 136, 136, 0.19);
  padding-top: 10px;
  padding-bottom: 8px;
  padding-left: 25px;
  padding-right: 25px;
}

.categories-box .content h3 {
  margin-bottom: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 20px;
  font-style: italic;
}

.categories-box:hover img {
  -webkit-transform: scale(1.07);
          transform: scale(1.07);
}

.categories-box:hover .content {
  background-color: var(--mainColor);
}

.categories-box:hover .content h3 {
  color: var(--whiteColor);
}

/*================================================
Categories Area CSS
=================================================*/
.single-categories-box {
  margin-bottom: 30px;
  position: relative;
  border-radius: 3px;
  text-align: center;
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  padding: 30px 15px;
}

.single-categories-box img {
  width: auto !important;
  display: inline-block !important;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-categories-box h3 {
  font-size: 18px;
  margin-bottom: 0;
  margin-top: 25px;
}

.single-categories-box .link-btn {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
  z-index: 2;
}

.single-categories-box:hover img {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

/*================================================
Products Area CSS
=================================================*/
.single-products-box {
  margin-bottom: 30px;
}

.single-products-box .image {
  position: relative;
  overflow: hidden;
}

.single-products-box .image img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-products-box .image .new {
  position: absolute;
  left: 12px;
  top: 12px;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  padding: 1px 12px;
  z-index: 2;
  font-size: 14px;
  font-weight: 600;
}

.single-products-box .image .sale {
  position: absolute;
  z-index: 2;
  right: 12px;
  top: 12px;
  background-color: red;
  color: var(--whiteColor);
  padding: 1px 12px;
  font-size: 14px;
  font-weight: 600;
}

.single-products-box .image .buttons-list {
  position: absolute;
  text-align: center;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 20px;
}

.single-products-box .image .buttons-list ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.single-products-box .image .buttons-list ul li {
  opacity: 0;
  visibility: hidden;
  display: inline-block;
  -webkit-transform: translateX(30px);
          transform: translateX(30px);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  margin-left: 3px;
  margin-right: 3px;
}

.single-products-box .image .buttons-list ul li a {
  display: inline-block;
  font-size: 22px;
  line-height: 1;
  color: var(--blackColor);
  position: relative;
  width: 40px;
  border-radius: 50%;
  line-height: 43px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  background-color: var(--whiteColor);
  height: 40px;
}

.single-products-box .image .buttons-list ul li a:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.single-products-box .image .buttons-list ul li a .tooltip-label {
  right: 50%;
  top: -15px;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
  position: absolute;
  padding: 0 10px;
  line-height: 28px;
  font-size: 12px;
  letter-spacing: .3px;
  visibility: hidden;
  white-space: nowrap;
  opacity: 0;
  color: transparent;
  background-color: transparent;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  background-color: var(--blackColor);
  color: var(--whiteColor);
}

.single-products-box .image .buttons-list ul li a .tooltip-label::before {
  content: "\e9ff";
  color: var(--blackColor);
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%) rotate(90deg);
          transform: translateX(-50%) rotate(90deg);
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  bottom: -16px;
  font-family: "boxicons" !important;
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
}

.single-products-box .image .buttons-list ul li a:hover .tooltip-label {
  visibility: visible;
  opacity: 1;
  top: -45px;
}

.single-products-box .image .buttons-list ul li:nth-child(1) {
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}

.single-products-box .image .buttons-list ul li:nth-child(2) {
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}

.single-products-box .image .buttons-list ul li:nth-child(3) {
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
}

.single-products-box .content {
  margin-top: 25px;
}

.single-products-box .content h3 {
  margin-bottom: 8px;
  font-size: 20px;
}

.single-products-box .content .price span {
  color: var(--mainColor);
  font-size: 14.5px;
  font-weight: 600;
}

.single-products-box .content .price span.old-price {
  color: var(--optionalColor);
  text-decoration: line-through;
}

.single-products-box:hover .image img {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.single-products-box:hover .image .buttons-list ul li {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
  visibility: visible;
}

.products-slides.owl-theme .owl-nav {
  margin-top: 0;
  position: absolute;
  right: 0;
  top: -82px;
}

.products-slides.owl-theme .owl-nav [class*=owl-] {
  margin: 0;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background-color: transparent;
  color: var(--blackColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-align: center;
  line-height: 31px;
  border: 1px solid #ebebeb;
  font-size: 20px;
  padding-right: 2px !important;
}

.products-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  margin-left: 8px;
  padding-right: 0 !important;
  padding-left: 2px !important;
}

.products-slides.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border-color: var(--mainColor);
}

.drodo-grid-sorting {
  margin-bottom: 40px;
}

.drodo-grid-sorting .result-count p {
  font-weight: 500;
}

.drodo-grid-sorting .result-count p .count {
  font-weight: 700;
  color: var(--blackColor);
}

.drodo-grid-sorting .result-count .sub-title {
  text-transform: uppercase;
  font-weight: 700;
}

.drodo-grid-sorting .ordering {
  text-align: right;
}

.drodo-grid-sorting .ordering label {
  display: inline-block;
  margin-bottom: 0;
  color: var(--optionalColor);
  margin-right: 5px;
  font-weight: 600;
}

.drodo-grid-sorting .ordering .nice-select {
  display: inline-block;
  width: 215px;
  float: unset;
  background: #f8f8f8;
  border-color: #eeeeee;
  color: var(--blackColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  padding: 0 0 0 12px;
  height: 45px;
  line-height: 45px;
  font-size: 15px;
  font-weight: 600;
}

.drodo-grid-sorting .ordering .nice-select .list {
  background-color: var(--whiteColor);
  border-radius: 5px;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  list-style-type: none;
  border: none;
  width: 100%;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.drodo-grid-sorting .ordering .nice-select .list .option {
  line-height: 38px;
  min-height: 38px;
  color: var(--blackColor);
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  padding-left: 32px;
  padding-right: 25px;
  font-size: 15px;
  font-weight: 600;
}

.drodo-grid-sorting .ordering .nice-select .list .option:hover {
  background-color: var(--mainColor) !important;
  color: var(--whiteColor);
}

.drodo-grid-sorting .ordering .nice-select .list .option:hover::before {
  color: var(--whiteColor);
}

.drodo-grid-sorting .ordering .nice-select .list .option.focus, .drodo-grid-sorting .ordering .nice-select .list .option.selected.focus {
  background-color: transparent !important;
  color: var(--blackColor);
}

.drodo-grid-sorting .ordering .nice-select .list .option::before {
  content: "\ea0f";
  position: absolute;
  left: 10px;
  top: -3px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--mainColor);
  font-family: 'boxicons';
  font-size: 20px;
}

.drodo-grid-sorting .ordering .nice-select .list .option.selected::before {
  opacity: 1;
  visibility: visible;
}

.drodo-grid-sorting .ordering .nice-select .list .option.selected:hover {
  background-color: var(--mainColor) !important;
  color: var(--whiteColor);
}

.drodo-grid-sorting .ordering .nice-select .list .option.selected:hover::before {
  color: var(--whiteColor);
}

.drodo-grid-sorting .ordering .nice-select:after {
  border-color: var(--blackColor);
  height: 8px;
  width: 8px;
  margin-top: -5px;
}

.drodo-grid-sorting .ordering .nice-select:hover {
  border-color: var(--mainColor);
  background-color: transparent;
}

.products-collections-area .container-fluid {
  width: 90%;
}

/*================================================
Products Details Area CSS
=================================================*/
.products-details-image img {
  width: 100%;
}

.products-details-image .products-details-image-slides {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.products-details-image .products-details-image-slides-owl-thumbs {
  padding: 0;
  text-align: center;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  margin-top: 0;
  margin-bottom: 0;
}

.products-details-image .products-details-image-slides-owl-thumbs .owl-thumb-item {
  display: block;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
  position: relative;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
}

.products-details-image .products-details-image-slides-owl-thumbs .owl-thumb-item img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.products-details-image .products-details-image-slides-owl-thumbs .owl-thumb-item img:hover {
  opacity: 0.5;
}

.products-details-image .products-details-image-slides-owl-thumbs .owl-thumb-item.active img {
  opacity: 0.5;
}

.products-details-image .row {
  margin-left: -8px;
  margin-right: -8px;
  margin-top: -15px;
}

.products-details-image .row .col-lg-6 {
  padding-left: 8px;
  padding-right: 8px;
}

.products-details-image .row .col-lg-6 .single-products-details-image {
  margin-top: 15px;
}

.products-details-image .single-products-details-image {
  margin-top: 30px;
}

.products-details-image .single-products-details-image:first-child {
  margin-top: 0;
}

.products-details-image-slider.owl-theme {
  margin-bottom: 30px;
}

.products-details-image-slider.owl-theme .owl-nav {
  margin-top: 0;
}

.products-details-image-slider.owl-theme .owl-nav [class*=owl-] {
  color: #87a1c1;
  font-size: 15px;
  margin: 0;
  padding: 0;
  background: var(--whiteColor);
  width: 40px;
  height: 40px;
  line-height: 35px;
  position: absolute;
  left: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  top: 50%;
  padding-right: 2px !important;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 50%;
  border-style: solid;
  border-color: var(--whiteColor);
  border-width: 1.5px;
}

.products-details-image-slider.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 15px;
  padding-right: 0 !important;
  padding-left: 2px !important;
}

.products-details-image-slider.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border-color: var(--mainColor);
}

.products-details-desc h3 {
  margin-bottom: 18px;
  font-size: 22px;
}

.products-details-desc .price {
  margin-bottom: 15px;
  color: var(--mainColor);
  font-size: var(--fontSize);
  font-weight: 600;
}

.products-details-desc .price .old-price {
  text-decoration: line-through;
  color: #828893;
  font-weight: normal;
}

.products-details-desc .products-review {
  margin-bottom: 12px;
}

.products-details-desc .products-review .rating {
  display: inline-block;
  padding-right: 5px;
  font-size: 18px;
}

.products-details-desc .products-review .rating i {
  color: #ffba0a;
  display: inline-block;
  margin-right: -2px;
}

.products-details-desc .products-review .rating-count {
  display: inline-block;
  color: var(--blackColor);
  border-bottom: 1px solid var(--blackColor);
  line-height: initial;
  position: relative;
  top: -2px;
  font-weight: 600;
}

.products-details-desc .products-review .rating-count:hover {
  color: var(--mainColor);
  border-color: var(--mainColor);
}

.products-details-desc .products-info {
  list-style-type: none;
  padding-left: 0;
  margin-top: 15px;
  margin-bottom: 0;
}

.products-details-desc .products-info li {
  color: var(--blackColor);
  margin-bottom: 8px;
  font-size: 16px;
}

.products-details-desc .products-info li:last-child {
  margin-bottom: 0;
}

.products-details-desc .products-info li span {
  color: var(--optionalColor);
}

.products-details-desc .products-info li a {
  display: inline-block;
  font-weight: 600;
  color: var(--blackColor);
}

.products-details-desc .products-info li a:hover {
  color: var(--mainColor);
}

.products-details-desc .products-color-switch {
  margin-top: 10px;
}

.products-details-desc .products-color-switch span {
  margin-bottom: 8px;
  display: block;
  font-size: 17px;
  font-weight: 600;
}

.products-details-desc .products-color-switch ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: -3px;
  margin-left: -3px;
}

.products-details-desc .products-color-switch ul li {
  display: inline-block;
  margin-left: 3px;
  margin-top: 3px;
}

.products-details-desc .products-color-switch ul li a {
  display: block;
  position: relative;
  border: 1px solid transparent;
  width: 22px;
  height: 22px;
  border-radius: 50%;
}

.products-details-desc .products-color-switch ul li a::before {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #eeeeee;
  border-radius: 50%;
  margin: 2px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.products-details-desc .products-color-switch ul li a:hover, .products-details-desc .products-color-switch ul li a:focus {
  border-color: var(--blackColor);
}

.products-details-desc .products-color-switch ul li a.color-white::before {
  background: var(--whiteColor);
  border: 1px solid #eeeeee;
}

.products-details-desc .products-color-switch ul li a.color-black::before {
  background: var(--blackColor);
}

.products-details-desc .products-color-switch ul li a.color-red::before {
  background: red;
}

.products-details-desc .products-color-switch ul li a.color-blue::before {
  background: blue;
}

.products-details-desc .products-color-switch ul li a.color-green::before {
  background: green;
}

.products-details-desc .products-color-switch ul li a.color-yellow::before {
  background: yellow;
}

.products-details-desc .products-color-switch ul li a.color-yellowgreen::before {
  background: yellowgreen;
}

.products-details-desc .products-color-switch ul li a.color-pink::before {
  background: pink;
}

.products-details-desc .products-color-switch ul li a.color-violet::before {
  background: violet;
}

.products-details-desc .products-color-switch ul li a.color-teal::before {
  background: teal;
}

.products-details-desc .products-color-switch ul li a.color-plum::before {
  background: plum;
}

.products-details-desc .products-color-switch ul li a.color-lime::before {
  background: lime;
}

.products-details-desc .products-color-switch ul li a.color-blueviolet::before {
  background: blueviolet;
}

.products-details-desc .products-color-switch ul li.active a {
  border-color: var(--blackColor);
}

.products-details-desc .products-size-wrapper {
  margin-top: 8px;
  margin-bottom: 25px;
}

.products-details-desc .products-size-wrapper span {
  margin-bottom: 8px;
  display: block;
  font-size: 17px;
  font-weight: 600;
}

.products-details-desc .products-size-wrapper ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: -8px;
  margin-left: -8px;
}

.products-details-desc .products-size-wrapper ul li {
  display: inline-block;
  margin-left: 8px;
  margin-top: 8px;
}

.products-details-desc .products-size-wrapper ul li a {
  width: 38px;
  height: 35px;
  display: block;
  line-height: 32px;
  border: 1px solid #eeeeee;
  color: #858585;
  text-align: center;
  font-size: var(--fontSize);
  font-weight: 600;
}

.products-details-desc .products-size-wrapper ul li a:hover {
  border-color: var(--mainColor);
  color: var(--mainColor);
}

.products-details-desc .products-size-wrapper ul li.active a {
  border-color: var(--mainColor);
  color: var(--mainColor);
}

.products-details-desc .products-info-btn {
  margin-bottom: 25px;
}

.products-details-desc .products-info-btn a {
  text-transform: uppercase;
  display: inline-block;
  position: relative;
  padding-left: 22px;
  line-height: initial;
  font-size: 14px;
  font-weight: 600;
}

.products-details-desc .products-info-btn a::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 1.5px;
  background: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.products-details-desc .products-info-btn a i {
  font-size: 18px;
  position: absolute;
  left: 0;
  top: 0;
}

.products-details-desc .products-info-btn a:hover {
  color: var(--mainColor);
}

.products-details-desc .products-info-btn a:hover::before {
  width: 100%;
}

.products-details-desc .products-info-btn a:not(:first-child) {
  margin-left: 5px;
}

.products-details-desc .products-add-to-cart .input-counter {
  max-width: 120px;
  min-width: 120px;
  margin-right: 10px;
  text-align: center;
  display: inline-block;
  position: relative;
}

.products-details-desc .products-add-to-cart .input-counter span {
  position: absolute;
  top: 0;
  background-color: transparent;
  cursor: pointer;
  color: #d0d0d0;
  width: 40px;
  height: 100%;
  line-height: 50px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 22px;
}

.products-details-desc .products-add-to-cart .input-counter span.minus-btn {
  left: 0;
}

.products-details-desc .products-add-to-cart .input-counter span.plus-btn {
  right: 0;
}

.products-details-desc .products-add-to-cart .input-counter span:hover {
  color: var(--mainColor);
}

.products-details-desc .products-add-to-cart .input-counter input {
  height: 45px;
  color: var(--blackColor);
  outline: 0;
  display: block;
  border: none;
  background-color: #f8f8f8;
  text-align: center;
  width: 100%;
  font-size: 17px;
  font-weight: 600;
}

.products-details-desc .products-add-to-cart .input-counter input::-webkit-input-placeholder {
  color: var(--blackColor);
}

.products-details-desc .products-add-to-cart .input-counter input:-ms-input-placeholder {
  color: var(--blackColor);
}

.products-details-desc .products-add-to-cart .input-counter input::-ms-input-placeholder {
  color: var(--blackColor);
}

.products-details-desc .products-add-to-cart .input-counter input::placeholder {
  color: var(--blackColor);
}

.products-details-desc .products-add-to-cart .default-btn {
  border-radius: 0;
  height: 45px;
  top: -1px;
}

.products-details-desc .wishlist-btn {
  margin-top: 20px;
}

.products-details-desc .wishlist-btn a {
  display: inline-block;
  font-weight: 700;
  position: relative;
  line-height: 1;
  padding-left: 20px;
}

.products-details-desc .wishlist-btn a i {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 16px;
}

.products-details-desc .buy-checkbox-btn {
  margin-top: 20px;
}

.products-details-desc .buy-checkbox-btn input {
  display: none;
}

.products-details-desc .buy-checkbox-btn .cbx {
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  cursor: pointer;
}

.products-details-desc .buy-checkbox-btn .cbx span {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.products-details-desc .buy-checkbox-btn .cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  -webkit-transform: scale(1);
          transform: scale(1);
  vertical-align: middle;
  border: 1px solid #ebebeb;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.products-details-desc .buy-checkbox-btn .cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: var(--whiteColor);
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.products-details-desc .buy-checkbox-btn .cbx span:first-child:before {
  content: "";
  width: 100%;
  height: 100%;
  background: var(--mainColor);
  display: block;
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 1;
  border-radius: 50%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.products-details-desc .buy-checkbox-btn .cbx span:last-child {
  padding-left: 4px;
  color: var(--blackColor);
  position: relative;
  top: -1px;
}

.products-details-desc .buy-checkbox-btn .cbx:hover span:first-child {
  border-color: var(--mainColor);
}

.products-details-desc .buy-checkbox-btn .inp-cbx:checked + .cbx span:first-child {
  background: var(--mainColor);
  border-color: var(--mainColor);
  -webkit-animation: wave 0.4s ease;
          animation: wave 0.4s ease;
}

.products-details-desc .buy-checkbox-btn .inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}

.products-details-desc .buy-checkbox-btn .inp-cbx:checked + .cbx span:first-child:before {
  -webkit-transform: scale(3.5);
          transform: scale(3.5);
  opacity: 0;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

.products-details-desc .buy-checkbox-btn .item:not(:first-child) {
  margin-top: 20px;
}

.products-details-desc .buy-checkbox-btn .default-btn {
  display: block;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 12.5px;
  padding-bottom: 12.5px;
}

.products-details-desc .products-details-accordion {
  margin-top: 25px;
}

.products-details-desc .products-details-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.products-details-desc .products-details-accordion .accordion .accordion-item {
  border-radius: 5px;
  display: block;
  background: var(--whiteColor);
  margin-bottom: 20px;
}

.products-details-desc .products-details-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}

.products-details-desc .products-details-accordion .accordion .accordion-title {
  padding-left: 25px;
  color: var(--blackColor);
  text-decoration: none;
  position: relative;
  display: block;
  font-size: 16px;
  font-weight: 700;
}

.products-details-desc .products-details-accordion .accordion .accordion-title i {
  position: absolute;
  left: -6px;
  top: -3px;
  font-size: 30px;
  line-height: 1;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.products-details-desc .products-details-accordion .accordion .accordion-title.active i {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.products-details-desc .products-details-accordion .accordion .accordion-content {
  display: none;
  position: relative;
  margin-top: -5px;
  padding-top: 15px;
}

.products-details-desc .products-details-accordion .accordion .accordion-content.show {
  display: block;
}

.products-details-desc .products-details-accordion .accordion .accordion-content ul, .products-details-desc .products-details-accordion .accordion .accordion-content ol {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.products-details-desc .products-details-accordion .accordion .accordion-content ul li, .products-details-desc .products-details-accordion .accordion .accordion-content ol li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 13px;
  color: var(--optionalColor);
}

.products-details-desc .products-details-accordion .accordion .accordion-content ul li::before, .products-details-desc .products-details-accordion .accordion .accordion-content ol li::before {
  content: '';
  position: absolute;
  top: 8px;
  left: 0;
  width: 6px;
  height: 6px;
  background: var(--mainColor);
  border-radius: 50%;
}

.products-details-desc .products-details-accordion .accordion .accordion-content ul li:last-child, .products-details-desc .products-details-accordion .accordion .accordion-content ol li:last-child {
  margin-bottom: 0;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .table {
  margin-bottom: 0;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .table.table-striped tbody tr td {
  border-top: none;
  vertical-align: middle;
  white-space: nowrap;
  padding: 10px 15px;
  color: var(--blackColor);
}

.products-details-desc .products-details-accordion .accordion .accordion-content .table.table-striped tbody tr td:first-child {
  font-weight: 500;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .table.table-bordered tbody tr td {
  vertical-align: middle;
  white-space: nowrap;
  padding: 10px 15px;
  color: var(--optionalColor);
  line-height: 1.7;
  border-color: #eeeeee;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .table.table-bordered tbody tr td:first-child {
  color: var(--blackColor);
  font-weight: 500;
}

.products-details-desc .products-details-accordion .accordion .accordion-content h3 {
  margin-bottom: 12px;
  font-size: 18px;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .products-reviews {
  margin-top: 10px;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .products-reviews h3 {
  margin-bottom: 0;
  display: inline-block;
  margin-right: 15px;
  font-size: 22px;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .products-reviews .rating {
  display: inline-block;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .products-reviews .rating span {
  font-size: 17px;
  color: #cecfd2;
  margin-right: -2px;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .products-reviews .rating span.checked {
  color: orange;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .products-reviews .rating-count {
  margin-top: 10px;
  margin-bottom: 20px;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .products-reviews .rating-count span {
  display: block;
  color: var(--optionalColor);
}

.products-details-desc .products-details-accordion .accordion .accordion-content .products-reviews .row {
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .products-reviews .side {
  float: left;
  width: 9%;
  margin-top: 10px;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .products-reviews .side div {
  font-weight: 700;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .products-reviews .middle {
  margin-top: 14px;
  float: left;
  width: 82%;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .products-reviews .right {
  text-align: right;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .products-reviews .bar-container {
  width: 100%;
  background-color: #f1f1f1;
  text-align: center;
  color: var(--whiteColor);
  border-radius: 5px;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .products-reviews .bar-5 {
  width: 100%;
  height: 18px;
  background-color: #4CAF50;
  border-radius: 5px;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .products-reviews .bar-4 {
  width: 75%;
  height: 18px;
  background-color: #2196F3;
  border-radius: 5px;
  border-radius: 5px;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .products-reviews .bar-3 {
  width: 50%;
  height: 18px;
  background-color: #00bcd4;
  border-radius: 5px;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .products-reviews .bar-2 {
  width: 25%;
  height: 18px;
  background-color: #ff9800;
  border-radius: 5px;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .products-reviews .bar-1 {
  width: 0;
  height: 18px;
  background-color: #f44336;
  border-radius: 5px;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .products-review-comments {
  margin-top: 40px;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .products-review-comments h3 {
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 10px;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 22px;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .products-review-comments .user-review {
  border-bottom: 1px solid #f3f3f3;
  padding: 20px 0 20px 110px;
  position: relative;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .products-review-comments .user-review img {
  position: absolute;
  left: 0;
  top: 20px;
  width: 90px;
  height: 90px;
  border-radius: 5px;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .products-review-comments .user-review .sub-comment {
  margin-bottom: 8px;
  font-weight: 700;
  font-family: var(--fontFamily);
}

.products-details-desc .products-details-accordion .accordion .accordion-content .products-review-comments .user-review .review-rating {
  display: block;
  margin-bottom: 8px;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .products-review-comments .user-review .review-rating .review-stars {
  display: inline-block;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .products-review-comments .user-review .review-rating .review-stars i {
  color: #cecfd2;
  font-size: 18px;
  display: inline-block;
  margin-right: -3px;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .products-review-comments .user-review .review-rating .review-stars i.checked {
  color: orange;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .products-review-comments .user-review .review-rating span {
  color: var(--blackColor);
  position: relative;
  top: -2px;
  margin-left: 5px;
  font-family: var(--fontFamily2);
  font-weight: 700;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .review-form-wrapper {
  margin-top: 30px;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .review-form-wrapper h3 {
  margin-bottom: 10px;
  font-size: 22px;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .review-form-wrapper .comment-notes span {
  color: red;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .review-form-wrapper form {
  margin-top: 20px;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .review-form-wrapper form .form-group {
  margin-bottom: 25px;
  text-align: left;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .review-form-wrapper form .rating {
  text-align: left;
  overflow: hidden;
  max-width: 115px;
  margin-top: -5px;
  margin-bottom: 20px;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .review-form-wrapper form .rating label {
  float: right;
  position: relative;
  width: 23px;
  height: 23px;
  cursor: pointer;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .review-form-wrapper form .rating label:not(:first-of-type) {
  padding-right: 5px;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .review-form-wrapper form .rating label:before {
  content: "\2605";
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 27px;
  color: #CCCCCC;
  line-height: 1;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .review-form-wrapper form .rating input {
  display: none;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .review-form-wrapper form .rating input:checked ~ label:before, .products-details-desc .products-details-accordion .accordion .accordion-content .review-form-wrapper form .rating:not(:checked) > label:hover:before, .products-details-desc .products-details-accordion .accordion .accordion-content .review-form-wrapper form .rating:not(:checked) > label:hover ~ label:before {
  color: #f6b500;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .review-form-wrapper form .comment-form-cookies-consent {
  text-align: left;
  margin-bottom: 0;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked, .products-details-desc .products-details-accordion .accordion .accordion-content .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:not(:checked) {
  display: none;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked + label, .products-details-desc .products-details-accordion .accordion .accordion-content .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  margin-bottom: 0;
  color: var(--optionalColor);
  font-weight: 500;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked + label:before, .products-details-desc .products-details-accordion .accordion .accordion-content .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 1px;
  width: 19px;
  height: 19px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: 1px solid #f5f5f5;
  border-radius: 3px;
  background: #f5f5f5;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked + label:after, .products-details-desc .products-details-accordion .accordion .accordion-content .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:not(:checked) + label:after {
  content: '';
  width: 8px;
  height: 8px;
  background: var(--mainColor);
  position: absolute;
  top: 6.5px;
  left: 6px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.products-details-desc .products-details-accordion .accordion .accordion-content .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.products-details-desc .products-details-accordion .accordion .accordion-content .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.products-details-desc .products-details-accordion .accordion .accordion-content .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:hover + label:before {
  border-color: var(--mainColor);
}

.products-details-desc .products-details-accordion .accordion .accordion-content .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked + label:before {
  border-color: var(--mainColor);
}

.products-details-desc .products-details-accordion .accordion .accordion-content .review-form-wrapper form button {
  margin-top: 22px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 11px;
  padding-bottom: 11px;
}

.products-details-tabs {
  margin-top: 50px;
}

.products-details-tabs .nav {
  text-align: center;
  padding-left: 0;
  margin-bottom: 40px;
  list-style-type: none;
  display: block;
}

.products-details-tabs .nav .nav-item {
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
}

.products-details-tabs .nav .nav-item .nav-link {
  color: #cccccc;
  border: none;
  border-bottom: 1px solid #eeeeee;
  padding: 0;
  background-color: transparent;
  position: relative;
  padding-bottom: 8px;
  font-size: 20px;
  font-weight: 700;
  font-family: var(--fontFamily);
}

.products-details-tabs .nav .nav-item .nav-link::before {
  content: '';
  position: absolute;
  left: 0;
  width: 0;
  height: 3px;
  background-color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  bottom: -2px;
}

.products-details-tabs .nav .nav-item .nav-link:hover, .products-details-tabs .nav .nav-item .nav-link.active {
  color: var(--blackColor);
}

.products-details-tabs .nav .nav-item .nav-link:hover::before, .products-details-tabs .nav .nav-item .nav-link.active::before {
  width: 100%;
}

.products-details-tabs .tab-content .tab-pane p {
  line-height: 1.8;
}

.products-details-tabs .tab-content .tab-pane ul {
  padding-left: 0;
  list-style-type: none;
  margin-top: 20px;
  margin-bottom: 0;
}

.products-details-tabs .tab-content .tab-pane ul li {
  margin-bottom: 12px;
  position: relative;
  color: var(--optionalColor);
  padding-left: 15px;
}

.products-details-tabs .tab-content .tab-pane ul li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 8px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: var(--blackColor);
}

.products-details-tabs .tab-content .tab-pane ul li:last-child {
  margin-bottom: 0;
}

.products-details-tabs .tab-content .tab-pane .products-reviews h3 {
  margin-bottom: 0;
  display: inline-block;
  margin-right: 15px;
  font-size: 22px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .rating {
  display: inline-block;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .rating span {
  font-size: 17px;
  color: #cecfd2;
  margin-right: -2px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .rating span.checked {
  color: orange;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .rating-count {
  margin-top: 10px;
  margin-bottom: 20px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .rating-count span {
  display: block;
  color: var(--optionalColor);
}

.products-details-tabs .tab-content .tab-pane .products-reviews .row {
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .side {
  float: left;
  width: 9%;
  margin-top: 10px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .side div {
  font-weight: 700;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .middle {
  margin-top: 14px;
  float: left;
  width: 82%;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .right {
  text-align: right;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .bar-container {
  width: 100%;
  background-color: #f1f1f1;
  text-align: center;
  color: var(--whiteColor);
  border-radius: 5px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .bar-5 {
  width: 100%;
  height: 18px;
  background-color: #4CAF50;
  border-radius: 5px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .bar-4 {
  width: 75%;
  height: 18px;
  background-color: #2196F3;
  border-radius: 5px;
  border-radius: 5px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .bar-3 {
  width: 50%;
  height: 18px;
  background-color: #00bcd4;
  border-radius: 5px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .bar-2 {
  width: 25%;
  height: 18px;
  background-color: #ff9800;
  border-radius: 5px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .bar-1 {
  width: 0;
  height: 18px;
  background-color: #f44336;
  border-radius: 5px;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments {
  margin-top: 40px;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments h3 {
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 10px;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 22px;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review {
  border-bottom: 1px solid #f3f3f3;
  padding: 20px 0 20px 110px;
  position: relative;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review img {
  position: absolute;
  left: 0;
  top: 20px;
  width: 90px;
  height: 90px;
  border-radius: 5px;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review .sub-comment {
  margin-bottom: 8px;
  font-weight: 700;
  font-family: var(--fontFamily);
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review .review-rating {
  display: block;
  margin-bottom: 8px;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review .review-rating .review-stars {
  display: inline-block;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review .review-rating .review-stars i {
  color: #cecfd2;
  font-size: 18px;
  display: inline-block;
  margin-right: -3px;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review .review-rating .review-stars i.checked {
  color: orange;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review .review-rating span {
  color: var(--blackColor);
  position: relative;
  top: -2px;
  margin-left: 5px;
  font-family: var(--fontFamily2);
  font-weight: 700;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper {
  margin-top: 30px;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper h3 {
  margin-bottom: 10px;
  font-size: 22px;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper .comment-notes span {
  color: red;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form {
  margin-top: 20px;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .form-group {
  margin-bottom: 25px;
  text-align: left;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating {
  text-align: left;
  overflow: hidden;
  max-width: 115px;
  margin-top: -5px;
  margin-bottom: 20px;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating label {
  float: right;
  position: relative;
  width: 23px;
  height: 23px;
  cursor: pointer;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating label:not(:first-of-type) {
  padding-right: 5px;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating label:before {
  content: "\2605";
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 27px;
  color: #CCCCCC;
  line-height: 1;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating input {
  display: none;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating input:checked ~ label:before, .products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating:not(:checked) > label:hover:before, .products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating:not(:checked) > label:hover ~ label:before {
  color: #f6b500;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent {
  text-align: left;
  margin-bottom: 0;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked, .products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:not(:checked) {
  display: none;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked + label, .products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  margin-bottom: 0;
  color: var(--optionalColor);
  font-weight: 500;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked + label:before, .products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 1px;
  width: 19px;
  height: 19px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: 1px solid #f5f5f5;
  border-radius: 3px;
  background: #f5f5f5;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked + label:after, .products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:not(:checked) + label:after {
  content: '';
  width: 8px;
  height: 8px;
  background: var(--mainColor);
  position: absolute;
  top: 6.5px;
  left: 6px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:hover + label:before {
  border-color: var(--mainColor);
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked + label:before {
  border-color: var(--mainColor);
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form button {
  margin-top: 22px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 11px;
  padding-bottom: 11px;
}

.products-details-tabs .tab-content .tab-pane table {
  margin-bottom: 0;
}

.products-details-tabs .tab-content .tab-pane table tr td {
  white-space: nowrap;
  border-color: #eeeeee;
  color: var(--optionalColor);
  vertical-align: middle;
  padding: 10px 15px;
}

.products-details-tabs .tab-content .tab-pane table tr td:first-child {
  color: var(--blackColor);
  font-weight: 600;
  font-family: var(--fontFamily2);
}

.related-products {
  margin-top: 50px;
}

/*================================================
Cart Area CSS
=================================================*/
.cart-table table {
  margin-bottom: 0;
}

.cart-table table thead tr th {
  border-bottom-width: 0px;
  vertical-align: middle;
  padding: 0 0 15px;
  text-transform: uppercase;
  border: none;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 700;
}

.cart-table table tbody tr td {
  vertical-align: middle;
  color: var(--optionalColor);
  white-space: nowrap;
  font-weight: 700;
  font-size: 15px;
  padding-left: 0;
  padding-right: 0;
  border-color: #eaedff;
  border-left: none;
  border-right: none;
}

.cart-table table tbody tr td.product-thumbnail a {
  display: block;
}

.cart-table table tbody tr td.product-thumbnail a img {
  width: 80px;
}

.cart-table table tbody tr td.product-name a {
  display: inline-block;
}

.cart-table table tbody tr td.product-subtotal .remove {
  color: red;
  float: right;
  position: relative;
  top: -1px;
  font-size: 18px;
}

.cart-table table tbody tr td.product-quantity .input-counter {
  max-width: 130px;
  min-width: 130px;
  text-align: center;
  display: inline-block;
  position: relative;
}

.cart-table table tbody tr td.product-quantity .input-counter span {
  position: absolute;
  top: 0;
  background-color: transparent;
  cursor: pointer;
  color: #d0d0d0;
  width: 40px;
  height: 100%;
  line-height: 50px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 22px;
}

.cart-table table tbody tr td.product-quantity .input-counter span.minus-btn {
  left: 0;
}

.cart-table table tbody tr td.product-quantity .input-counter span.plus-btn {
  right: 0;
}

.cart-table table tbody tr td.product-quantity .input-counter span:hover {
  color: var(--mainColor);
}

.cart-table table tbody tr td.product-quantity .input-counter input {
  height: 45px;
  color: var(--blackColor);
  outline: 0;
  display: block;
  border: none;
  background-color: #f8f8f8;
  text-align: center;
  width: 100%;
  font-size: 17px;
  font-weight: 600;
}

.cart-table table tbody tr td.product-quantity .input-counter input::-webkit-input-placeholder {
  color: var(--blackColor);
}

.cart-table table tbody tr td.product-quantity .input-counter input:-ms-input-placeholder {
  color: var(--blackColor);
}

.cart-table table tbody tr td.product-quantity .input-counter input::-ms-input-placeholder {
  color: var(--blackColor);
}

.cart-table table tbody tr td.product-quantity .input-counter input::placeholder {
  color: var(--blackColor);
}

.cart-table table tbody tr td.product-subtotal {
  overflow: hidden;
}

.cart-buttons {
  margin-top: 30px;
}

.cart-buttons .shopping-coupon-code {
  position: relative;
  max-width: 530px;
}

.cart-buttons .shopping-coupon-code button {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  background: var(--blackColor);
  color: var(--whiteColor);
  border: none;
  padding: 0 25px;
  line-height: 47px;
  outline: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 15px;
  font-weight: 700;
}

.cart-buttons .shopping-coupon-code button:hover {
  background-color: var(--mainColor);
}

.cart-totals {
  background: var(--whiteColor);
  padding: 40px;
  max-width: 500px;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
          box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
}

.cart-totals h3 {
  margin-bottom: 25px;
  font-size: 22px;
}

.cart-totals ul {
  padding-left: 0;
  margin: 0 0 25px;
  list-style-type: none;
}

.cart-totals ul li {
  border: 1px solid #eaedff;
  padding: 10px 15px;
  color: var(--blackColor);
  overflow: hidden;
  font-weight: 700;
  font-size: 15px;
}

.cart-totals ul li:first-child {
  border-bottom: none;
}

.cart-totals ul li:last-child {
  font-size: 18px;
  border-top: none;
}

.cart-totals ul li:last-child span {
  color: var(--blackColor);
  font-weight: 600;
}

.cart-totals ul li span {
  float: right;
  color: var(--optionalColor);
  font-weight: normal;
}

/*================================================
Checkout Area CSS
=================================================*/
.user-actions {
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
          box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  background: var(--whiteColor);
  padding: 15px 20px;
  border-top: 3px solid var(--mainColor);
  position: relative;
  margin-bottom: 40px;
}

.user-actions i {
  color: var(--mainColor);
  margin-right: 2px;
  font-size: 20px;
  position: relative;
  top: 3px;
}

.user-actions span {
  display: inline-block;
  color: var(--mainColor);
  font-weight: 700;
  font-size: 16px;
}

.user-actions span a {
  display: inline-block;
}

.billing-details .title {
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
  font-size: 22px;
}

.billing-details .title::before {
  content: '';
  position: absolute;
  background: var(--mainColor);
  bottom: -1px;
  left: 0;
  width: 50px;
  height: 1px;
}

.billing-details .form-group {
  margin-bottom: 25px;
}

.billing-details .form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 14px;
}

.billing-details .form-group label .required {
  color: red;
}

.billing-details .form-group .nice-select {
  display: block;
  float: unset;
  background: #f8f8f8;
  border-color: #eeeeee;
  color: var(--blackColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  padding: 0 0 0 12px;
  height: 50px;
  line-height: 50px;
  width: 100%;
  font-size: 15px;
  font-weight: 600;
}

.billing-details .form-group .nice-select .list {
  background-color: var(--whiteColor);
  border-radius: 5px;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  list-style-type: none;
  border: none;
  width: 100%;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.billing-details .form-group .nice-select .list .option {
  line-height: 38px;
  min-height: 38px;
  color: var(--blackColor);
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  padding-left: 32px;
  padding-right: 25px;
  font-size: 15px;
  font-weight: 600;
}

.billing-details .form-group .nice-select .list .option:hover {
  background-color: var(--mainColor) !important;
  color: var(--whiteColor);
}

.billing-details .form-group .nice-select .list .option:hover::before {
  color: var(--whiteColor);
}

.billing-details .form-group .nice-select .list .option.focus, .billing-details .form-group .nice-select .list .option.selected.focus {
  background-color: transparent !important;
  color: var(--blackColor);
}

.billing-details .form-group .nice-select .list .option::before {
  content: "\ea0f";
  position: absolute;
  left: 10px;
  top: -3px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--mainColor);
  font-family: 'boxicons';
  font-size: 20px;
}

.billing-details .form-group .nice-select .list .option.selected::before {
  opacity: 1;
  visibility: visible;
}

.billing-details .form-group .nice-select .list .option.selected:hover {
  background-color: var(--mainColor) !important;
  color: var(--whiteColor);
}

.billing-details .form-group .nice-select .list .option.selected:hover::before {
  color: var(--whiteColor);
}

.billing-details .form-group .nice-select:after {
  border-color: var(--blackColor);
  height: 8px;
  width: 8px;
  margin-top: -5px;
}

.billing-details .form-group .nice-select:hover {
  border-color: var(--mainColor);
  background-color: transparent;
}

.billing-details .form-check {
  margin-bottom: 20px;
}

.billing-details .form-check .form-check-label {
  color: var(--blackColor);
  font-weight: 600;
}

.billing-details .form-check label {
  position: relative;
  left: -3px;
  top: 0;
  font-weight: 500;
}

.billing-details .col-lg-12:last-child .form-group {
  margin-bottom: 0;
}

.order-details .title {
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
  font-size: 22px;
}

.order-details .title::before {
  content: '';
  position: absolute;
  background: var(--mainColor);
  bottom: -1px;
  left: 0;
  width: 50px;
  height: 1px;
}

.order-details .order-table table {
  margin-bottom: 0;
}

.order-details .order-table table thead tr th {
  border-bottom-width: 0;
  vertical-align: middle;
  border-color: #eaedff;
  padding: 12px 20px 10px;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 15px;
}

.order-details .order-table table tbody tr td {
  vertical-align: middle;
  color: var(--optionalColor);
  white-space: nowrap;
  border-color: #eaedff;
  font-size: 14px;
  font-weight: 600;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 13px;
}

.order-details .order-table table tbody tr td.product-name a {
  display: inline-block;
}

.order-details .order-table table tbody tr td.order-subtotal span, .order-details .order-table table tbody tr td.order-shipping span, .order-details .order-table table tbody tr td.total-price span {
  color: var(--blackColor);
  font-weight: 700;
}

.order-details .order-table table tbody tr td.shipping-price, .order-details .order-table table tbody tr td.order-subtotal-price, .order-details .order-table table tbody tr td.product-subtotal {
  color: var(--blackColor);
  font-weight: 700;
}

.order-details .payment-box {
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
          box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  margin-top: 30px;
  padding: 30px;
}

.order-details .payment-box .payment-method p [type="radio"]:checked, .order-details .payment-box .payment-method p [type="radio"]:not(:checked) {
  display: none;
}

.order-details .payment-box .payment-method p [type="radio"]:checked + label, .order-details .payment-box .payment-method p [type="radio"]:not(:checked) + label {
  padding-left: 27px;
  cursor: pointer;
  display: block;
  color: var(--blackColor);
  position: relative;
  margin-bottom: 8px;
  font-weight: 700;
}

.order-details .payment-box .payment-method p [type="radio"]:checked + label::before, .order-details .payment-box .payment-method p [type="radio"]:not(:checked) + label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  height: 18px;
  border: 1px solid #dddddd;
  border-radius: 50%;
  background: var(--whiteColor);
}

.order-details .payment-box .payment-method p [type="radio"]:checked + label::after, .order-details .payment-box .payment-method p [type="radio"]:not(:checked) + label::after {
  content: '';
  width: 12px;
  height: 12px;
  background: var(--mainColor);
  position: absolute;
  top: 7px;
  left: 3px;
  border-radius: 50%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.order-details .payment-box .payment-method p [type="radio"]:not(:checked) + label::after {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0);
          transform: scale(0);
}

.order-details .payment-box .payment-method p [type="radio"]:checked + label::after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.order-details .payment-box .default-btn {
  margin-top: 15px;
  -webkit-box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
          box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
  padding-left: 30px;
  padding-right: 30px;
}

/*================================================
Woocommerce Widget Area CSS
=================================================*/
.woocommerce-widget-area .woocommerce-widget {
  margin-bottom: 35px;
}

.woocommerce-widget-area .woocommerce-widget .woocommerce-widget-title {
  margin-bottom: 25px;
  position: relative;
  padding-bottom: 8px;
  border-bottom: 1px solid #eeeeee;
  font-size: 22px;
}

.woocommerce-widget-area .woocommerce-widget .woocommerce-widget-title::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 1px;
  bottom: -1px;
  background-color: var(--mainColor);
}

.woocommerce-widget-area .woocommerce-widget:last-child {
  margin-bottom: 0;
}

.woocommerce-widget-area .color-list-widget .color-list-row {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: -5px;
  margin-top: -4px;
}

.woocommerce-widget-area .color-list-widget .color-list-row li {
  display: inline-block;
  margin-right: 5px;
  margin-top: 5px;
}

.woocommerce-widget-area .color-list-widget .color-list-row li a {
  display: block;
  position: relative;
  border: 1px solid transparent;
  width: 18px;
  height: 18px;
}

.woocommerce-widget-area .color-list-widget .color-list-row li a::before {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #eeeeee;
  margin: 2px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.woocommerce-widget-area .color-list-widget .color-list-row li a:hover {
  border-color: var(--blackColor);
}

.woocommerce-widget-area .color-list-widget .color-list-row li a.color-white::before {
  background: var(--whiteColor);
  border: 1px solid #eeeeee;
}

.woocommerce-widget-area .color-list-widget .color-list-row li a.color-black::before {
  background: var(--blackColor);
}

.woocommerce-widget-area .color-list-widget .color-list-row li a.color-red::before {
  background: red;
}

.woocommerce-widget-area .color-list-widget .color-list-row li a.color-blue::before {
  background: blue;
}

.woocommerce-widget-area .color-list-widget .color-list-row li a.color-green::before {
  background: green;
}

.woocommerce-widget-area .color-list-widget .color-list-row li a.color-yellow::before {
  background: yellow;
}

.woocommerce-widget-area .color-list-widget .color-list-row li a.color-yellowgreen::before {
  background: yellowgreen;
}

.woocommerce-widget-area .color-list-widget .color-list-row li a.color-pink::before {
  background: pink;
}

.woocommerce-widget-area .color-list-widget .color-list-row li a.color-violet::before {
  background: violet;
}

.woocommerce-widget-area .color-list-widget .color-list-row li a.color-teal::before {
  background: teal;
}

.woocommerce-widget-area .color-list-widget .color-list-row li a.color-plum::before {
  background: plum;
}

.woocommerce-widget-area .color-list-widget .color-list-row li a.color-lime::before {
  background: lime;
}

.woocommerce-widget-area .color-list-widget .color-list-row li a.color-blueviolet::before {
  background: blueviolet;
}

.woocommerce-widget-area .color-list-widget .color-list-row li:first-child {
  margin-left: 0;
}

.woocommerce-widget-area .color-list-widget .color-list-row li:last-child {
  margin-right: 0;
}

.woocommerce-widget-area .color-list-widget .color-list-row li.active a {
  border-color: var(--blackColor);
}

.woocommerce-widget-area .best-seller-widget {
  position: relative;
  overflow: hidden;
}

.woocommerce-widget-area .best-seller-widget .item {
  overflow: hidden;
  margin-bottom: 15px;
}

.woocommerce-widget-area .best-seller-widget .item:last-child {
  margin-bottom: 0;
}

.woocommerce-widget-area .best-seller-widget .item .thumb {
  float: left;
  height: 80px;
  overflow: hidden;
  display: block;
  position: relative;
  width: 80px;
  margin-right: 15px;
  z-index: 1;
}

.woocommerce-widget-area .best-seller-widget .item .thumb .fullimage {
  width: 80px;
  height: 80px;
  display: inline-block;
  border-radius: 5px;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
}

.woocommerce-widget-area .best-seller-widget .item .thumb .fullimage.bg1 {
  background-image: url(../../assets/img/products/products-img3.jpg);
}

.woocommerce-widget-area .best-seller-widget .item .thumb .fullimage.bg2 {
  background-image: url(../../assets/img/products/products-img2.jpg);
}

.woocommerce-widget-area .best-seller-widget .item .thumb .fullimage.bg3 {
  background-image: url(../../assets/img/products/products-img5.jpg);
}

.woocommerce-widget-area .best-seller-widget .item .thumb::before, .woocommerce-widget-area .best-seller-widget .item .thumb::after {
  -webkit-transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
  transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
  content: '';
  background-color: var(--whiteColor);
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.woocommerce-widget-area .best-seller-widget .item .thumb::before {
  width: 40px;
  height: 1px;
  left: 100%;
}

.woocommerce-widget-area .best-seller-widget .item .thumb::after {
  height: 40px;
  width: 1px;
  top: 0;
}

.woocommerce-widget-area .best-seller-widget .item .info {
  overflow: hidden;
  margin-top: 5px;
}

.woocommerce-widget-area .best-seller-widget .item .info span {
  display: block;
  color: var(--mainColor);
  font-size: 14px;
  font-weight: 600;
  margin-top: 2px;
  margin-bottom: 5px;
}

.woocommerce-widget-area .best-seller-widget .item .info .title {
  margin-bottom: 0;
  line-height: 1.4;
  font-size: 17px;
}

.woocommerce-widget-area .best-seller-widget .item .info .title a {
  display: inline-block;
}

.woocommerce-widget-area .best-seller-widget .item .info .rating {
  color: #ffba0a;
}

.woocommerce-widget-area .best-seller-widget .item:hover .thumb::before, .woocommerce-widget-area .best-seller-widget .item:hover .thumb::after {
  opacity: 1;
  top: 50%;
  left: 50%;
}

.woocommerce-widget-area .woocommerce-ads-widget {
  position: relative;
  text-align: center;
  overflow: hidden;
}

.woocommerce-widget-area .woocommerce-ads-widget img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
}

.woocommerce-widget-area .woocommerce-ads-widget .content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.woocommerce-widget-area .woocommerce-ads-widget .content span {
  display: block;
  font-weight: 600;
  color: var(--mainColor);
  margin-bottom: 5px;
}

.woocommerce-widget-area .woocommerce-ads-widget .content h3 {
  font-size: 22px;
  margin-bottom: 15px;
}

.woocommerce-widget-area .woocommerce-ads-widget .link-btn {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.woocommerce-widget-area .woocommerce-ads-widget:hover img {
  -webkit-transform: scale(1.06);
          transform: scale(1.06);
}

.woocommerce-widget-area .brands-list-widget .brands-list-row {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.woocommerce-widget-area .brands-list-widget .brands-list-row li {
  margin-bottom: 9px;
}

.woocommerce-widget-area .brands-list-widget .brands-list-row li a {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  font-weight: 600;
}

.woocommerce-widget-area .brands-list-widget .brands-list-row li a::before {
  content: '';
  position: absolute;
  left: 0;
  top: 2.5px;
  width: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  height: 15px;
  border-radius: 3px;
  border: 1px solid var(--blackColor);
}

.woocommerce-widget-area .brands-list-widget .brands-list-row li a:hover {
  color: var(--mainColor);
}

.woocommerce-widget-area .brands-list-widget .brands-list-row li a:hover::before {
  border-color: var(--mainColor);
}

.woocommerce-widget-area .brands-list-widget .brands-list-row li a.active::before {
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}

.woocommerce-widget-area .brands-list-widget .brands-list-row li.active a::before {
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}

.woocommerce-widget-area .brands-list-widget .brands-list-row li:last-child {
  display: inline-block;
}

.woocommerce-widget-area .price-list-widget .collection-filter-by-price {
  margin-top: 20px;
}

.woocommerce-widget-area .price-list-widget .collection-filter-by-price .irs-handle {
  cursor: pointer;
}

.woocommerce-widget-area .price-list-widget .collection-filter-by-price .irs--flat .irs-from, .woocommerce-widget-area .price-list-widget .collection-filter-by-price .irs--flat .irs-to, .woocommerce-widget-area .price-list-widget .collection-filter-by-price .irs--flat .irs-single {
  background-color: var(--mainColor);
  padding: 1px 5px 1px 11.5px;
}

.woocommerce-widget-area .price-list-widget .collection-filter-by-price .irs--flat .irs-from::before, .woocommerce-widget-area .price-list-widget .collection-filter-by-price .irs--flat .irs-to::before, .woocommerce-widget-area .price-list-widget .collection-filter-by-price .irs--flat .irs-single::before {
  border-top-color: var(--mainColor);
}

.woocommerce-widget-area .price-list-widget .collection-filter-by-price .irs--flat .irs-from::after, .woocommerce-widget-area .price-list-widget .collection-filter-by-price .irs--flat .irs-to::after, .woocommerce-widget-area .price-list-widget .collection-filter-by-price .irs--flat .irs-single::after {
  content: '$';
  position: absolute;
  left: 5px;
  top: 0.5px;
}

.woocommerce-widget-area .price-list-widget .collection-filter-by-price .irs--flat .irs-bar {
  background-color: var(--mainColor);
}

.woocommerce-widget-area .price-list-widget .collection-filter-by-price .irs--flat .irs-handle > i:first-child {
  background-color: var(--mainColor);
}

.woocommerce-widget-area .price-list-widget .collection-filter-by-price .irs--flat .irs-min, .woocommerce-widget-area .price-list-widget .collection-filter-by-price .irs--flat .irs-max {
  padding: 1px 3px 1px 9.5px;
}

.woocommerce-widget-area .price-list-widget .collection-filter-by-price .irs--flat .irs-min::before, .woocommerce-widget-area .price-list-widget .collection-filter-by-price .irs--flat .irs-max::before {
  content: '$';
  position: absolute;
  left: 3px;
  top: 0.5px;
}

/*================================================
Products Promotions Area CSS
=================================================*/
.single-products-promotions-box {
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}

.single-products-promotions-box img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-products-promotions-box .content {
  position: absolute;
  right: 0;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: right;
  padding-left: 15px;
  padding-right: 15px;
}

.single-products-promotions-box .content .sub-title {
  display: block;
  margin-bottom: 10px;
  color: var(--optionalColor);
  font-weight: 600;
}

.single-products-promotions-box .content h3 {
  font-size: 25px;
  margin-bottom: 0;
  background-color: #cae7d9;
  display: inline-block;
  margin-right: -15px;
  border-radius: 30px 0 0 30px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 15px;
  padding-right: 15px;
}

.single-products-promotions-box .content .discount {
  display: block;
  font-size: 25px;
  font-weight: 700;
}

.single-products-promotions-box .content .discount span {
  font-size: var(--fontSize);
  font-weight: 600;
}

.single-products-promotions-box .content .link-btn {
  display: inline-block;
  margin-top: 20px;
  font-weight: 600;
}

.single-products-promotions-box .content .link-btn i {
  position: relative;
  top: 1px;
  margin-left: -2px;
}

.single-products-promotions-box:hover img {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.col-lg-4:nth-child(2) .single-products-promotions-box .content h3 {
  background-color: #f1e6b9;
}

.col-lg-4:nth-child(3) .single-products-promotions-box .content h3 {
  background-color: #b6d2f1;
}

.promotion-banner {
  background-image: url(../../assets/img/promotions-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 30px;
  padding-right: 30px;
}

.promotion-banner .sub-title {
  display: block;
  font-weight: 600;
  margin-bottom: 12px;
}

.promotion-banner h2 {
  margin-bottom: 20px;
  font-size: 30px;
  line-height: 1.3;
}

.promotion-banner h2 span {
  color: var(--mainColor);
}

.promotion-banner #timer {
  margin-bottom: 30px;
}

.promotion-banner #timer div {
  background-color: var(--whiteColor);
  color: var(--blackColor);
  -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  width: 70px;
  height: 70px;
  border-radius: 5px;
  font-size: 24px;
  font-weight: 700;
  margin-right: 10px;
}

.promotion-banner #timer div span {
  display: block;
  color: var(--optionalColor);
  margin-top: 0;
  font-size: 14px;
  font-weight: 600;
}

/*================================================
Feedback Area CSS
=================================================*/
.feedback-inner {
  text-align: center;
  z-index: 1;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}

.feedback-inner h2 {
  line-height: 1;
  font-size: 30px;
  position: relative;
  top: -4px;
  margin-bottom: 30px;
}

.feedback-inner h2 span {
  display: inline-block;
  background-color: var(--whiteColor);
  padding-left: 35px;
  padding-right: 35px;
}

.feedback-inner::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  border: 1px solid var(--mainColor);
}

.feedback-inner .owl-theme .owl-nav.disabled + .owl-dots {
  text-align: right;
  margin-top: 25px;
}

.feedback-inner .owl-theme .owl-dots .owl-dot {
  margin-left: 7px;
}

.feedback-inner .owl-theme .owl-dots .owl-dot span {
  width: 15px;
  height: 15px;
  margin: 0;
  border: 1px solid var(--mainColor);
  background: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 50%;
  position: relative;
}

.feedback-inner .owl-theme .owl-dots .owl-dot span::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 3px;
  background-color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
}

.feedback-inner .owl-theme .owl-dots .owl-dot:hover span::before, .feedback-inner .owl-theme .owl-dots .owl-dot.active span::before {
  opacity: 1;
  visibility: visible;
}

.single-feedback-item {
  text-align: center;
  position: relative;
  padding-top: 68px;
}

.single-feedback-item::before {
  line-height: 1;
  content: "\f111";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  color: var(--mainColor);
  font-size: 55px;
  font-family: Flaticon;
}

.single-feedback-item p {
  margin-bottom: 0;
  font-size: 18px;
  color: var(--blackColor);
}

.single-feedback-item .client-info {
  margin-top: 25px;
  text-align: left;
}

.single-feedback-item .client-info img {
  width: 70px !important;
  display: inline-block !important;
  height: 70px;
  border-radius: 50%;
}

.single-feedback-item .client-info .title {
  margin-left: 15px;
}

.single-feedback-item .client-info .title h3 {
  font-size: 20px;
  margin-bottom: 2px;
}

.single-feedback-item .client-info .title span {
  display: block;
}

.single-feedback-box {
  margin-bottom: 30px;
  text-align: center;
  position: relative;
  background-color: var(--whiteColor);
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  padding-top: 98px;
}

.single-feedback-box::before {
  line-height: 1;
  content: "\f111";
  position: absolute;
  left: 0;
  right: 0;
  top: 30px;
  color: var(--mainColor);
  font-size: 55px;
  font-family: Flaticon;
}

.single-feedback-box p {
  margin-bottom: 0;
  font-size: 18px;
  color: var(--blackColor);
}

.single-feedback-box .client-info {
  margin-top: 25px;
  text-align: left;
}

.single-feedback-box .client-info img {
  width: 70px !important;
  display: inline-block !important;
  height: 70px;
  border-radius: 50%;
}

.single-feedback-box .client-info .title {
  margin-left: 15px;
}

.single-feedback-box .client-info .title h3 {
  font-size: 20px;
  margin-bottom: 2px;
}

.single-feedback-box .client-info .title span {
  display: block;
}

.feedback-slides-two.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.feedback-slides-two.owl-theme .owl-dots .owl-dot {
  margin-left: 3px;
  margin-right: 3px;
}

.feedback-slides-two.owl-theme .owl-dots .owl-dot span {
  width: 15px;
  height: 15px;
  margin: 0;
  border: 1px solid var(--mainColor);
  background: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 50%;
  position: relative;
}

.feedback-slides-two.owl-theme .owl-dots .owl-dot span::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 3px;
  background-color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
}

.feedback-slides-two.owl-theme .owl-dots .owl-dot:hover span::before, .feedback-slides-two.owl-theme .owl-dots .owl-dot.active span::before {
  opacity: 1;
  visibility: visible;
}

/*================================================
Why Choose Us Area CSS
=================================================*/
.single-why-choose-box {
  margin-bottom: 30px;
  text-align: center;
  background-color: #f2f8f6;
  padding: 35px 20px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-why-choose-box .icon {
  line-height: 1;
  margin-bottom: 25px;
  font-size: 45px;
  color: var(--mainColor);
}

.single-why-choose-box h3 {
  font-size: 22px;
  margin-bottom: 12px;
}

.single-why-choose-box:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  background-color: var(--whiteColor) !important;
}

.col-lg-4:nth-child(2) .single-why-choose-box {
  background-color: #f3f7fa;
}

.col-lg-4:nth-child(3) .single-why-choose-box {
  background-color: #fef7ee;
}

/*================================================
Hot Deal Area CSS
=================================================*/
.hot-deal-area {
  background-image: url(../../assets/img/hot-deal-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.hot-deal-content {
  text-align: center;
  position: relative;
  z-index: 1;
}

.hot-deal-content .sub-title {
  display: block;
  font-weight: 600;
  margin-bottom: 12px;
  color: var(--mainColor);
}

.hot-deal-content h2 {
  margin-bottom: 25px;
  font-size: 70px;
  line-height: 1.1;
}

.hot-deal-content h2 span {
  color: var(--mainColor);
}

.hot-deal-content #timer {
  margin-bottom: 40px;
}

.hot-deal-content #timer div {
  background-color: var(--whiteColor);
  color: var(--blackColor);
  -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  width: 100px;
  height: 105px;
  border-radius: 5px;
  font-size: 35px;
  font-weight: 700;
  margin-left: 10px;
  margin-right: 10px;
}

.hot-deal-content #timer div span {
  display: block;
  color: var(--optionalColor);
  margin-top: 0;
  font-size: 16px;
  font-weight: 600;
}

.hot-deal-content .back-text {
  position: absolute;
  left: 0;
  right: 0;
  top: -35px;
  z-index: -1;
  line-height: 1;
  color: var(--blackColor);
  opacity: .04;
  font-size: 270px;
  font-weight: 700;
}

/*================================================
Brands Area CSS
=================================================*/
.single-brands-item {
  margin-bottom: 30px;
  text-align: center;
  border-right: 1px solid #eeeeee;
}

.col-lg-2:nth-child(6) .single-brands-item, .col-lg-2:nth-child(12) .single-brands-item, .col-lg-2:nth-child(18) .single-brands-item {
  border-right: none;
}

/*================================================
Blog Area CSS
=================================================*/
.single-blog-post {
  margin-bottom: 30px;
}

.single-blog-post .post-image {
  overflow: hidden;
}

.single-blog-post .post-image img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-blog-post .post-content {
  margin-top: 25px;
}

.single-blog-post .post-content h3 {
  margin-bottom: 15px;
  font-size: 21px;
}

.single-blog-post .post-content .post-meta {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.single-blog-post .post-content .post-meta li {
  margin-right: 20px;
  position: relative;
  color: var(--optionalColor);
  font-weight: 600;
}

.single-blog-post .post-content .post-meta li::before {
  content: '';
  position: absolute;
  right: -11px;
  top: 10.8px;
  width: 1px;
  height: 14px;
  background-color: #b3afaf;
}

.single-blog-post .post-content .post-meta li:last-child {
  margin-right: 0;
}

.single-blog-post .post-content .post-meta li:last-child::before {
  display: none;
}

.single-blog-post .post-content .post-meta li img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 5px;
}

.single-blog-post .post-content .post-meta li a {
  color: var(--optionalColor);
}

.single-blog-post .post-content .post-meta li a:hover {
  color: var(--mainColor);
}

.single-blog-post:hover .post-image img {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details-desc {
  background-color: #f7f8fa;
  padding: 25px;
}

.blog-details-desc .article-image-slides.owl-theme .owl-nav {
  margin-top: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.blog-details-desc .article-image-slides.owl-theme .owl-nav [class*=owl-] {
  color: #87a1c1;
  font-size: 18px;
  margin: 0;
  padding: 0;
  background: var(--whiteColor);
  width: 45px;
  height: 45px;
  line-height: 40px;
  position: absolute;
  left: 20px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 50%;
  border-style: solid;
  border-color: var(--whiteColor);
  border-width: 1.5px;
}

.blog-details-desc .article-image-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 20px;
}

.blog-details-desc .article-image-slides.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border-color: var(--mainColor);
}

.blog-details-desc .article-image-slides.owl-theme:hover .owl-nav {
  opacity: 1;
  visibility: visible;
}

.blog-details-desc .article-video iframe {
  width: 100%;
  height: 400px;
  border: none !important;
}

.blog-details-desc .article-content {
  margin-top: 30px;
}

.blog-details-desc .article-content .entry-meta ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.blog-details-desc .article-content .entry-meta ul li {
  margin-right: 20px;
  position: relative;
  display: inline-block;
  border-right: 1px solid #eeeeee;
  font-weight: 600;
  padding-right: 20px;
  padding-left: 45px;
}

.blog-details-desc .article-content .entry-meta ul li i {
  font-size: 32px;
  color: #cfcfcf;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.blog-details-desc .article-content .entry-meta ul li span {
  display: block;
  color: var(--mainColor);
  text-transform: uppercase;
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 600;
}

.blog-details-desc .article-content .entry-meta ul li:last-child {
  padding-right: 0;
  margin-right: 0;
  border-right: none;
}

.blog-details-desc .article-content h3 {
  margin-bottom: 15px;
  margin-top: 25px;
  font-size: 22px;
}

.blog-details-desc .article-content .wp-block-gallery.columns-3 {
  padding-left: 0;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.blog-details-desc .article-content .wp-block-gallery.columns-3 li {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.3333%;
          flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-right: 10px;
  padding-left: 10px;
}

.blog-details-desc .article-content .wp-block-gallery.columns-3 li figure {
  margin-bottom: 0;
}

.blog-details-desc .article-content .features-list {
  padding-left: 0;
  list-style-type: none;
  margin-top: 25px;
  margin-bottom: 30px;
}

.blog-details-desc .article-content .features-list li {
  margin-bottom: 15px;
  position: relative;
  padding-left: 26px;
  color: var(--optionalColor);
}

.blog-details-desc .article-content .features-list li i {
  color: var(--mainColor);
  display: inline-block;
  font-size: 20px;
  position: absolute;
  left: 0;
  top: 1px;
}

.blog-details-desc .article-content .features-list li:last-child {
  margin-bottom: 0;
}

.blog-details-desc .article-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  border-top: 1px solid #f1ecec;
  padding-top: 30px;
  margin-top: 30px;
}

.blog-details-desc .article-footer .article-tags {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
  color: var(--optionalColor);
}

.blog-details-desc .article-footer .article-tags span {
  display: inline-block;
  color: var(--blackColor);
  margin-right: 3px;
  position: relative;
  top: 3px;
  font-size: 20px;
}

.blog-details-desc .article-footer .article-tags a {
  display: inline-block;
  font-weight: 600;
}

.blog-details-desc .article-footer .article-share {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.blog-details-desc .article-footer .article-share .social {
  padding-left: 0;
  list-style-type: none;
  text-align: right;
  margin-bottom: 0;
}

.blog-details-desc .article-footer .article-share .social li {
  display: inline-block;
}

.blog-details-desc .article-footer .article-share .social li span {
  display: inline-block;
  margin-right: 3px;
  font-weight: 600;
  position: relative;
  top: -1px;
}

.blog-details-desc .article-footer .article-share .social li a {
  display: block;
  width: 32px;
  height: 32px;
  line-height: 34px;
  border-radius: 50%;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border: 1px solid var(--mainColor);
  text-align: center;
  font-size: 17px;
  margin-left: 2px;
}

.blog-details-desc .article-footer .article-share .social li a:hover, .blog-details-desc .article-footer .article-share .social li a:focus {
  color: var(--mainColor);
  background-color: transparent;
}

.blog-details-desc .article-footer .article-share .social li a.facebook {
  background-color: #3b5998;
  border-color: #3b5998;
  color: var(--whiteColor);
}

.blog-details-desc .article-footer .article-share .social li a.facebook:hover, .blog-details-desc .article-footer .article-share .social li a.facebook:focus {
  color: #3b5998;
  background-color: transparent;
}

.blog-details-desc .article-footer .article-share .social li a.twitter {
  background-color: #1da1f2;
  border-color: #1da1f2;
  color: var(--whiteColor);
}

.blog-details-desc .article-footer .article-share .social li a.twitter:hover, .blog-details-desc .article-footer .article-share .social li a.twitter:focus {
  color: #1da1f2;
  background-color: transparent;
}

.blog-details-desc .article-footer .article-share .social li a.linkedin {
  background-color: #007bb5;
  border-color: #007bb5;
  color: var(--whiteColor);
}

.blog-details-desc .article-footer .article-share .social li a.linkedin:hover, .blog-details-desc .article-footer .article-share .social li a.linkedin:focus {
  color: #007bb5;
  background-color: transparent;
}

.blog-details-desc .article-footer .article-share .social li a.instagram {
  background-color: #c13584;
  border-color: #c13584;
  color: var(--whiteColor);
}

.blog-details-desc .article-footer .article-share .social li a.instagram:hover, .blog-details-desc .article-footer .article-share .social li a.instagram:focus {
  color: #c13584;
  background-color: transparent;
}

.blog-details-desc .article-author {
  margin-top: 30px;
  border-radius: 5px;
  background: var(--whiteColor);
}

.blog-details-desc .article-author .author-profile-header {
  height: 115px;
  border-radius: 5px 5px 0 0;
  background-color: var(--mainColor);
  background-image: url(../../assets/img/bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.blog-details-desc .article-author .author-profile {
  padding: 0 25px 25px;
}

.blog-details-desc .article-author .author-profile .author-profile-title {
  position: relative;
  z-index: 1;
  margin-top: -45px;
}

.blog-details-desc .article-author .author-profile .author-profile-title img {
  display: inline-block;
  border: 3px solid var(--whiteColor);
  width: 100px;
  height: 100px;
  margin-bottom: 25px;
}

.blog-details-desc .article-author .author-profile .author-profile-title h4 {
  margin-bottom: 5px;
  font-size: 20px;
}

.blog-details-desc .article-author .author-profile .author-profile-title span {
  display: block;
  margin-bottom: 12px;
  color: var(--optionalColor);
}

blockquote, .blockquote {
  overflow: hidden;
  background-color: var(--whiteColor);
  padding: 50px !important;
  position: relative;
  text-align: center;
  z-index: 1;
  margin-bottom: 20px;
  margin-top: 20px;
}

blockquote p, .blockquote p {
  color: var(--blackColor);
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 700;
  font-size: 20px !important;
}

blockquote cite, .blockquote cite {
  display: none;
}

blockquote::before, .blockquote::before {
  color: #efefef;
  position: absolute;
  -webkit-animation: fade-up 1.5s infinite linear;
          animation: fade-up 1.5s infinite linear;
  left: 50px;
  top: -50px;
  z-index: -1;
  content: "\f111";
  font-family: Flaticon;
  font-size: 135px;
}

blockquote::after, .blockquote::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: var(--mainColor);
  margin-top: 20px;
  margin-bottom: 20px;
}

.drodo-post-navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid #f1ecec;
  border-bottom: 1px solid #f1ecec;
}

.prev-link-wrapper {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
  padding-right: 15px;
}

.prev-link-wrapper a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.prev-link-wrapper a:hover .image-prev::after {
  opacity: 1;
  visibility: visible;
}

.prev-link-wrapper a:hover .image-prev .post-nav-title {
  opacity: 1;
  visibility: visible;
}

.prev-link-wrapper a:hover .prev-link-info-wrapper {
  color: var(--mainColor);
}

.prev-link-wrapper .image-prev {
  display: inline-block;
  min-width: 100px;
  width: 100px;
  border-radius: 5px;
  overflow: hidden;
  vertical-align: top;
  margin-right: 20px;
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.prev-link-wrapper .image-prev img {
  border-radius: 5px;
}

.prev-link-wrapper .image-prev::after {
  display: block;
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  opacity: 0;
  background-color: var(--mainColor);
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.prev-link-wrapper .image-prev .post-nav-title {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  z-index: 2;
  color: var(--whiteColor);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: var(--fontSize);
  font-weight: 700;
}

.prev-link-wrapper .prev-link-info-wrapper {
  color: var(--blackColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.prev-link-wrapper .prev-title {
  display: inline-block;
  font-weight: 700;
  font-size: 16px;
}

.prev-link-wrapper .meta-wrapper {
  display: block;
  color: var(--optionalColor);
  text-transform: capitalize;
  margin-top: 7px;
  font-weight: 600;
  font-size: 14px;
}

.next-link-wrapper {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
  text-align: right;
  padding-left: 15px;
}

.next-link-wrapper a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.next-link-wrapper a:hover .image-next::after {
  opacity: 1;
  visibility: visible;
}

.next-link-wrapper a:hover .image-next .post-nav-title {
  opacity: 1;
  visibility: visible;
}

.next-link-wrapper a:hover .next-link-info-wrapper {
  color: var(--mainColor);
}

.next-link-wrapper .image-next {
  display: inline-block;
  min-width: 100px;
  width: 100px;
  border-radius: 5px;
  overflow: hidden;
  vertical-align: top;
  margin-left: 20px;
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.next-link-wrapper .image-next img {
  border-radius: 5px;
}

.next-link-wrapper .image-next::after {
  display: block;
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  opacity: 0;
  background-color: var(--mainColor);
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.next-link-wrapper .image-next .post-nav-title {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  z-index: 2;
  color: var(--whiteColor);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: var(--fontSize);
  font-weight: 700;
}

.next-link-wrapper .next-link-info-wrapper {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--blackColor);
}

.next-link-wrapper .next-title {
  display: inline-block;
  font-weight: 700;
  font-size: 16px;
}

.next-link-wrapper .meta-wrapper {
  display: block;
  color: var(--optionalColor);
  text-transform: capitalize;
  margin-top: 7px;
  font-weight: 600;
  font-size: 14px;
}

.comments-area {
  margin-top: 30px;
}

.comments-area .comments-title {
  line-height: initial;
  margin-bottom: 30px;
  font-size: 22px;
}

.comments-area ol, .comments-area ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.comments-area .comment-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.comments-area .children {
  margin-left: 20px;
}

.comments-area .comment-body {
  border-bottom: 1px dashed #eeeeee;
  padding-left: 70px;
  color: var(--blackColor);
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.comments-area .comment-body .reply {
  margin-top: 15px;
}

.comments-area .comment-body .reply a {
  border: 1px dashed #ded9d9;
  color: var(--blackColor);
  display: inline-block;
  padding: 6px 20px;
  border-radius: 30px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
}

.comments-area .comment-body .reply a:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}

.comments-area .comment-author {
  font-size: 15px;
  margin-bottom: 8px;
  position: relative;
  z-index: 2;
}

.comments-area .comment-author .avatar {
  height: 50px;
  left: -65px;
  position: absolute;
  width: 50px;
}

.comments-area .comment-author .fn {
  font-weight: 700;
}

.comments-area .comment-author .says {
  display: none;
}

.comments-area .comment-metadata {
  margin-bottom: .8em;
  color: var(--optionalColor);
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
}

.comments-area .comment-metadata a {
  display: inline-block;
  color: var(--optionalColor);
}

.comments-area .comment-metadata a:hover {
  color: var(--mainColor);
}

.comments-area .comment-respond {
  margin-top: 30px;
}

.comments-area .comment-respond .comment-reply-title {
  margin-bottom: 0;
  font-size: 22px;
}

.comments-area .comment-respond .comment-reply-title #cancel-comment-reply-link {
  display: inline-block;
}

.comments-area .comment-respond .comment-form {
  overflow: hidden;
}

.comments-area .comment-respond .comment-notes {
  margin-bottom: 20px;
  margin-top: 10px;
}

.comments-area .comment-respond .comment-notes .required {
  color: red;
}

.comments-area .comment-respond .comment-form-comment {
  float: left;
  width: 100%;
}

.comments-area .comment-respond label {
  display: none;
}

.comments-area .comment-respond input[type="date"], .comments-area .comment-respond input[type="time"], .comments-area .comment-respond input[type="datetime-local"], .comments-area .comment-respond input[type="week"], .comments-area .comment-respond input[type="month"], .comments-area .comment-respond input[type="text"], .comments-area .comment-respond input[type="email"], .comments-area .comment-respond input[type="url"], .comments-area .comment-respond input[type="password"], .comments-area .comment-respond input[type="search"], .comments-area .comment-respond input[type="tel"], .comments-area .comment-respond input[type="number"], .comments-area .comment-respond textarea {
  display: block;
  width: 100%;
  background-color: var(--whiteColor);
  border: none;
  padding: 1px 0 0 15px;
  height: 50px;
  outline: 0;
  border-radius: 3px;
  color: var(--blackColor);
  font-size: 15px;
}

.comments-area .comment-respond input[type="date"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="time"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="datetime-local"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="week"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="month"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="text"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="email"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="url"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="password"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="search"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="tel"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="number"]::-webkit-input-placeholder, .comments-area .comment-respond textarea::-webkit-input-placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--optionalColor);
}

.comments-area .comment-respond input[type="date"]:-ms-input-placeholder, .comments-area .comment-respond input[type="time"]:-ms-input-placeholder, .comments-area .comment-respond input[type="datetime-local"]:-ms-input-placeholder, .comments-area .comment-respond input[type="week"]:-ms-input-placeholder, .comments-area .comment-respond input[type="month"]:-ms-input-placeholder, .comments-area .comment-respond input[type="text"]:-ms-input-placeholder, .comments-area .comment-respond input[type="email"]:-ms-input-placeholder, .comments-area .comment-respond input[type="url"]:-ms-input-placeholder, .comments-area .comment-respond input[type="password"]:-ms-input-placeholder, .comments-area .comment-respond input[type="search"]:-ms-input-placeholder, .comments-area .comment-respond input[type="tel"]:-ms-input-placeholder, .comments-area .comment-respond input[type="number"]:-ms-input-placeholder, .comments-area .comment-respond textarea:-ms-input-placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--optionalColor);
}

.comments-area .comment-respond input[type="date"]::-ms-input-placeholder, .comments-area .comment-respond input[type="time"]::-ms-input-placeholder, .comments-area .comment-respond input[type="datetime-local"]::-ms-input-placeholder, .comments-area .comment-respond input[type="week"]::-ms-input-placeholder, .comments-area .comment-respond input[type="month"]::-ms-input-placeholder, .comments-area .comment-respond input[type="text"]::-ms-input-placeholder, .comments-area .comment-respond input[type="email"]::-ms-input-placeholder, .comments-area .comment-respond input[type="url"]::-ms-input-placeholder, .comments-area .comment-respond input[type="password"]::-ms-input-placeholder, .comments-area .comment-respond input[type="search"]::-ms-input-placeholder, .comments-area .comment-respond input[type="tel"]::-ms-input-placeholder, .comments-area .comment-respond input[type="number"]::-ms-input-placeholder, .comments-area .comment-respond textarea::-ms-input-placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--optionalColor);
}

.comments-area .comment-respond input[type="date"]::placeholder, .comments-area .comment-respond input[type="time"]::placeholder, .comments-area .comment-respond input[type="datetime-local"]::placeholder, .comments-area .comment-respond input[type="week"]::placeholder, .comments-area .comment-respond input[type="month"]::placeholder, .comments-area .comment-respond input[type="text"]::placeholder, .comments-area .comment-respond input[type="email"]::placeholder, .comments-area .comment-respond input[type="url"]::placeholder, .comments-area .comment-respond input[type="password"]::placeholder, .comments-area .comment-respond input[type="search"]::placeholder, .comments-area .comment-respond input[type="tel"]::placeholder, .comments-area .comment-respond input[type="number"]::placeholder, .comments-area .comment-respond textarea::placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--optionalColor);
}

.comments-area .comment-respond input[type="date"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="time"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="datetime-local"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="week"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="month"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="text"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="email"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="url"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="password"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="search"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="tel"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="number"]:focus::-webkit-input-placeholder, .comments-area .comment-respond textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

.comments-area .comment-respond input[type="date"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="time"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="datetime-local"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="week"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="month"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="text"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="email"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="url"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="password"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="search"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="tel"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="number"]:focus:-ms-input-placeholder, .comments-area .comment-respond textarea:focus:-ms-input-placeholder {
  color: transparent;
}

.comments-area .comment-respond input[type="date"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="time"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="datetime-local"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="week"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="month"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="text"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="email"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="url"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="password"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="search"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="tel"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="number"]:focus::-ms-input-placeholder, .comments-area .comment-respond textarea:focus::-ms-input-placeholder {
  color: transparent;
}

.comments-area .comment-respond input[type="date"]:focus::placeholder, .comments-area .comment-respond input[type="time"]:focus::placeholder, .comments-area .comment-respond input[type="datetime-local"]:focus::placeholder, .comments-area .comment-respond input[type="week"]:focus::placeholder, .comments-area .comment-respond input[type="month"]:focus::placeholder, .comments-area .comment-respond input[type="text"]:focus::placeholder, .comments-area .comment-respond input[type="email"]:focus::placeholder, .comments-area .comment-respond input[type="url"]:focus::placeholder, .comments-area .comment-respond input[type="password"]:focus::placeholder, .comments-area .comment-respond input[type="search"]:focus::placeholder, .comments-area .comment-respond input[type="tel"]:focus::placeholder, .comments-area .comment-respond input[type="number"]:focus::placeholder, .comments-area .comment-respond textarea:focus::placeholder {
  color: transparent;
}

.comments-area .comment-respond textarea {
  height: auto !important;
  padding-top: 15px;
}

.comments-area .comment-respond .comment-form-author {
  float: left;
  width: 50%;
  padding-right: 10px;
  margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-email {
  float: left;
  width: 50%;
  padding-left: 12px;
  margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-url {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-cookies-consent {
  width: 100%;
  float: left;
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
  margin-top: 15px;
}

.comments-area .comment-respond .comment-form-cookies-consent input {
  position: absolute;
  left: 0;
  top: 6px;
}

.comments-area .comment-respond .comment-form-cookies-consent label {
  display: inline-block;
  margin: 0;
  color: var(--optionalColor);
  font-weight: normal;
  font-size: var(--fontSize);
}

.comments-area .comment-respond .form-submit {
  float: left;
  width: 100%;
}

.comments-area .comment-respond .form-submit input {
  background: var(--mainColor);
  border: none;
  color: var(--whiteColor);
  padding: 11px 30px;
  display: inline-block;
  cursor: pointer;
  text-transform: capitalize;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 0;
  font-weight: 700;
  font-size: 15px;
}

.comments-area .comment-respond .form-submit input:hover, .comments-area .comment-respond .form-submit input:focus {
  color: var(--whiteColor);
  background: var(--blackColor);
}

/*================================================
Facility Area CSS
=================================================*/
.single-facility-box {
  margin-bottom: 30px;
  position: relative;
  border-right: 1px solid #e2e2e2;
  padding-left: 62px;
}

.single-facility-box .icon {
  position: absolute;
  left: 0;
  top: 0;
  line-height: 1;
  color: var(--mainColor);
  font-size: 45px;
}

.single-facility-box h3 {
  margin-bottom: 8px;
  font-size: 22px;
}

.single-facility-box p {
  font-size: 14.5px;
  line-height: initial;
}

.col-lg-3:nth-child(4) .single-facility-box, .col-lg-3:nth-child(8) .single-facility-box, .col-lg-3:nth-child(12) .single-facility-box {
  border-right: none;
}

/*================================================
Gallery Area CSS
=================================================*/
.single-gallery-item {
  position: relative;
  margin-bottom: 30px;
  z-index: 1;
  border-radius: 5px;
  overflow: hidden;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.single-gallery-item a {
  display: block;
  border-radius: 5px;
}

.single-gallery-item a img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-gallery-item:hover a img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-filter: blur(2px);
          filter: blur(2px);
}

/*================================================
History Area CSS
=================================================*/
.history-timeline {
  position: relative;
  list-style-type: none;
  padding-left: 0;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

.history-timeline::before {
  content: '';
  width: 1px;
  height: 100%;
  background-color: #ebebeb;
  top: 0;
  left: 130px;
  position: absolute;
}

.history-timeline .timeline-block {
  position: relative;
  padding-left: 165px;
  margin-bottom: 30px;
}

.history-timeline .timeline-block:last-child {
  margin-bottom: 0;
}

.history-timeline .timeline-block:last-child::before {
  display: none;
}

.history-timeline .timeline-block::before {
  content: "\ea17";
  position: absolute;
  left: 116px;
  color: var(--mainColor);
  bottom: -25px;
  -webkit-animation: fade-down 1.5s infinite linear;
          animation: fade-down 1.5s infinite linear;
  font-family: "boxicons" !important;
  font-size: 30px;
}

.history-timeline .timeline-block .timeline-date {
  text-align: right;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: var(--optionalColor);
  font-size: 15px;
}

.history-timeline .timeline-block .timeline-date span {
  display: block;
  margin-bottom: 3px;
  color: var(--blackColor);
  font-weight: 700;
}

.history-timeline .timeline-block .timeline-date sup {
  color: var(--blackColor);
  font-weight: 600;
}

.history-timeline .timeline-block .timeline-icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 126px;
}

.history-timeline .timeline-block .timeline-icon .dot-badge {
  background-color: var(--mainColor);
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.history-timeline .timeline-block .timeline-content {
  background-color: #f7f8fa;
  padding: 30px;
}

.history-timeline .timeline-block .timeline-content .content {
  padding-left: 5px;
  padding-right: 15px;
}

.history-timeline .timeline-block .timeline-content .content h3 {
  margin-bottom: 15px;
  font-size: 23px;
}

/*================================================
Doctors Area CSS
=================================================*/
.single-doctors-box {
  margin-bottom: 30px;
}

.single-doctors-box .image {
  position: relative;
  text-align: center;
}

.single-doctors-box .image .social {
  padding-left: 0;
  list-style-type: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

.single-doctors-box .image .social li {
  display: inline-block;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  opacity: 0;
  visibility: hidden;
  margin-left: 1px;
  margin-right: 1px;
}

.single-doctors-box .image .social li a {
  display: block;
  width: 33px;
  height: 33px;
  background-color: var(--whiteColor);
  text-align: center;
  position: relative;
  font-size: 20px;
  color: var(--blackColor);
  border-radius: 2px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-doctors-box .image .social li a i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
}

.single-doctors-box .image .social li a:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.single-doctors-box .image .social li:nth-child(1) {
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}

.single-doctors-box .image .social li:nth-child(2) {
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}

.single-doctors-box .image .social li:nth-child(3) {
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
}

.single-doctors-box .image .social li:nth-child(4) {
  -webkit-transition-delay: 0.4s;
          transition-delay: 0.4s;
}

.single-doctors-box .image .social li:nth-child(5) {
  -webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
}

.single-doctors-box .content {
  margin-top: 25px;
}

.single-doctors-box .content h3 {
  margin-bottom: 5px;
  font-size: 22px;
}

.single-doctors-box .content span {
  color: var(--mainColor);
  font-weight: 600;
  display: block;
}

.single-doctors-box:hover .image .social li {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

/*================================================
Funfacts Area CSS
=================================================*/
.single-funfacts-box {
  margin-bottom: 30px;
  text-align: center;
}

.single-funfacts-box h3 {
  font-size: 45px;
  color: var(--mainColor);
  margin-bottom: 8px;
  line-height: 1;
}

.single-funfacts-box span {
  display: block;
  font-weight: 600;
  font-size: 17px;
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
  text-align: center;
  background-color: #f7f8fa;
  padding-top: 80px;
  padding-bottom: 80px;
}

.page-title-content h1 {
  margin-bottom: 0;
  font-size: 40px;
}

.page-title-content ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 12px;
}

.page-title-content ul li {
  display: inline-block;
  position: relative;
  color: var(--mainColor);
  font-weight: 600;
  font-size: 16px;
  margin-left: 10px;
  margin-right: 10px;
}

.page-title-content ul li::before {
  width: 1px;
  height: 14px;
  content: '';
  position: absolute;
  right: -13px;
  top: 6.2px;
  background-color: #404040;
  -webkit-transform: rotate(10deg);
          transform: rotate(10deg);
}

.page-title-content ul li:first-child {
  margin-left: 0;
}

.page-title-content ul li:last-child {
  margin-right: 0;
}

.page-title-content ul li:last-child::before {
  display: none;
}

.page-title-content ul li a {
  display: inline-block;
  color: var(--optionalColor);
}

.page-title-content ul li a:hover {
  color: var(--mainColor);
}

/*================================================
Pagination CSS
=================================================*/
.pagination-area {
  margin-top: 25px;
}

.pagination-area .page-numbers {
  width: 40px;
  height: 40px;
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 15px 2px #e4e4ee;
          box-shadow: 0 0 15px 2px #e4e4ee;
  color: var(--blackColor);
  text-align: center;
  display: inline-block;
  border-radius: 0;
  line-height: 41px;
  position: relative;
  margin-left: 3px;
  margin-right: 3px;
  font-weight: 700;
  font-size: 16px;
}

.pagination-area .page-numbers:hover, .pagination-area .page-numbers.current {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.pagination-area .page-numbers i {
  position: relative;
  top: 1.5px;
}

/*================================================
Widget Sidebar CSS
=================================================*/
.widget-area {
  padding-left: 10px;
}

.widget-area.widget-left-sidebar {
  padding-right: 10px;
  padding-left: 0;
}

.widget-area .widget {
  margin-bottom: 30px;
  background-color: #f7f8fa;
  padding: 25px;
}

.widget-area .widget:last-child {
  margin-bottom: 0;
}

.widget-area .widget .widget-title {
  margin-bottom: 25px;
  position: relative;
  padding-bottom: 7px;
  border-bottom: 1px solid #eeeeee;
  font-size: 21px;
}

.widget-area .widget .widget-title::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 1px;
  bottom: -1px;
  background-color: var(--mainColor);
}

.widget-area .widget_search form {
  position: relative;
}

.widget-area .widget_search form .screen-reader-text {
  display: none;
}

.widget-area .widget_search form label {
  display: block;
  margin-bottom: 0;
}

.widget-area .widget_search form .search-field {
  height: 50px;
  color: var(--blackColor);
  background-color: var(--whiteColor);
  display: block;
  width: 100%;
  border-radius: 5px;
  padding: 0 0 0 15px;
  border: none;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-weight: 500;
}

.widget-area .widget_search form .search-field::-webkit-input-placeholder {
  color: var(--optionalColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.widget-area .widget_search form .search-field:-ms-input-placeholder {
  color: var(--optionalColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.widget-area .widget_search form .search-field::-ms-input-placeholder {
  color: var(--optionalColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.widget-area .widget_search form .search-field::placeholder {
  color: var(--optionalColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.widget-area .widget_search form .search-field:focus::-webkit-input-placeholder {
  color: transparent;
}

.widget-area .widget_search form .search-field:focus:-ms-input-placeholder {
  color: transparent;
}

.widget-area .widget_search form .search-field:focus::-ms-input-placeholder {
  color: transparent;
}

.widget-area .widget_search form .search-field:focus::placeholder {
  color: transparent;
}

.widget-area .widget_search form button {
  border: none;
  background-color: var(--whiteColor);
  color: var(--mainColor);
  height: 40px;
  width: 40px;
  position: absolute;
  right: 5px;
  padding: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  top: 5px;
  font-size: 20px;
}

.widget-area .widget_search form button i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.widget-area .widget_search form button:hover, .widget-area .widget_search form button:focus {
  border-radius: 5px;
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.widget-area .widget_drodo_posts_thumb {
  position: relative;
  overflow: hidden;
}

.widget-area .widget_drodo_posts_thumb .item {
  overflow: hidden;
  margin-bottom: 15px;
}

.widget-area .widget_drodo_posts_thumb .item:last-child {
  margin-bottom: 0;
}

.widget-area .widget_drodo_posts_thumb .item .thumb {
  float: left;
  height: 80px;
  overflow: hidden;
  display: block;
  position: relative;
  width: 80px;
  margin-right: 15px;
  z-index: 1;
}

.widget-area .widget_drodo_posts_thumb .item .thumb .fullimage {
  width: 80px;
  height: 80px;
  display: inline-block;
  border-radius: 5px;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
}

.widget-area .widget_drodo_posts_thumb .item .thumb .fullimage.bg1 {
  background-image: url(../../assets/img/blog/blog-img1.jpg);
}

.widget-area .widget_drodo_posts_thumb .item .thumb .fullimage.bg2 {
  background-image: url(../../assets/img/blog/blog-img2.jpg);
}

.widget-area .widget_drodo_posts_thumb .item .thumb .fullimage.bg3 {
  background-image: url(../../assets/img/blog/blog-img3.jpg);
}

.widget-area .widget_drodo_posts_thumb .item .thumb::before, .widget-area .widget_drodo_posts_thumb .item .thumb::after {
  -webkit-transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
  transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
  content: '';
  background-color: var(--whiteColor);
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.widget-area .widget_drodo_posts_thumb .item .thumb::before {
  width: 40px;
  height: 1px;
  left: 100%;
}

.widget-area .widget_drodo_posts_thumb .item .thumb::after {
  height: 40px;
  width: 1px;
  top: 0;
}

.widget-area .widget_drodo_posts_thumb .item .info {
  overflow: hidden;
  margin-top: 5px;
}

.widget-area .widget_drodo_posts_thumb .item .info span {
  display: block;
  color: var(--optionalColor);
  text-transform: uppercase;
  margin-top: -2px;
  margin-bottom: 5px;
  font-size: 13px;
}

.widget-area .widget_drodo_posts_thumb .item .info .title {
  margin-bottom: 0;
  line-height: 1.4;
  font-size: 17px;
  font-weight: 700;
}

.widget-area .widget_drodo_posts_thumb .item .info .title a {
  display: inline-block;
}

.widget-area .widget_drodo_posts_thumb .item:hover .thumb::before, .widget-area .widget_drodo_posts_thumb .item:hover .thumb::after {
  opacity: 1;
  top: 50%;
  left: 50%;
}

.widget-area .widget_categories ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.widget-area .widget_categories ul li {
  position: relative;
  margin-bottom: 12px;
  overflow: hidden;
  color: var(--blackColor);
  padding-left: 18px;
  font-weight: 600;
  font-size: 14.5px;
}

.widget-area .widget_categories ul li:last-child {
  margin-bottom: 0;
}

.widget-area .widget_categories ul li::before {
  background: var(--mainColor);
  height: 8px;
  width: 8px;
  content: '';
  border-radius: 50%;
  left: 0;
  top: 7px;
  position: absolute;
}

.widget-area .widget_categories ul li a {
  color: var(--blackColor);
  display: inline-block;
}

.widget-area .widget_categories ul li a:hover {
  color: var(--mainColor);
}

.widget-area .widget_categories ul li .post-count {
  font-size: 14px;
  display: inline-block;
  position: relative;
  top: 1px;
}

.widget-area .widget_tag_cloud .widget-title {
  margin-bottom: 17px;
}

.widget-area .tagcloud a {
  display: inline-block;
  background: var(--whiteColor);
  color: var(--blackColor);
  padding: 7px 15px;
  border: none;
  border-radius: 3px;
  font-weight: 600;
  font-size: 14px !important;
  margin-top: 8px;
  margin-right: 4px;
}

.widget-area .tagcloud a:hover, .widget-area .tagcloud a:focus {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.widget-area .widget_instagram ul {
  padding-left: 0;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 0;
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -10px;
}

.widget-area .widget_instagram ul li {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.333333%;
          flex: 0 0 33.333333%;
  max-width: 33.333333%;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
}

.widget-area .widget_instagram ul li .box {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.widget-area .widget_instagram ul li .box .link-btn {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
}

.widget-area .widget_instagram ul li .box i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 30px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
  color: var(--whiteColor);
  z-index: 2;
}

.widget-area .widget_instagram ul li .box img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.widget-area .widget_instagram ul li .box::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
}

.widget-area .widget_instagram ul li .box:hover::before {
  opacity: 0.5;
  visibility: visible;
}

.widget-area .widget_instagram ul li .box:hover img {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.widget-area .widget_instagram ul li .box:hover i {
  opacity: 1;
  visibility: visible;
}

.widget-area .widget_insight ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.widget-area .widget_insight ul li {
  margin-bottom: -1px;
}

.widget-area .widget_insight ul li a {
  position: relative;
  display: block;
  padding: 15px 25px 15px 40px;
  color: var(--blackColor);
  border: 1px solid #eeeeee;
  background-color: var(--whiteColor);
  font-weight: 700;
  font-size: 15px;
}

.widget-area .widget_insight ul li a::before {
  width: 5px;
  height: 5px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: var(--optionalColor);
  content: '';
  position: absolute;
  left: 25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 50%;
}

.widget-area .widget_insight ul li a:hover, .widget-area .widget_insight ul li a.active {
  border-color: var(--mainColor);
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.widget-area .widget_insight ul li a:hover::before, .widget-area .widget_insight ul li a.active::before {
  background-color: var(--whiteColor);
}

.widget-area .widget_insight ul li.active a {
  border-color: var(--mainColor);
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.widget-area .widget_insight ul li.active a::before {
  background-color: var(--whiteColor);
}

/*================================================
Profile Authentication Area CSS
=================================================*/
.login-form {
  padding: 40px 50px;
  border-radius: 5px;
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 10px rgba(51, 51, 51, 0.1);
          box-shadow: 0 0 10px rgba(51, 51, 51, 0.1);
  margin-right: 30px;
}

.login-form h2 {
  margin-bottom: 25px;
  font-size: 30px;
}

.login-form form .form-group {
  margin-bottom: 25px;
}

.login-form form .form-group label {
  display: block;
  margin-bottom: 10px;
  color: var(--blackColor);
  font-weight: 600;
}

.login-form form .remember-me-wrap {
  margin-bottom: 0;
}

.login-form form .remember-me-wrap [type="checkbox"]:checked, .login-form form .remember-me-wrap [type="checkbox"]:not(:checked) {
  display: none;
}

.login-form form .remember-me-wrap [type="checkbox"]:checked + label, .login-form form .remember-me-wrap [type="checkbox"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  margin-bottom: 0;
  color: var(--optionalColor);
  font-weight: 600;
}

.login-form form .remember-me-wrap [type="checkbox"]:checked + label:before, .login-form form .remember-me-wrap [type="checkbox"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 19px;
  height: 19px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: 1px solid #f5f5f5;
  border-radius: 3px;
  background: #f5f5f5;
}

.login-form form .remember-me-wrap [type="checkbox"]:checked + label:after, .login-form form .remember-me-wrap [type="checkbox"]:not(:checked) + label:after {
  content: '';
  width: 8px;
  height: 8px;
  background: var(--mainColor);
  position: absolute;
  top: 5.5px;
  left: 6px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.login-form form .remember-me-wrap [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.login-form form .remember-me-wrap [type="checkbox"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.login-form form .remember-me-wrap [type="checkbox"]:hover + label:before {
  border-color: var(--mainColor);
}

.login-form form .remember-me-wrap [type="checkbox"]:checked + label:before {
  border-color: var(--mainColor);
}

.login-form form .lost-your-password-wrap {
  text-align: right;
}

.login-form form .lost-your-password-wrap a {
  display: inline-block;
  position: relative;
  font-weight: 600;
  line-height: 1.3;
}

.login-form form .lost-your-password-wrap a::before {
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
  content: '';
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: #eeeeee;
}

.login-form form .lost-your-password-wrap a::after {
  width: 0;
  height: 1px;
  position: absolute;
  left: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  bottom: 0;
  content: '';
  background-color: var(--mainColor);
}

.login-form form .lost-your-password-wrap a:hover::before {
  width: 0;
}

.login-form form .lost-your-password-wrap a:hover::after {
  width: 100%;
}

.login-form form button {
  margin-top: 22px;
  border: none;
  display: block;
  text-align: center;
  overflow: hidden;
  color: var(--whiteColor);
  background-color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  border-radius: 5px;
  padding: 14px 30px;
  font-weight: 700;
}

.login-form form button:hover {
  background-color: var(--blackColor);
  color: var(--whiteColor);
}

.register-form {
  padding-top: 30px;
  padding-left: 30px;
}

.register-form h2 {
  margin-bottom: 25px;
  font-size: 30px;
}

.register-form form .form-group {
  margin-bottom: 25px;
}

.register-form form .form-group label {
  display: block;
  margin-bottom: 10px;
  color: var(--blackColor);
  font-weight: 600;
}

.register-form form .description {
  font-style: italic;
  font-size: 13.5px;
  margin-top: -10px;
  margin-bottom: 0;
}

.register-form form button {
  margin-top: 22px;
  border: none;
  display: block;
  text-align: center;
  overflow: hidden;
  color: var(--whiteColor);
  background-color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  border-radius: 5px;
  padding: 14.5px 30px;
  font-weight: 700;
}

.register-form form button:hover {
  background-color: var(--blackColor);
  color: var(--whiteColor);
}

/*================================================
404 Error Area CSS
=================================================*/
.error-content {
  text-align: center;
}

.error-content h3 {
  margin-bottom: 20px;
  margin-top: 45px;
  font-size: 40px;
}

.error-content p {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

.error-content .default-btn {
  margin-top: 30px;
}

/*================================================
Coming Soon Area CSS
=================================================*/
.coming-soon-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
  background-image: url(../../assets/img/coming-soon-bg.jpg);
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.coming-soon-content {
  max-width: 700px;
  background: var(--whiteColor);
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  padding: 40px 60px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
          box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  margin-left: auto;
  margin-right: auto;
}

.coming-soon-content .logo {
  display: inline-block;
}

.coming-soon-content h2 {
  font-size: 48px;
  margin-top: 30px;
  margin-bottom: 0;
}

.coming-soon-content #timer {
  margin-top: 40px;
}

.coming-soon-content #timer div {
  background-color: var(--blackColor);
  color: var(--whiteColor);
  width: 100px;
  height: 105px;
  border-radius: 5px;
  font-size: 40px;
  font-weight: 700;
  margin-left: 10px;
  margin-right: 10px;
}

.coming-soon-content #timer div span {
  display: block;
  margin-top: -2px;
  font-size: 16.5px;
  font-weight: 600;
}

.coming-soon-content form {
  position: relative;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.coming-soon-content form .form-group {
  margin-bottom: 25px;
  width: 100%;
  position: relative;
}

.coming-soon-content form .form-group .label-title {
  margin-bottom: 0;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  color: var(--blackColor);
}

.coming-soon-content form .form-group .label-title i {
  position: absolute;
  left: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  top: 12px;
  font-size: 22px;
}

.coming-soon-content form .form-group .label-title::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background: var(--mainColor);
}

.coming-soon-content form .form-group .input-newsletter {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #eeeeee;
  padding: 0 0 0 32px;
  color: var(--blackColor);
  height: 45px;
  display: block;
  width: 100%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 16px;
  font-weight: 400;
}

.coming-soon-content form .form-group .input-newsletter::-webkit-input-placeholder {
  color: #A1A1A1;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.coming-soon-content form .form-group .input-newsletter:-ms-input-placeholder {
  color: #A1A1A1;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.coming-soon-content form .form-group .input-newsletter::-ms-input-placeholder {
  color: #A1A1A1;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.coming-soon-content form .form-group .input-newsletter::placeholder {
  color: #A1A1A1;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.coming-soon-content form .form-group .input-newsletter:focus::-webkit-input-placeholder {
  color: transparent;
}

.coming-soon-content form .form-group .input-newsletter:focus:-ms-input-placeholder {
  color: transparent;
}

.coming-soon-content form .form-group .input-newsletter:focus::-ms-input-placeholder {
  color: transparent;
}

.coming-soon-content form .form-group .input-newsletter:focus::placeholder {
  color: transparent;
}

.coming-soon-content form .default-btn {
  border-radius: 0;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 30px;
  padding-right: 30px;
}

.coming-soon-content form .validation-danger {
  margin-top: 15px;
  color: red;
}

.coming-soon-content form .validation-success {
  margin-top: 15px;
}

.coming-soon-content form p {
  margin-bottom: 0;
  margin-top: 20px;
}

/*================================================
Purchase Guide Area CSS
=================================================*/
.purchase-guide-content img {
  margin-bottom: 30px;
}

.purchase-guide-content h3 {
  margin-bottom: 15px;
  margin-top: 25px;
  font-size: 22px;
}

.purchase-guide-content ol, .purchase-guide-content ul {
  margin-top: 20px;
}

.purchase-guide-content ol li, .purchase-guide-content ul li {
  margin-bottom: 10px;
  color: var(--optionalColor);
  line-height: 1.8;
}

/*================================================
Privacy Policy Area CSS
=================================================*/
.privacy-policy-content img {
  margin-bottom: 30px;
}

.privacy-policy-content h3 {
  margin-bottom: 15px;
  margin-top: 25px;
  font-size: 22px;
}

.privacy-policy-content ol, .privacy-policy-content ul {
  margin-top: 20px;
}

.privacy-policy-content ol li, .privacy-policy-content ul li {
  margin-bottom: 10px;
  color: var(--optionalColor);
  line-height: 1.8;
}

/*================================================
Terms Of Service Area CSS
=================================================*/
.terms-of-service-content img {
  margin-bottom: 30px;
}

.terms-of-service-content h3 {
  margin-bottom: 15px;
  margin-top: 25px;
  font-size: 22px;
}

.terms-of-service-content ol, .terms-of-service-content ul {
  margin-top: 20px;
}

.terms-of-service-content ol li, .terms-of-service-content ul li {
  margin-bottom: 10px;
  color: var(--optionalColor);
  line-height: 1.8;
}

/*================================================
Customer Service Area CSS
=================================================*/
.customer-service-content h3 {
  position: relative;
  padding-left: 30px;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 700;
}

.customer-service-content h3 i {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--mainColor);
}

.customer-service-content h3:not(:first-child) {
  margin-top: 30px;
}

.customer-service-content ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.customer-service-content ul li {
  color: var(--optionalColor);
  margin-bottom: 12px;
  position: relative;
  padding-left: 13px;
}

.customer-service-content ul li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 9px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: var(--blackColor);
}

.customer-service-content ul li:last-child {
  margin-bottom: 0;
}

.customer-service-content ul li a {
  display: inline-block;
  color: var(--optionalColor);
}

.customer-service-content ul li a:hover {
  color: var(--mainColor);
}

/*================================================
FAQ Area CSS
=================================================*/
.faq-accordion-tab .tabs {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 60px;
  text-align: center;
}

.faq-accordion-tab .tabs li {
  margin-left: 10px;
  margin-right: 10px;
}

.faq-accordion-tab .tabs li a {
  color: var(--blackColor);
  text-decoration: none;
  border-radius: 5px;
  padding: 20px;
  display: block;
  -webkit-box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
          box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
  font-weight: 700;
}

.faq-accordion-tab .tabs li a i {
  color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 30px;
  font-weight: 400;
}

.faq-accordion-tab .tabs li a span {
  display: block;
  margin-top: 8px;
}

.faq-accordion-tab .tabs li a:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.faq-accordion-tab .tabs li a:hover i {
  color: var(--whiteColor);
}

.faq-accordion-tab .tabs li.current a {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.faq-accordion-tab .tabs li.current a i {
  color: var(--whiteColor);
}

.faq-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.faq-accordion .accordion .accordion-item {
  border-radius: 5px;
  display: block;
  -webkit-box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
          box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
  background: var(--whiteColor);
  margin-bottom: 15px;
}

.faq-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}

.faq-accordion .accordion .accordion-title {
  padding: 20px 25px 17px 60px;
  color: var(--blackColor);
  text-decoration: none;
  position: relative;
  display: block;
  font-size: 16px;
  font-weight: 700;
}

.faq-accordion .accordion .accordion-title i {
  position: absolute;
  left: 25px;
  top: 18px;
  font-size: 25px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.faq-accordion .accordion .accordion-title.active i {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.faq-accordion .accordion .accordion-content {
  display: none;
  position: relative;
  margin-top: -5px;
  padding-bottom: 20px;
  padding-right: 25px;
  padding-left: 60px;
}

.faq-accordion .accordion .accordion-content p {
  line-height: 1.8;
}

.faq-accordion .accordion .accordion-content.show {
  display: block;
}

.tab .tabs-item {
  display: none;
}

.tab .tabs-item:first-child {
  display: block;
}

/*================================================
Tracking Order Area CSS
=================================================*/
.track-order-content {
  max-width: 700px;
  background-color: var(--whiteColor);
  -webkit-box-shadow: 3px 5px 25px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 3px 5px 25px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 40px;
  margin-left: auto;
  margin-right: auto;
}

.track-order-content form {
  padding-left: 30px;
  padding-right: 30px;
}

.track-order-content form .form-group {
  text-align: left;
}

.track-order-content form .form-group label {
  display: block;
  color: var(--blackColor);
  margin-bottom: 9px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

.track-order-content form .default-btn {
  margin-top: 5px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
}

/*================================================
Contact Area CSS
=================================================*/
.single-contact-info-box {
  text-align: center;
  margin-bottom: 30px;
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
}

.single-contact-info-box .icon {
  font-size: 40px;
  line-height: 1;
  margin-bottom: 25px;
  color: var(--mainColor);
}

.single-contact-info-box h3 {
  font-size: 22px;
  margin-bottom: 12px;
}

.single-contact-info-box p {
  margin-bottom: 0;
}

.single-contact-info-box p a {
  color: var(--optionalColor);
  display: inline-block;
}

.single-contact-info-box p a:hover {
  color: var(--mainColor);
}

.contact-form {
  padding-right: 30px;
}

.contact-form .sub-title {
  display: block;
  font-weight: 600;
  margin-bottom: 10px;
  color: var(--mainColor);
}

.contact-form h2 {
  margin-bottom: 0;
  font-size: 30px;
}

.contact-form form {
  margin-top: 35px;
}

.contact-form form label {
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
  color: var(--blackColor);
}

.contact-form form .form-group {
  margin-bottom: 25px;
}

.contact-form form .default-btn {
  padding: 13px 35px;
}

.contact-form form .help-block.with-errors {
  color: red;
  margin-bottom: 0;
  margin-top: 15px;
}

#maps iframe {
  height: 500px;
  width: 100%;
  margin-bottom: -7px;
  border: none;
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
  padding-top: 70px;
}

.single-footer-widget {
  margin-bottom: 30px;
}

.single-footer-widget .logo {
  margin-bottom: 20px;
}

.single-footer-widget .footer-contact-info {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.single-footer-widget .footer-contact-info li {
  display: block;
  margin-bottom: 12px;
  color: var(--optionalColor);
}

.single-footer-widget .footer-contact-info li span {
  color: var(--blackColor);
  font-weight: 600;
}

.single-footer-widget .footer-contact-info li a {
  color: var(--optionalColor);
}

.single-footer-widget .footer-contact-info li a:hover {
  color: var(--mainColor);
}

.single-footer-widget .footer-contact-info li:last-child {
  margin-bottom: 0;
}

.single-footer-widget .social {
  padding-left: 0;
  list-style-type: none;
  margin-top: 12px;
  margin-bottom: 0;
}

.single-footer-widget .social li {
  display: inline-block;
  margin-right: 5px;
}

.single-footer-widget .social li a {
  font-size: 22px;
  line-height: 1;
}

.single-footer-widget .social li:last-child {
  margin-right: 0;
}

.single-footer-widget h3 {
  margin-bottom: 25px;
  font-size: 22px;
  position: relative;
  padding-bottom: 5px;
}

.single-footer-widget h3::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 80px;
  height: 1px;
  background-color: var(--mainColor);
}

.single-footer-widget .link-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.single-footer-widget .link-list li {
  margin-bottom: 12px;
}

.single-footer-widget .link-list li a {
  display: inline-block;
  color: var(--optionalColor);
  position: relative;
  padding-left: 13px;
}

.single-footer-widget .link-list li a::before {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--optionalColor);
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.single-footer-widget .link-list li a:hover {
  color: var(--mainColor);
  -webkit-transform: translateX(7px);
          transform: translateX(7px);
}

.single-footer-widget .link-list li a:hover::before {
  background-color: var(--mainColor);
}

.single-footer-widget .link-list li:last-child {
  margin-bottom: 0;
}

.single-footer-widget .newsletter-form .input-newsletter {
  display: block;
  width: 100%;
  border: 1px solid #eeeeee;
  background-color: transparent;
  color: var(--blackColor);
  height: 50px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  padding-left: 15px;
  border-radius: 5px;
  font-weight: 400;
}

.single-footer-widget .newsletter-form .input-newsletter::-webkit-input-placeholder {
  color: #999999;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-footer-widget .newsletter-form .input-newsletter:-ms-input-placeholder {
  color: #999999;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-footer-widget .newsletter-form .input-newsletter::-ms-input-placeholder {
  color: #999999;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-footer-widget .newsletter-form .input-newsletter::placeholder {
  color: #999999;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-footer-widget .newsletter-form .input-newsletter:focus {
  border-color: var(--mainColor);
}

.single-footer-widget .newsletter-form .input-newsletter:focus::-webkit-input-placeholder {
  color: transparent;
}

.single-footer-widget .newsletter-form .input-newsletter:focus:-ms-input-placeholder {
  color: transparent;
}

.single-footer-widget .newsletter-form .input-newsletter:focus::-ms-input-placeholder {
  color: transparent;
}

.single-footer-widget .newsletter-form .input-newsletter:focus::placeholder {
  color: transparent;
}

.single-footer-widget .newsletter-form .validation-danger {
  color: red;
  margin-top: 15px;
}

.single-footer-widget .newsletter-form .validation-success {
  margin-top: 15px;
}

.single-footer-widget .newsletter-form .default-btn {
  display: block;
  width: 100%;
  margin-top: 12px;
  padding-left: 25px;
  padding-right: 25px;
}

.footer-bottom-area {
  background-color: #f7f8fa;
  margin-top: 40px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.footer-bottom-area p {
  line-height: initial;
}

.footer-bottom-area p i {
  position: relative;
  top: 1px;
}

.footer-bottom-area p a {
  font-weight: 600;
  color: var(--mainColor);
}

.footer-bottom-area .payment-types {
  text-align: right;
}

.footer-bottom-area .payment-types ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.footer-bottom-area .payment-types ul li {
  margin-right: 10px;
  color: var(--optionalColor);
  font-weight: 500;
}

.footer-bottom-area .payment-types ul li:last-child {
  margin-right: 0;
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
  position: fixed;
  cursor: pointer;
  bottom: 20px;
  right: 20px;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  z-index: 4;
  width: 43px;
  text-align: center;
  height: 45px;
  opacity: 0;
  visibility: hidden;
  font-size: 27px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}

.go-top i {
  position: absolute;
  right: 0;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.go-top.active {
  opacity: 1;
  visibility: visible;
  bottom: 20px;
}

.go-top:hover {
  background-color: var(--blackColor);
  color: var(--whiteColor);
}

/*================================================
Modal CSS
=================================================*/
.productsQuickView .modal-dialog {
  max-width: 900px;
  margin: 0 auto;
}

.productsQuickView .modal-content {
  border: none;
  padding: 40px;
  border-radius: 0;
}

.productsQuickView .modal-content button.close {
  position: absolute;
  right: 0;
  top: 0;
  outline: 0;
  opacity: 1;
  color: var(--blackColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  line-height: 38px;
  padding: 0;
  margin: 0;
  font-size: 25px;
  width: 35px;
  text-shadow: unset;
  height: 35px;
}

.productsQuickView .modal-content button.close:hover {
  background-color: red;
  color: var(--whiteColor);
}

.productsQuickView .modal-content .products-content h3 {
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 600;
}

.productsQuickView .modal-content .products-content .price {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--mainColor);
  margin-top: 12px;
  margin-bottom: 15px;
  font-size: var(--fontSize);
  font-weight: 600;
}

.productsQuickView .modal-content .products-content .price .old-price {
  text-decoration: line-through;
  color: #999999;
  font-weight: 500;
}

.productsQuickView .modal-content .products-content .products-review .rating {
  display: inline-block;
  padding-right: 5px;
}

.productsQuickView .modal-content .products-content .products-review .rating i {
  color: #f49f0b;
  display: inline-block;
  margin-right: -2px;
}

.productsQuickView .modal-content .products-content .products-review .rating-count {
  display: inline-block;
  color: var(--blackColor);
  border-bottom: 1px solid var(--blackColor);
  line-height: initial;
  position: relative;
  top: -1px;
}

.productsQuickView .modal-content .products-content .products-review .rating-count:hover {
  color: var(--mainColor);
  border-color: var(--mainColor);
}

.productsQuickView .modal-content .products-content .products-info {
  list-style-type: none;
  padding-left: 0;
  margin-top: 20px;
  margin-bottom: 0;
}

.productsQuickView .modal-content .products-content .products-info li {
  color: var(--blackColor);
  margin-bottom: 8px;
}

.productsQuickView .modal-content .products-content .products-info li:last-child {
  margin-bottom: 0;
}

.productsQuickView .modal-content .products-content .products-info li span {
  color: var(--optionalColor);
}

.productsQuickView .modal-content .products-content .products-info li a {
  display: inline-block;
  font-weight: 600;
  color: var(--blackColor);
}

.productsQuickView .modal-content .products-content .products-info li a:hover {
  color: var(--blackColor);
}

.productsQuickView .modal-content .products-content .products-color-switch {
  margin-top: 8px;
}

.productsQuickView .modal-content .products-content .products-color-switch h4 {
  font-size: 16px;
  margin-bottom: 6px;
}

.productsQuickView .modal-content .products-content .products-color-switch ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: -4px;
  margin-left: -4px;
}

.productsQuickView .modal-content .products-content .products-color-switch ul li {
  display: inline-block;
  margin-left: 4px;
  margin-top: 4px;
}

.productsQuickView .modal-content .products-content .products-color-switch ul li a {
  display: inline-block;
  position: relative;
  border: 1px solid transparent;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.productsQuickView .modal-content .products-content .products-color-switch ul li a::before {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #eeeeee;
  border-radius: 50%;
  margin: 2px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.productsQuickView .modal-content .products-content .products-color-switch ul li a:hover, .productsQuickView .modal-content .products-content .products-color-switch ul li a:focus {
  border-color: var(--blackColor);
}

.productsQuickView .modal-content .products-content .products-color-switch ul li a.color-white::before {
  background: var(--whiteColor);
  border: 1px solid #eeeeee;
}

.productsQuickView .modal-content .products-content .products-color-switch ul li a.color-black::before {
  background: var(--blackColor);
}

.productsQuickView .modal-content .products-content .products-color-switch ul li a.color-red::before {
  background: red;
}

.productsQuickView .modal-content .products-content .products-color-switch ul li a.color-blue::before {
  background: blue;
}

.productsQuickView .modal-content .products-content .products-color-switch ul li a.color-green::before {
  background: green;
}

.productsQuickView .modal-content .products-content .products-color-switch ul li a.color-yellow::before {
  background: yellow;
}

.productsQuickView .modal-content .products-content .products-color-switch ul li a.color-yellowgreen::before {
  background: yellowgreen;
}

.productsQuickView .modal-content .products-content .products-color-switch ul li a.color-pink::before {
  background: pink;
}

.productsQuickView .modal-content .products-content .products-color-switch ul li a.color-violet::before {
  background: violet;
}

.productsQuickView .modal-content .products-content .products-color-switch ul li a.color-teal::before {
  background: teal;
}

.productsQuickView .modal-content .products-content .products-color-switch ul li a.color-plum::before {
  background: plum;
}

.productsQuickView .modal-content .products-content .products-color-switch ul li a.color-lime::before {
  background: lime;
}

.productsQuickView .modal-content .products-content .products-color-switch ul li a.color-blueviolet::before {
  background: blueviolet;
}

.productsQuickView .modal-content .products-content .products-color-switch ul li.active a {
  border-color: var(--blackColor);
}

.productsQuickView .modal-content .products-content .products-size-wrapper {
  margin-top: 8px;
  margin-bottom: 25px;
}

.productsQuickView .modal-content .products-content .products-size-wrapper h4 {
  font-size: 16px;
  margin-bottom: 8px;
}

.productsQuickView .modal-content .products-content .products-size-wrapper ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: -8px;
  margin-left: -8px;
}

.productsQuickView .modal-content .products-content .products-size-wrapper ul li {
  display: inline-block;
  margin-left: 8px;
  margin-top: 8px;
}

.productsQuickView .modal-content .products-content .products-size-wrapper ul li a {
  width: 35px;
  height: 30px;
  display: inline-block;
  line-height: 28px;
  border: 1px solid #eeeeee;
  color: #858585;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}

.productsQuickView .modal-content .products-content .products-size-wrapper ul li a:hover, .productsQuickView .modal-content .products-content .products-size-wrapper ul li a:focus {
  border-color: var(--blackColor);
  color: var(--blackColor);
}

.productsQuickView .modal-content .products-content .products-size-wrapper ul li.active a {
  border-color: var(--blackColor);
  color: var(--blackColor);
}

.productsQuickView .modal-content .products-content .products-add-to-cart .input-counter {
  max-width: 110px;
  min-width: 110px;
  margin-right: 10px;
  text-align: center;
  display: inline-block;
  position: relative;
}

.productsQuickView .modal-content .products-content .products-add-to-cart .input-counter span {
  position: absolute;
  top: 0;
  background-color: transparent;
  cursor: pointer;
  color: #d0d0d0;
  width: 40px;
  height: 100%;
  line-height: 50px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 18px;
}

.productsQuickView .modal-content .products-content .products-add-to-cart .input-counter span.minus-btn {
  left: 0;
}

.productsQuickView .modal-content .products-content .products-add-to-cart .input-counter span.plus-btn {
  right: 0;
}

.productsQuickView .modal-content .products-content .products-add-to-cart .input-counter span:hover {
  color: var(--mainColor);
}

/* .productsQuickView .modal-content .products-content .products-add-to-cart .input-counter input {
  height: 45px;
  color: var(--blackColor);
  outline: 0;
  display: block;
  border: none;
  background-color: #f8f8f8;
  text-align: center;
  width: 100%;
  font-size: 17px;
  font-weight: 600;
} */

.productsQuickView .modal-content .products-content .products-add-to-cart .input-counter input::-webkit-input-placeholder {
  color: var(--blackColor);
}

/* .productsQuickView .modal-content .products-content .products-add-to-cart .input-counter input:-ms-input-placeholder {
  color: var(--blackColor);
} */

/* 
.productsQuickView .modal-content .products-content .products-add-to-cart .input-counter input::placeholder {
  color: var(--blackColor);
} */

/* .productsQuickView .modal-content .products-content .products-add-to-cart .default-btn {
  top: -1px;
} */

.productsQuickView .modal-content .products-content .view-full-info {
  margin-top: 25px;
  line-height: initial;
  display: inline-block;
  position: relative;
  font-weight: 600;
  font-size: 15px;
}

.productsQuickView .modal-content .products-content .view-full-info::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: var(--blackColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.productsQuickView .modal-content .products-content .view-full-info:hover::before {
  width: 0;
}

.shoppingCartModal.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 320px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
          transform: translate3d(0%, 0, 0);
}

.shoppingCartModal.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.shoppingCartModal.modal.right.fade .modal-dialog {
  right: -420px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.shoppingCartModal.modal.right.fade.show .modal-dialog {
  right: 0;
}

.shoppingCartModal .modal-content {
  border-radius: 0;
  border: none;
}

.shoppingCartModal .modal-content .modal-body {
  padding: 25px 15px;
}

.shoppingCartModal .modal-content .modal-body h3 {
  font-size: 17px;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.shoppingCartModal .modal-content .modal-body .products-cart-content .products-cart {
  position: relative;
  border-bottom: 1px dashed #eeeeee;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.shoppingCartModal .modal-content .modal-body .products-cart-content .products-cart .products-image {
  margin-right: 15px;
  width: 55px;
}

.shoppingCartModal .modal-content .modal-body .products-cart-content .products-cart .products-image a {
  display: block;
}

.shoppingCartModal .modal-content .modal-body .products-cart-content .products-cart .products-content {
  position: relative;
}

.shoppingCartModal .modal-content .modal-body .products-cart-content .products-cart .products-content h3 {
  font-size: 15px;
  text-transform: initial;
  margin-bottom: 5px;
}

.shoppingCartModal .modal-content .modal-body .products-cart-content .products-cart .products-content h3 a {
  display: inline-block;
}

.shoppingCartModal .modal-content .modal-body .products-cart-content .products-cart .products-content span {
  font-size: 12px;
  display: block;
  margin-bottom: 3px;
  color: var(--optionalColor);
}

.shoppingCartModal .modal-content .modal-body .products-cart-content .products-cart .products-content .products-price span {
  display: inline-block;
  margin-bottom: 0;
  font-size: 13px;
  color: var(--optionalColor);
}

.shoppingCartModal .modal-content .modal-body .products-cart-content .products-cart .products-content .products-price span.price {
  font-size: 14px;
  color: var(--blackColor);
}

.shoppingCartModal .modal-content .modal-body .products-cart-content .products-cart .remove-btn {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: inline-block;
  color: var(--optionalColor);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.shoppingCartModal .modal-content .modal-body .products-cart-content .products-cart .remove-btn:hover {
  color: red;
}

.shoppingCartModal .modal-content .modal-body .products-cart-content .products-cart:hover .remove-btn {
  opacity: 1;
  visibility: visible;
}

.shoppingCartModal .modal-content .modal-body .products-cart-subtotal {
  overflow: hidden;
  margin-bottom: 15px;
  border-bottom: 1px dashed #eeeeee;
  padding-bottom: 15px;
}

.shoppingCartModal .modal-content .modal-body .products-cart-subtotal span {
  text-transform: uppercase;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
}

.shoppingCartModal .modal-content .modal-body .products-cart-subtotal span.subtotal {
  float: right;
}

.shoppingCartModal .modal-content .modal-body .products-cart-btn .default-btn {
  display: block;
  width: 100%;
  text-align: center;
  padding-left: 25px;
  padding-right: 25px;
}

.shoppingCartModal .close {
  color: var(--blackColor);
  font-size: 30px;
  outline: 0 !important;
  line-height: initial;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: absolute;
  right: 15px;
  top: 17px;
  z-index: 1;
}

.shoppingCartModal .close:not(:disabled):not(.disabled):hover, .shoppingCartModal .close:not(:disabled):not(.disabled):focus {
  color: red;
  text-decoration: none;
  opacity: 1;
}

.shoppingWishlistModal.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 320px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
          transform: translate3d(0%, 0, 0);
}

.shoppingWishlistModal.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.shoppingWishlistModal.modal.right.fade .modal-dialog {
  right: -420px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.shoppingWishlistModal.modal.right.fade.show .modal-dialog {
  right: 0;
}

.shoppingWishlistModal .modal-content {
  border-radius: 0;
  border: none;
}

.shoppingWishlistModal .modal-content .modal-body {
  padding: 25px 15px;
}

.shoppingWishlistModal .modal-content .modal-body h3 {
  font-size: 17px;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.shoppingWishlistModal .modal-content .modal-body .products-cart-content .products-cart {
  position: relative;
  border-bottom: 1px dashed #eeeeee;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.shoppingWishlistModal .modal-content .modal-body .products-cart-content .products-cart .products-image {
  margin-right: 15px;
  width: 55px;
}

.shoppingWishlistModal .modal-content .modal-body .products-cart-content .products-cart .products-image a {
  display: block;
}

.shoppingWishlistModal .modal-content .modal-body .products-cart-content .products-cart .products-content {
  position: relative;
}

.shoppingWishlistModal .modal-content .modal-body .products-cart-content .products-cart .products-content h3 {
  font-size: 15px;
  text-transform: initial;
  margin-bottom: 5px;
}

.shoppingWishlistModal .modal-content .modal-body .products-cart-content .products-cart .products-content h3 a {
  display: inline-block;
}

.shoppingWishlistModal .modal-content .modal-body .products-cart-content .products-cart .products-content span {
  font-size: 12px;
  display: block;
  margin-bottom: 3px;
  color: var(--optionalColor);
}

.shoppingWishlistModal .modal-content .modal-body .products-cart-content .products-cart .products-content .products-price span {
  display: inline-block;
  margin-bottom: 0;
  font-size: 13px;
  color: var(--optionalColor);
}

.shoppingWishlistModal .modal-content .modal-body .products-cart-content .products-cart .products-content .products-price span.price {
  font-size: 14px;
  color: var(--blackColor);
}

.shoppingWishlistModal .modal-content .modal-body .products-cart-content .products-cart .remove-btn {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: inline-block;
  color: var(--optionalColor);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.shoppingWishlistModal .modal-content .modal-body .products-cart-content .products-cart .remove-btn:hover {
  color: red;
}

.shoppingWishlistModal .modal-content .modal-body .products-cart-content .products-cart:hover .remove-btn {
  opacity: 1;
  visibility: visible;
}

.shoppingWishlistModal .modal-content .modal-body .products-cart-subtotal {
  overflow: hidden;
  margin-bottom: 15px;
  border-bottom: 1px dashed #eeeeee;
  padding-bottom: 15px;
}

.shoppingWishlistModal .modal-content .modal-body .products-cart-subtotal span {
  text-transform: uppercase;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
}

.shoppingWishlistModal .modal-content .modal-body .products-cart-subtotal span.subtotal {
  float: right;
}

.shoppingWishlistModal .modal-content .modal-body .products-cart-btn .default-btn {
  display: block;
  width: 100%;
  text-align: center;
  padding-left: 25px;
  padding-right: 25px;
}

.shoppingWishlistModal .close {
  color: var(--blackColor);
  font-size: 30px;
  outline: 0 !important;
  line-height: initial;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: absolute;
  right: 15px;
  top: 17px;
  z-index: 1;
}

.shoppingWishlistModal .close:not(:disabled):not(.disabled):hover, .shoppingWishlistModal .close:not(:disabled):not(.disabled):focus {
  color: red;
  text-decoration: none;
  opacity: 1;
}

.productsFilterModal.modal.left .modal-dialog {
  position: fixed;
  margin: auto;
  width: 400px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
          transform: translate3d(0%, 0, 0);
}

.productsFilterModal.modal.left.fade .modal-dialog {
  left: -320px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.productsFilterModal.modal.left.fade.show .modal-dialog {
  left: 0;
}

.productsFilterModal .modal-content {
  height: 100%;
  overflow-y: auto;
  border-radius: 0;
  border: none;
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 30px;
  padding-right: 30px;
}

.productsFilterModal .modal-content .modal-body {
  padding: 0;
}

.productsFilterModal .modal-content .modal-body .woocommerce-widget-area .filter-list-widget .selected-filters-wrap-list ul li a i {
  top: 0;
  padding-left: 0;
}

.productsFilterModal button.close {
  position: absolute;
  left: 30px;
  top: 20px;
  outline: 0;
  padding-left: 25px;
  text-shadow: unset;
  opacity: 1;
  line-height: initial;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: var(--fontSize);
}

.productsFilterModal button.close i {
  font-size: 24px;
  position: absolute;
  left: 0;
  top: -1px;
}

.productsFilterModal button.close:hover {
  color: red;
}

.productsFilterModal .woocommerce-widget-area {
  display: block !important;
}
.product-search{
  box-sizing: border-box;

padding: 1%;

min-height: 400px;

background: #FFFFFF;
border: 1px solid #DEDCDC;
}
.prod-size-onselect{
 display: inline;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 360;
  font-size: 14px;
text-align: center;
align-items: center;
justify-content: center;

padding:1%;
color:black;
align-content: center;
border: 1px solid #F0A639;
border-radius:10px;
}
.atrname{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 360;
  font-size: 14px;
text-align: center;
align-items: center;
justify-content: center;

padding:1%;
color:black;
align-content: center;

}
.prod-size{
  display: flex;
  color: #6F6F6F;
  width: 100px;
height: 32px;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
padding-left: 5%;
padding-top: 1%;
align-content: center;
justify-content: center;
}
.del-date{
  
box-sizing: border-box;
font-size: 12px;
height: 60px;
align-items: center;
align-content: center;
justify-content: center;

background: #FFFFFF;
border: 1px solid #DFDFDF;
}
.add-date{
    
box-sizing: border-box;

align-content: center;
justify-content: center;
width: 300px;
height: 80px;
align-items: center;
font-size: 12px;
background: #FFFFFF;
border: 1px solid #DFDFDF;
}
.products-info{
  list-style-type:none;
  align-items: center;
 
}
.devider{
 width:100%;
height: 0px;
border: 1px solid #F0F0F0;
}
.colorattr{
  border-radius: 50%;
  width: 18px;
  height: 18px;
  border: 1px solid #141414;
  
}
.old-price {
  text-decoration: line-through ;
  color: #828893;
  font-weight: normal;
  size:16px ! important;
}
.price {
  font-size: 22px ! important;
  font-weight: 700 ! important;
}
.book-doctor:hover{

  cursor: pointer;
  border-color: #01C9C9 !important;
  background: transparent linear-gradient(281deg, #c0d9e0 0%, #85cece 100%) 0% 0% no-repeat;
}
.book-doctor{

  cursor: pointer;
  color: #ffffff !important;
  font-weight:600;
  background: transparent linear-gradient(281deg, #007C9D 0%, #01C9C9 100%) 0% 0% no-repeat;
 
}
.search-webordering{
  height: 38px;
  margin-top: -1px;
  border: 1px solid rgb(148, 134, 134);
  border-radius: 40px;
  background-color: white;
}


