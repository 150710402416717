.form-content input[type="file"] {
}
.reg-form{
  background-color: white !important;
  /* min-width:350px; */
}
.camera-icon {
  text-align: center;
  margin: auto;
}
.fas fa-solid fa-camera cam-icon{
  font-size: 1.5em;
}
.pic-container .cam-icon {
  color: blue;
  font-size: 30px;
  margin: auto;
  margin-top: auto;
  color: #007bff;
  cursor: pointer;
}
#form-content-holder{
  align-items: flex-start !important;
}
.custom-file-label:after {
  content: "\f007" !important;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 600;
  padding: 0.475rem 0.75rem 0.375rem;
  color: #495057;
  background-color: transparent;
  border-left: 0;
  border-radius: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.custom-file-label::after {
  /* position: absolute; */
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}
.custom-file-label {
  z-index: 0 !important;
}

.gender-div,
.user-type {
  max-height: 40px!important;
  width: 100% !important;
  
  text-align: center;
  border: 0;
  outline: 0;
  border-radius: 6px;
  background-color: #fff;
  font-size: 15px;
  font-weight: 300;
  color: #8d8d8d;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-bottom: 14px;
}
.user-type {
padding: 7px 5px 7px 20px !important;
}
.gender_div{
  padding: 7px 0px 7px 0px !important;
}
.pswd {
  height: 44px;
  border:none !important;
  width: 95% !important;
}

.gender-div,
.user-type {
  border: 1px solid rgba(0, 149, 255, 0);
  background-color: #f7f7f7 !important;
  border:none !important;
  color: #000;
}

.gender-div label,
.gender-div span {
  margin-right: 10px;
}

.user-type label,
.user-type span {
  margin-right: 10px;
}
input:-internal-autofill-selected{
  background-color:#F7F7F7!important
}
.muted {
  width: 100%;
  padding: 9px 20px;
  text-align: left;
  border: 0;
  outline: 0;
  border-radius: 6px;
  background-color: #fff;
  font-size: 15px;
  font-weight: 300;
  color: #8d8d8d;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-bottom: 14px;
  border: 1px solid rgba(0, 149, 255, 0);
  background-color: #f7f7f7;
  color: #000;
}

.sendotp-btn:active,
.sendotp-btn:focus .sendotp-btn:hover {
  background-color: #f7f7f7 !important;
  color: #000;
  cursor: none;
}

.form-content .form-items {
  text-align: center !important;
}
.page-links a {
  margin-right: 10px !important;
  margin-left: 10px !important;
  font-size: 20px !important;
}


.avatar {
  vertical-align: middle;
  width: 120px;
  height: auto;
  min-height:100px;
  max-height:160px;
  border-radius: 2px;
  border: 6px solid white;
  background-color: #f0f0f0;

  display: flex;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
}
.close-img-btn {
  font-size: 22px;
  position: absolute;

  left: -10px;
  top: -8px;
  color: #007bff;
}
.close-img-btn:hover {
  color: grey;

  cursor: pointer;
}
.pic-container {
  width: 120px;
  height: 130px;

  background-color: var(--theme-lightest-grey);
  border: 1px transparent;

  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.2);
  align-self: flex-start;
}
.pic-container label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.pic-container label p {
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  margin-bottom: 5px !important;
  font-size: 16px;
  padding: 10px 4px;
}
.custom-file-input {
  display: none;
}
.pic-container .cam-icon {
  color: blue;
  font-size: 30px;
  margin: auto;
  margin-top: auto;
  color: #007bff;
  cursor: pointer;
}
.pic-container i:hover {
  color: grey;
}

.second-row {
  margin-top: 100px !important;
}
.camera-icon {
  text-align: center;
  margin: auto;
}
/* form.reg-form {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3) !important;
  border-radius: 8px;
  width: 90% !important;
  margin-left:80px;
  
} */
.divider {
  width: 90%;
  background-color: var(--theme-lightest-grey);
  border-radius: 10px;
  height: 2px;
  margin-bottom: 30px;
  margin-top: 30px;
}
.form-content .btn {
  border-radius: 6px;
  padding: 6px 28px;
  font-size: 14px;
  font-weight: 700;
  margin-right: 10px;
  border: 0;
  margin-bottom: 27px;
}
div.dob {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
form.reg-form {
  box-shadow: 0 5px 10px rgb(0 0 0 / 30%) !important;
  border-radius: 8px;
  width: 80% !important;
  margin-left: 10%;
  background-color: rgb(244, 225, 245);
}


.form-control{
  height: 44px!important;
}
.reg-form .reg-form-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color:#9ADFCC;
  border-radius: 8px;
  width : 100%

}

.page-links a{
  background-color: #f0f0f0!important;
  padding:6px 20px!important;
  border-radius: 40px !important;
  color:grey !important;
  font-weight: bold !important;
  font-size: 18px !important;
  
}

.page-links a:after{
  display: none!important;
}


.page-links a.active {
  background-color: var(--theme-icons-purple) !important;
  box-shadow: 4px 4px 10px rgba(0,0,0,0.3);
  color:white !important;

}

.usertype-icon{
  font-size: 30px;
  color: rgba(0,0,0,0.6);
  margin:10px;
}
.grey-title{
  color: var(--theme-dark) !important;
}
.page-links a, .page-links a:active{
  margin-top: 60px !important;
}
.sub-headings{
  width:90%;
  text-align: left;

  font-weight: bold;
  color: #666666 ;
}
.form-heading{

  padding-top:30px;
}
.hosp-form .form-heading{

  
}
.first-row{

   width: 100%;
    height: 160px;
    margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.form-content .custom-file{
    margin-bottom: 14px;
    width: auto !important;
    /* margin-right: 25px; */
}

.dob .react-date-picker {
    position: absolute;
    margin-left: 95px;
}
/*
.error-validation-msg{
  padding: 2px;
  
  border-radius:5px;
  box-shadow: 4px 4px 4px rgba(0,0,0,0.1);
  

}*/

.error-validation-msg{
    color: #ffa700;
    background-color: white;
    box-shadow: 4px 4px 4px rgba(0,0,0,0.1);
    padding: 4px;
   border-radius: 4px;
    z-index: 1000;
    width: 90%;
}
.google-play-text{
  height:70px !important;
  width:auto !important;
}
.felixacare-logo-color{
  color:var(--theme-icons-purple) !important;
}
.info-holder{
  border-bottom: 2px solid grey;
  border-top: 2px solid grey;
  padding-top:20px;
  padding-bottom:30px;
}
.bg-2{
  top:20px !important;
}

.holder-sticky{
  


position: fixed !important;
width:450px !important;
top:60vh !important;

}
.captions{
  font-size: 25px !important;
}
.captions span{
  font-size: 25px !important;
}
.item-left-row{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 80% !important;
}
.s-wrap{
  width:100%;
  
  overflow:hidden;
}
.s-move{
  display:flex;
  position:relative;
  top:0;
  right:0;
}
.slide{
  box-sizing: border-box;
  width:100%;
  flex-shrink:0;
 

}
@keyframes slideh{
  0% { right : 0; }
   20% { right : 0%; }
   30% { right : 0%; }
  40% { right : 100%; }
 55% { right : 100%; }
 70% { right : 100%; }

 90% { right : 100%; }
 100% { right : 0; }
}
.s-move{
  animation: slideh 15s infinite linear;
 animation-delay: 4s;
 
}
.s-move:hover{
  animation-play-state: paused
}

@media only screen and (min-width: 768px) {
  .reg-form .reg-form-div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  /* .form-control{
  height: 44px!important;
} */
  .gender-div {
    width: 80% !important;
  }
  /*
  .user-type,
  #qualification,
  #hospital {
    width: 100% !important;
  }  */
  #qualification,
  #hospital {
    width: 100% !important;
  }
  .reg-form .form-control,
  .country-selector,
  .region-selector,
  .phone-input,
  .custom-file, .hospital-div, .qualification-div{
    width: 45%;
  }
  .form-items {
    max-width: 100% !important;
  }
  .form-content {
    background-color: #f9f9f9 !important;
    /* margin-left: 50px; */
  }
  .form-content .item {
    width: 45% !important;
  }
  .form-content .react-date-picker__inputGroup__year {
    width: 40px !important;
  }
  .form-content .react-date-picker__inputGroup__month {
    width: 20px !important;
  }
  .form-content .react-date-picker__inputGroup__day {
    width: 20px !important;
  }

  .spacer {
    width: 100%;
    margin-top: 6px;
  }
}

.form-margin {
  margin-top: 20px;
}

.healthcare-width {
  width: 80% !important;
}

.privacy-modal{
  height: 60vh;
  overflow-y: auto;
}

@media only screen and (min-width: 769px) {
  .reg-form .reg-form-div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .gender-div {
    width: 45% !important;
  }

  .healthcare-width {
    width: 45% !important;
  }
 
 #qualification,
  #hospital {
    width: 100% !important;
  }
   .reg-form .form-control,
  .country-selector,
  .region-selector,
  .phone-input,
  .custom-file,.hospital-div, .qualification-div {
    width: 45%;
  }
  .form-items {
    /* max-width: 95% !important; */
    /* margin-left: 30px; */
  
  }
  .form-reg-items {
    width: 100%;
  }

  .form-content .item {
    width: 45% !important;
  }
  .form-content .react-date-picker__inputGroup__year {
    width: 40px !important;
  }
  .form-content .react-date-picker__inputGroup__month {
    width: 20px !important;
  }
  .form-content .react-date-picker__inputGroup__day {
    width: 20px !important;
  }
  .spacer {
    width: 100%;
    margin-top: 6px;
  }
}


.circular-button {
  position: absolute;
  top: 40%;
  left: 80%;
  color: red;
  background: white;
  padding: 6px;
  border-radius: 100px;
  border: 2px solid;
  cursor: pointer;
}
.disabled-input{

  background-color: #d8d8d8 !important ;
}

