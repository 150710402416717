.treat-button {
    
    flex-shrink: 0;
    
    background-color: white;
    color:black;
 
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    padding:2%;
    width: 90%;
    
    float: right;
    border-radius: 5px;
    border: 1px solid rgba(207, 207, 207, 1)
    
}
.treat-button-main {
    width:50%;
    flex-shrink: 0;
    
    background-color: white;
    color:black;

    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    padding:1%;
   
    border-radius: 5px;
    border: 1px solid rgba(207, 207, 207, 1)
    
}
.treat-paste-button{
    border-radius: 30px;
    background: #98B7DD;
    flex-shrink: 0;
    
    color: white;
   
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    width: 90%;
    height: 36px;
  
}
.treat-button-sec {
    margin: 2%;
    flex-shrink: 0;
    border-radius: 30px;

    background-color: #81B6B6;
    color: white;
  
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    width: 90%;
    height: 36px;
    float: right;

}
.treat-div{
    margin-top: 1%;
    
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    background: white;
   margin-bottom: 1%;
    color: black;
}
.treat-first-div{
    border-radius: 30px 10px 10px 30px;
    border-right: 5px solid white;

}
.treat-sec-div{
   
    border-radius: 10px 30px 30px 10px;
  padding-left: 1%;
    height: 225px;
    padding-top: 2%;

}
.nav-head{
   margin-left: 3%;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #CDCDCD;

    
}
.treat-nav-main{
    display: flex;
    border-radius: 10px;
border: 1px solid #BBB;
background: rgba(129, 182, 182, 1);
padding:1%
}
.treat-nav{
    display: flex;
    border-radius: 20px;
border: 1px solid #BBB;
background: #FFF;
padding:1%
}
.treat-nav-all{
    z-index:1;
    position: relative;
    border-radius: 20px;
border: 1px solid #BBB;
background: #FFF;

}
.treat-nav1{
    display: flex;
border: 1px solid #BBB;
background: #FFF;
padding:1%
}
.qty-div{
    border-radius: 5px;
    background: #FFF;
    color:black;
    text-align: center;
    margin:1%;
    padding: 1.5%;
    height:30px;
}
.qty-div:hover{
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1); 
}
.leftmost{
    border-right: 1px solid  #BBB;
}
.bottomline{
    border-bottom: 1px solid  #BBB;
}
.fa-window-close:hover{
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1);  
}
.fa-clone:hover{
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1);  
}
.fa-clipboard:hover{
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1);  
}
.treat-paste-button:hover{
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1);  
}
.food-display{
   margin: 1%; 
   
    border-radius: 10px;
border: 1px solid #BBB;
background:#56B5EB;
color:white;
text-transform: capitalize;
padding:1%;
font-size: medium;
}