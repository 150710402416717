@media only screen and (min-width: 1024px) {

.patient-record-holder{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    color:rgba(8, 33, 70,1 )!important;
    margin-top: -15px;
}
}
.sticky-nav{
width: 14%;
    padding-top: 0.5rem;
    margin-left:22px;
  
   border-right:4px solid #d0d0d0;
  
  

}
.right-section{
    width:65%;
    margin-bottom:60px;

 
    
}
.left-nav ul.stickynav-ul .checkBoxWebmenu li{
	   
    margin-bottom: 4px !important;
    padding: 6px !important;
    border-left: solid 0.28rem var(--theme-blue);
       background-color:  #0a9494ab;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    text-align: left;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor:pointer;
    /* margin-left:10px; */
    color: #fff !important;
    width:100%;
}
.left-nav ul.stickynav-ul li h6{
    z-index:100;
}
.left-nav ul.stickynav-ul li:hover, .left-nav ul.stickynav-ul li:active, .left-nav ul.stickynav-ul li:focus{
    background-color: var(--theme-blue);
    color:white;
}

.left-nav ul.stickynav-ul {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
}

.left-nav{
   margin-right: 1.71rem;
    font-size: 0.8rem;
    top:50px;
    position: sticky;
}

.right-section{
	padding-top: 1.1428571428571428rem;
    position: relative;
}

.right-section > *:first-of-type {
    margin-top: 0;

  }

  .rightnav{
    padding-top: 1.1428571428571428rem;
    position: relative;
    margin-top: 0;
  }
  
.each-section{
      background: white;
    box-shadow: 0 0 0.71rem #cccccc;
    padding: 10px;
    margin: 1.71rem 0;
    border-radius: 0.21rem;
    position: relative;
 }
 .history-boxes{
 	display: flex;
 	flex-direction: row;
 	flex-wrap: wrap;
 	justify-content: center;
    align-items: center;
 }

 .name{
	color: var(--theme-blue);
	font-weight: bold;
	text-transform: capitalize;
}

.id,.user-details{
    color:rgba(0,0,0,0.5);
    font-weight: bold;
    text-transform: capitalize;


}
.ht,.wt{
       text-transform: lowercase !important;
}
.sub-section-title{
    color:rgb(33, 15, 99);
    font-weight: bold;
    text-transform: capitalize;


}
.sub-title{
	text-transform: capitalize;
	font-weight: bold;
	color: #1f1714 !important;
    margin-bottom: 20px;
    font-size: 20px;
}

.text-area:focus{
    border:none;
    outline:none;
   border-radius:2px;
   border:2px solid var(--theme-blue);
   background-color: #fff;
    
}
.text-area{
    border:none;
    outline:none;
    resize:none;
    border-radius:2px;
    background-color: #f0f0f0;
    color: black;
    padding: 10px;
    width:90%;
    height:90%; 
}
.next-visit .react-date-picker__wrapper,.scan-row  .react-date-picker__wrapper {
  border-radius: 5px;
  background-color: white;
  border:1px solid var(--theme-blue);
}
.planner-titles{
    color: white;
   font-weight:bolder;
    margin-right: 10px;
}
.next-visit,.scan-row{
    margin:auto;
    display: flex;
    flex-direction: row;
    justify-content: center;

}
.custom-input{
    width:60% !important;
    margin-right: 10px;
}
.lab-test-planner, .scanning-planner,.next-visit-planner{
   
   
    margin:15px auto ;
}

.profile-picture{
    border:6px solid rgba(0,0,0,0.4);
    box-shadow: 4px 4px 4px rgba(0,0,0,0.1);
    margin-left: 20px;
}

#box-1,.box-1{
    background-color: rgba(255, 99, 132, 1);

}
#box-2,.box-2{
    background-color: rgba(54, 162, 235, 1);
    
}
#box-3,.box-3{
    background-color: rgba(255, 206, 86, 1);
    
}
#box-4,.box-4{
    background-color: var(--teal);
    
}

.zero-contentbox .visit-box{
    background-color: #e4dd9f;
}

.header-box1{
    background-color: var(--dark-teal) !important;
}

.patient-record-holder .each-list-title {
    padding: 10px !important;
   margin: 10px !important;
 width:150px;
 min-height: 65px;
 margin-top: 3% !important;
    text-transform: capitalize !important;
    cursor:pointer;
    
}

.patient-record-holder .each-list-title h5{
    text-transform: capitalize;
}
.btn-small{
    padding:5px 5px !important;
}
.info-section{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  
}


.schedule-title-holder{
    background-color: #f0f0f0;
    padding:10px;
    background-color: var(--theme-blue);
}
.info-left{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.each-list-title .box-titles h5 {
    color: white;
    font-weight: bolder;
}
.each-tile{
    box-shadow: 1px 1px 4px rgba(0,0,0,0.1) !important;
}

.scan-name-col, .test-name-col , .scan-date-col, .test-date-col{

    overflow-x:hidden;
}

.scan-text{
    border:none;
    outline:none;
    
    border-radius:2px;
    background-color: #ffffff;
    color: black;
    padding: 10px;
    width:90%;
    height:44px; 
}

.btn-round{
    border:2px solid #f0f0f0;
    padding:.375rem .75rem !important;
    border-radius: 50% !important;
}
.schedule-title-holder{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.summary-div{
      background-color: #f0f0f0;
      padding:20px;
      margin-bottom: 10px;
}

.modal-80w{
    width: 80vw !important;
    max-width :80vw !important;
}
.modal-medium{
    width: 40vw !important;
    max-width :40vw !important;
  
}


.history-link{
    cursor: pointer;
}

.modal-90w{
    width: 90vw !important;
    max-width :90vw !important;
}
.modal-preview{
    width: 70vw !important;
    max-width :70vw !important;
}
.modal-100w{
    width: 60vw !important;
    max-width :70vw !important;
    height:60vw !important;
}
.first-contentbox,.second-contentbox{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
   
   
  justify-content: flex-start;

}

.first-contentbox1{

    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
   height :420px;
   
  justify-content: flex-start; 

}

.zero-contentbox{
     display:flex;
    flex-direction: row;
    flex-wrap: wrap;
   justify-content: space-between;
    align-content: flex-stretch;
   background-color: #0a9494ab;
    color: rgb(17, 11, 107);
}
/*
.info{
    
}
*/

.first-contents {
    padding:20px;
    margin-right:10px;
}

/*
.info-section{
    flex-grow: 1.5;
}

*/

.imp-info-subsection{
    flex-grow: 1;
   
}
.sub-section1{
        background: white;
    box-shadow: 0 0 0.71rem #cccccc;
    width:450px;
    margin: .71rem ;
    border-radius: 0.21rem;
    position: relative;
    /* //max-width: 500px; */
    min-height:170px;
    /* min-width: 200px; */
    height:390px; 
}
.video-button{
    color:var(--teal);
    background-color:white;
     height:30px;
      width:121px;
      margin-left: 61%;
       margin-top:-10px;
        padding-top:5px;
        text-align: center;
        border-radius: 0.21rem;
}
.second-contentbox1{

    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100% !important;
    align-content: stretch;
    justify-content: center;
    margin-top: 40px;
}

/* .patient-record-holder .sub-section{
    background: white;
   box-shadow: 0 0 0.71rem #cccccc;
   
   margin: .71rem ;
   border-radius: 0.21rem;
   position: relative;
   /*max-width: 500px;*/
   /* min-height:170px;
   min-width: 200px;
   height:400px; */
/* }  */

.sub-section5{
    background: white;
    box-shadow: 0 0 0.71rem #cccccc;
    
    margin: .71rem ;
    border-radius: 0.21rem;
    position: relative;
    max-width: 630px;
     min-height:170px;
    min-width: 200px;
    height:480px;
}

.patient-record-holder .sub-section{
     background: white;
    box-shadow: 0 0 0.71rem #cccccc;
    
    margin: .71rem ;
    border-radius: 0.21rem;
    position: relative;
    /* max-width: 500px; */
     min-height:450px;
    min-width: 200px;
    
} 
.wound-section{
    background: white;
    box-shadow: 0 0 0.71rem #cccccc;
    
    margin: .71rem ;
    border-radius: 0.21rem;
    position: relative;
 padding:2%;
    min-width: 200px;
}
.patient-record-holder .sub-section.diagnostics{
    width: 45% !important;
    padding-right: 3%;

}
.personal-details-sub-section{
    padding: 1.14rem 2.28rem 0.57rem 2.28rem;
    height:400px;
    
}
.sub-section-img{
     margin-top: -1.4rem ;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;

}
.sub-section-header{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width:100%;
    background-color: grey;
    padding: 8px !important;
}
.sub-section-body{
    padding:5px;
    /* background-color: #F5F5F5; */
    /* box-shadow: 0 2px 2px #808080; */
}
.sub-section-footer{
     width:100%;
   
    padding: 8px !important;
}
.sub-section-header svg{
    margin-right: 10px;
}
.user1{
    background-color: #d0d0d0;
    border-radius: 0 25px 25px 0px;
    margin-right: 20px;
    margin-bottom: 10px;
    padding: 4px 10px;
    color:grey;
    margin-top: 10px;
}

.user2{
    background-color: #d0d0d0;
    border-radius: 25px 0px 0px 25px;
    margin-left: 20px;
    margin-bottom: 10px;
    padding:4px 10px;
    color:grey;
}

.btn-glucose{
    background-color: var(--red);
}
#vitals-tile{
padding: 10px !important;
    width: 90px !important;
    min-width: 80px;
    text-transform: capitalize !important;
}
#vitals-tile-small{
     padding: 10px !important;
    width: 90px !important;
    min-width: 80px;
    min-height: 70px;
    text-transform: capitalize !important;
}
.vitals-row{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.vitals-col{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}
.vitals-colv{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    padding-top: 1%;
  }

.second-contentbox{
    align-content: stretch;
}

.sub-section-dr-notes{
    min-width:350px !important;
}

.next-visit-new .react-date-picker__wrapper,.scan-row  .react-date-picker__wrapper {
  border-radius: 5px;
  background-color: white;
  border:1px solid var(--theme-blue);
}

.date-picker{
    align-self: center;
}

.scan-table-holder{
    width:60%;
}

.prime-header{
    font-weight: bold;
    text-transform: capitalize;
}
.felixa-header{
    color: #125654 !important;
}
.box-theme{
    background-color:#e4dd9f;
    font-weight: bold;
    color:rgb(16, 7, 58) !important;
}
.health-and-history{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}


.colored-box{
    transition: scale 0.5s ease-in-out;
    box-shadow: 4px 4px 10px rgba(0,0,0,0.2) !important;

   
}
.colored-box:hover{
     transform: scale(1.05);
     box-shadow: 4px 4px 10px rgba(0,0,0,0.2) !important;
 }
 .colored-box-small:hover{
     transform: scale(1.12);
     box-shadow: 4px 4px 10px rgba(0,0,0,0.2) !important;
 }
 .dr-notes-nav{
    color:white !important;
}

.box-dr-notes{
    transition: all .2s ease-in-out;
   margin-left: 10px;
   width:auto !important;
   padding:14px !important;
   box-shadow: 4px 4px 4px rgba(0,0,0,0.2);
   justify-content: flex-start !important;
}
.box-dr-notes:hover{
    transform: scale(1.1);
}

/*.schedule-title-holder,.schedule-planners .ReactTable{
    max-width: 700px;
}
.schedule-title-holder{
    min-width: 100px;
}
.schedule-planners{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
*/

.schedule-planners{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
}

.schedule-planners .next-visit-planner,.schedule-planners .scanning-planner,.schedule-planners .lab-test-planner{
    box-shadow: 4px 4px 4px rgba(0,0,0,0.3) !important ;
    align-self: center;
    width:80%;
}
.target-tab-content{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding:3px;
}
.personal-details-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px;
}

.personal-details-sub-section{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    height:400px;
    margin-top: -45px;
   
}
.text-left {
    text-align: left !important;
}
 .user-details {
    color: rgba(0,0,0,0.5);
    font-weight: bold;
    text-transform: capitalize;
}
.icon-color-prescription{
  color:var(--red);
}

.overlay-header{
        text-align: center;
    color: #666666 !important;
    text-transform: capitalize !important;
    font-weight: bolder;
    align-self: center;
}
.vitals-subsection,.primary-subsection,.inferences-subsection,.primarymed-subsection,.comorbidities-subsection{
    width:26% !important;
}
.presc-subsection{
    width:46%;
}

.nutr-subsection{
    width:33%;
}
.medhistory-subsection{
    width:53%;
}
.presc-box-body{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.schedule-box{
    width:auto !important;
}
.scan-react-table  .rt-thead .rt-th{
 background-color:#e4dd9f;
 color: rgba(6, 39, 86, 0.88);
 font-weight: 100;
}
.test-react-table  .rt-thead .rt-th{
 background-color:#e4dd9f;
 color:rgba(6, 39, 86, 0.88);
}
.scan-react-table{
 margin-top: -20px;
 width:100%;
}
.payment-react-table{
    margin-top: -10px;
    width:60%;
   }
.test-react-table{
 margin-top: -20px;
}

.dr-msgs-basic-sticky{

       display: none;
       bottom: 5vh;
    right: 0;
    bottom: 0 !important;
    position: fixed;
    z-index: 10000 !important;
    font-size: 30px;
    background-color: #eee;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 4px 4px 14px rgb(0 0 0 / 20%);
    
}

.date-instr{
  
    color:grey;
}

.med-tests-link{
    color:#fff;
    text-decoration: none;
}
.freq-box{
    margin-bottom: 4px;
}
.freq-link a{
    text-decoration: none;
}

.box-dr-notes{
    margin-bottom: 4px !important;
}

.comp-text{
    background-color: var(--flat-red);
    border-radius: 4px;
    padding:4px;
}

.nutrients-modal{
    margin:5px !important;
}

.flex-row-end{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.tool-icon{
    cursor: pointer;
}

.my-tooltip > .tooltip-inner {
    background-color: rgb(255, 255, 255);
    opacity: 1.0;
    filter: alpha(opacity=100);
    color: purple;
    }
    
    .my-tooltip > div.arrow::before {
        border-top-color: rgb(255, 255, 255) !important;
        color: rgb(255, 255, 255) !important;
      }

      .tap-tooltip > .tooltip-inner {
        background-color: rgb(255, 255, 255);
        opacity: 6.0;
        /* filter: alpha(opacity=100); */
        color: #005a66;
        width: 200px;
        }
        
        .tap-tooltip > div.arrow::before {
            border-top-color: rgb(255, 255, 255) !important;
            color: rgb(255, 255, 255) !important;
          }
    
.fa-info-circle{
    
    font-size: large;
    cursor: pointer;
    margin-left: auto;
}

.react-date-picker__inputGroup__divider{
    color:#000;
}

.panel-box{
    padding:10px 20px;
    cursor: pointer;

}
.zero-content-box{
    background-color:#171cbb !important;
    box-shadow: 4px 4px 4px rgba(0,0,0,0.2);
}
.image-edit{
    margin-right: auto;
    margin-left: auto;
    width: 50%;
    height: 50%;
}
.update-class{
    border-radius: 6px;
}
.cam{
    color: var(--flat-blue) !important;
    font-size: 20px;
    position: relative;
    top: 22px;
    left: 50px;
    cursor: pointer;
}
.cam:hover{
    color: #6495ED!important;
    box-shadow: #cccccc;
}
.close-prev{
    color: var(--flat-red) !important;
    font-size: 20px;
    position: relative;
    top: 22px;
    left: 50px;
    cursor: pointer;
}
.close-prev:hover{
    color: red !important;
    box-shadow: #666666;
}
.select-img{
    color: var(--theme-blue) !important;
    font-size: 35px;
    cursor: pointer;
}
.select-img:hover{
    color: var(--flat-blue) !important;
    text-shadow: 2px 2px 2px gainsboro;
}
.delete-btn-new{
	color:white;
	background-color: #dc3545;
	padding:3px 10px;
	border-radius: 50%;
	outline: none;
	border:none;

}

.delete-btn-new:hover, .delete-btn-new:focus{
	box-shadow: 0 5px 6px rgba(80, 182, 255, 0.31);
	background-color: red;
    outline:none;
}
.scan-close{
    text-align: right;
}
.scan-close-icon{
    font-size: 20px;
    cursor: pointer;
    margin-left: 4px !important;
    color: #dc3545;
}
.scan-close-icon:hover,.date-delete-icon:hover{
    color: lightcoral;
}

.second-contentbox{
    width:110% !important;
}

.inferences-subsection2{
    width:100% !important;

}
.date-delete-icon{
    font-size: 15px;
    cursor: pointer;
    
    color: red;
}
.scan-close-icon:hover,.date-delete-icon:hover{
    color: lightcoral;
}
.trash-btn{
    color:var(--flat-red);
    cursor:pointer;
    font-size:20px;
}
.trash-btn:hover{
    color:red;
    box-shadow: 4px 4px 10px rgba(0,0,0,0.1);

}
.box-vital{
    background-color: #6353be;
}
#patient-female{
    font-size: 20px;
    color: #d45c8c !important;
    text-transform: capitalize;
    text-align: center!important;
}
#patient-male{
    font-size: 20px;
    color: #4c3e6c !important;
    text-transform: capitalize;
    text-align: center!important;
}

.head-strip {
    background-color: #e4dd9f !important;
    padding: 5px;
    text-align: left;
    border-radius: 5px;

}
.grid-procedure {
    display: grid;
    grid-template-columns: 6fr 1fr;
}
.next-visit-box {
    background-color: #c2c0c094;
    padding: 20px;
    width: 95%;
    border-radius: 5px;
    box-shadow: 0 2px 2px #808080;
    align-items: center;
}
#diagnostic-sec{
    /* width: 50% !important;
    height: 50% !important; */
}
.allergy-body-content{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.loader{
    width:100px;
    height:100px;
    position:relative;
  
  }
  .loader::before,
  .loader::after{
    content:"";
    position: absolute;
    width:inherit;
    height: inherit;
    border-radius: 50%;
    mix-blend-mode: multiply;
    animation: rotate is infinite cubic-bezier(0.77,0,0.175,1);
  }
  .loader::before{
    background-color: #fc3f9e;
  }
  .loader::after{
    background-color: #50e8f3;
    animation-delay: .5s;
  }
  
  @keyframes rotate {
    0%,100%{
      left:95px;
    }
    25%{
      transform:scale(.3);
  
    }
    50%{
      left:0;
    }
    75%{
      transform: scale(1);
    }
  }

  .visitdata{
  
min-height: 300px;
border-radius: 0px 10px 0px 0px;
border: 1px solid #CDCDCD;
background: #FFF;
box-shadow: 0px 4px 4px 0p;

  }
  .visitbutton{

border-radius: 10px 10px 0px 0px;
margin-top: 5%;
flex-shrink: 0;
padding:1.3%;
background: #ffffff;
color:#000;
border: 1px solid rgba(172, 172, 172, 1)
  }
  .ipinput{
    width: 200px;
height: 45px;

flex-shrink: 0;
border-radius: 10px;
border: 1.124px solid #E4E4E4;
font-weight: 700;
color:black
  }
  .assignwindow{
    width: 500px;
height: 127px;
flex-shrink: 0;
border-radius: 30px;
border: 1px solid #CDCDCD;
background: #FFF;
margin-top: 5%;
margin-left: 7%;
  }
  .assignbutton{

    width: 159px;
height: 55px;
margin-top: 5%;
margin-left: 5%;
flex-shrink: 0;
border-radius: 10px;
background: #3C689E;
color:#fff
  }
  .floordisplayrooms{
   
height: 65px;
flex-shrink: 0;
border-radius: 10px;
border: 1px solid #FFF;

  }

  .displayrooms{
   
    height: auto;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid #C6C6C6;
    background: #FFF;
  }
  .availablebox{
    width:91%;
height: 55px;
flex-shrink: 0;
margin-left: 10%;
margin-top: 10px;
border-radius: 10px;
border: 1px solid #ABABAB;
background: #FFF;
  }

  .assignselect{
    /* width: 450px; */
   padding-right: 2%;
   padding-bottom:2%;
/* height: 965px; */
flex-shrink: 0;
/* margin-left: -20%; */
border-radius: 20px;
margin-top: 3%;
border: 1px solid #C6C6C6;
background: #FFF;
  }
  .patientbox{
    /* width: 420px; */
min-height: 129px;
padding:4%;
flex-shrink: 0;
padding-bottom: 4%;
margin-left: 15px;
border-radius: 10px;
margin-top: 20px;
border: 1px solid #E1E1E1;
background: #F9F9F9;
  }

.patientdisplay{
    /* width: 400px; */
    height: 60px;
    margin-left: 10px;
    flex-shrink: 0;
    margin-top: 20px;
    border-radius: 10px;
    border: 1.124px solid rgba(88, 182, 222, 0.15);
    background: #F0F9FD;
  }
 .patientdisplay-tosave{
     /* width: 400px; */
     height: 60px;
     margin-left: 10px;
     flex-shrink: 0;
     margin-top: 20px;
     border-radius: 10px;
     border: 2.124px solid rgba(255, 188, 2, 0.553);
     background: #F0F9FD;
 }
 .rightassign{

width: 183px;
height: 200px;
flex-shrink: 0;
margin-top: 20px;
border-radius: 30px;
margin-left: -13px;

background: #FFF;
 }
 @media (max-width: 768px) {
    .patient-record-holder {
      flex-direction: column; /* Stack elements vertically on smaller screens */
      align-items: center; /* Center align items vertically */
    }
  }
  .pro-table .rt-thead {

    background-color: rgba(227, 221, 166, 1);
    color: black !important;
}

.pro-table .rt-thead .rt-resizable-header-content {
    color: black !important;
}

.pro-table .rt-tbody {
    background-color: rgb(255, 255, 255) !important;
}