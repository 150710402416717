.services-btn-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: flex-start;
    gap: 10px;
}
.booking-detail{
    /* background-color: #F8F8F8; */
    /* box-shadow: 1px 5px 6px #bebebe; */
    box-shadow: 1px 2px 3px 2px #bebebe;
    border-radius: 5px;
    padding: 20px;
    margin: 10px;
}
.booking-details-grid{
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 10px;
}
@media only screen and (min-width: 600px) {
    .book-modal-width {
      max-width: 500px;
      
    
       /* New width for default modal */
    }
    .book-modal-width {
      max-width: 500px;
   
       }
  }
  .detail-icon{
    align-self: center;
    justify-self: center;
    font-size: 17px;
    color: #696969;
  }
  .slot-booking{
      display: flex;
      flex-direction: column;
      background-color: whitesmoke;
      padding: 5px;
      border-radius: 2px;
      margin: 5px;
      color: black;
  }
  .appointment-doctor-list{
      background-color: white;
      box-shadow: 0 5px 6px #bebebe;
      padding: 10px;
      width: 45%;
  }
  /* .appointment-doctor-list .list-item :hover{
    background-color: #bebebe;
  }  */
  /* .hover-service-listings :hover{
    background-color: #696969;
  }
  .hover-service-listings :focus{
    background-color: #696969;
  } */

  #csc-listing:focus{
    background-color: #FFEDD4;
  }

  .service-details{
    background-color: white;
    box-shadow: 0 5px 6px #bebebe;
    padding: 10px;
    width: 35%;
  }
  .service-priority{
      width: 70%;
      margin-left: auto;
  }
  .service-date{
    border: 1px solid #C0C0C0;
    border-radius: 5px;
    margin-left: auto;
    width: 50%;
  }
  .service-date:hover{
      border-color: rgb(24, 141, 236);
  }
  .service-phone-size{
      width: 70% !important;
      margin-left: auto;
  }
  .booking-tile{
      display: flex;
      flex-direction: column;
      background-color: white;
      /* border-radius: 5px; */
      margin: 10px;
      box-shadow: 0 4px 5px gray;
      width: 15%;
  }
  .booking-header{
    background-color: var(--theme-blue);
    color: white;
    
    /* border-bottom: 1px solid #808080;  */
    padding: 10px;
  }
  .booking-body{
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 5px;
  }
  .booking-body i{
      align-self: center;
  }
  .booking-body span{
    justify-self: flex-start;
}
  .booking-tile h5{
        font-weight: 600;
      margin-bottom: 0;   
  }
  .doc-select-bg{
    background-color: antiquewhite;
  }
  .calender-app-sidebar-section .inline-slot-details h2{
    font-weight: bold;
    color: #505050;
  }
  .calender-app-sidebar-section .flex-col{


  }
  .inline-slot-details{
    display: inline-flex;
    flex-direction: column;
    padding: 8px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 5px 5px darkgray;
    align-items: center;
  }
.cancel-booking{
  background-color: var(--flat-red);
  padding: 7px;
  align-self: flex-end;
  cursor: pointer;
  color: white;
  font-weight: bold;
  border-radius: 2px;
}
.cancel-booking:hover{
  background-color: rgb(255, 56, 99);
  box-shadow: 0 5px 5px darkgray;
}
