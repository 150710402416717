.forgot-password-form{
	padding:10px 30px 40px 30px !important;
	margin-top:auto !important;
	
	box-shadow: 4px 4px 14px rgba(0,0,0,0.2) !important;
	width:500px !important;

}

.forgot-password-page{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
}
.wrapper-content{
	height: 100%;
    min-height: 80vh;
    background-color: #ececec;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.btn-reset-pw{
	margin-left: 4px;
}
.form-button-forgot-pw{
	display:flex;
	flex-direction: row;
	justify-content: space-between;
	width:100%;
}
