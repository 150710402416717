.outer-home {
}
.outer-home img {
  margin: auto;
  margin-top: 60px;
}

.home-icon{
  vertical-align: middle;
  border-width: 5px;
  width: 120px;
  height: 120px;
  font-size: 70px !important;
  padding: 20px;
  border-style: solid;
  margin-bottom: 10px;
  transition: transform .8s;
  border-radius: 200px;
  cursor: pointer;
  color:#6edcd1 !important
}
.home-tiles{
  border-radius: 50px;
  /* cursor: pointer; */
  height: 200px;
  transition: transform .8s;
  /* width: 206px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 10px 10px;
  flex-basis: 18%;
  padding: 10px;
  /* box-shadow: 2px 2px 5px purple; */
}
.home-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px;
}

.each-title {
  transition: transform .8s;
  font-weight: 800;
  cursor: pointer;
  font-size: 18px !important;
}

.home-icon:hover {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1); 
  color:#2b69a3 !important;
  box-shadow: 1px 1px 10px;
}

.each-title:hover {
  color: #2b69a3;;
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1); 
}

.home-navbar {
  background-color:#2b69a3;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
  box-shadow: 3px 5px 10px rgba(0,0,0,0.2);
  height:65px;
  
  position: sticky;
  top:0;
  box-shadow: 5px 5px 5px rgba(0,0,0,0.2);
}

.divide-line{
  margin: 50px auto 30px auto!important;
  width:80% !important;
}

.seperator {
  width: 100%;
  background-color: #bcbcbc;
  border-radius: 10px;
  height: 2px;
  
  margin: 20px auto;
}

.screen-adj{
  padding-top: 12% !important;
}
.sub-section_followup {
  background: white;
box-shadow: 0 0 0.71rem #cccccc;
min-width:450px;
margin: .71rem ;
border-radius: 0.21rem;
position: relative;
/* //max-width: 500px; */
min-height:400px;
/* min-width: 200px; */
  
}
.follow_up_tile{
 margin-top: 2%;
 margin-left: 5.5%;
  border:2px solid #0812bd42;
  justify-content: flex-end;
  align-content: center;
  border-radius: 10px;
}
.quick-img{
padding-left: 10%;
background: #FFFFFF;
border: 1px solid #B9B7B7;
border-radius: 20px;
}
.pro-counter{
  box-sizing: border-box;
width: 50px;
height: 25px;
font-size: small;
background: #FFFFFF;
border: 1px solid #ABABAB;
align-content: center;
border-radius: 10px;

}
input[type=number]::-webkit-inner-spin-button {
  opacity: 1 !important;
}
