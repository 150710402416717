.banner{
   
    box-shadow: 0 5px 10px rgba(114, 174, 234, 0.3);
   
    background-color: white;
}
.banner1{
   
    box-shadow: 0 5px 10px rgba(119, 225, 113, 0.296);
    padding: 2%;
    min-height: 80vh;
    background-color: #ffffff;
}
.confinput{
  
    margin: 0px;
    border-color: #2828284d;
    width :200px;
    height:44px

}
.confinput2{
  
    margin: 0px;
    border-color:  #ffffff;
   
    height:30px;

}
.confinput1{
    background-color: rgba(227, 225, 225, 0.87);
    margin: 0px;
    border-color: #ffffff;
    width :200px;
    height:30px;
   
     pointer-events:none; 
}
.appForm1 {
    flex-direction:column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3) !important;
    border-radius: 0px;
    /* margin-right: 10px; */
    padding: 30px 30px;
    width: 100% !important;
    background-color: #e6e6f0;
      /* overflow: auto; */
    }
    
    