.custom-btn {
    font-size: 0.8rem;
    padding: 0.2rem 0.5rem;
  }

  .dp-btn {
    color: rgb(163, 163, 195);
    font-size: 16px;
    font-weight: bold;
    margin-right: 10px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .dp-btn.active {
    /* background-color: blue; */
   /* text-underline-offset:blue; */
   color:blue;
  }
