.ReactTable .rt-th,.ReactTable .rt-td:nth-child(2){

	/* z-index:900; */
}

.add-btn-react-table{
    position: absolute;
    margin-top:-150px;
 left: 40%;
    z-index:1000 !important;
}

.suggestions-box{
	max-height:250px;
	overflow-y:scroll !important;
	
	background-color: #f0f0f0 ;
	width:100%;
	z-index:3000;
}

.scan-item {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  height:auto;
  width:100%;
  /* overflow-y:scroll; */
  
}
.each-option-suggestion{
 
    background-color: #ffffff !important;
}

.scan-item p{
 
  
  color:var(--theme-blue) !important;
  font-weight:bold;
  border-radius: 10px;
  
  width:100%!important;
  height:auto;
}
.scan-item p:hover{
	cursor: pointer;
	background-color: #dedede;
}
.search-results-holder{
	display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  background-color:#F0f0f0;
}

.react-date-picker__calendar{
	z-index:1000 !important;

}

.react-calender{
  z-index:10000 !important;
}


input.date-custom-format {
    position: relative;
    height: 44px;
   
      display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

input.date-custom-format:before {
    position: absolute;
    top: 3px; left: 3px;

    content: attr(data-date);
    display: inline-block;
    color: black;
    padding: .375rem .75rem;
    overflow-x: hidden;
}



input.date-custom-format::-webkit-datetime-edit, input.date-custom-format::-webkit-inner-spin-button, input::-webkit-clear-button {
    display: none;

}

input.date-custom-format::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 3px;
    right: 0;
    color: var(--theme-blue);
    opacity: 1;
    cursor: pointer;
    padding: .375rem .75rem;
}