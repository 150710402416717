.addH5 svg {
	float: right;
    margin-top: 5px;
}
.addH5 svg.opened {
	transform: rotate(180deg);

}
.food-habits-header{
	height: 70px;
}
.span-date1{
	margin-left:35% ;
}
Accordion.Collapse{
	position: absolute;
  z-index: 1;
}
 .addH5{
	color: rgb(15, 1, 1) !important;
}

.chol-section .card {
   border : 0px;
}

.chol-section .card-header {
   border : 0px;
   padding-top: 12px;
   cursor: pointer;
   background-color: blue;
}

.svgHeader {
	margin-top: 5px;
    float: right;
}.chol-section .chol-table{

	min-width:100px;
}
