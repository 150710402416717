.report-display {
   
    padding: 15px;
   
    border-radius: 10px;
   
    /* margin-bottom: 5px; */
  
   
    /* box-shadow: inset 0px 4px 4px #dcd4ff89; */
}
       
.togglegroup{
    font-size: small !important;
}
  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #1269B9 !important;
    background:radial-gradient(#7807a5, transparent) !important;
    width:100%;
    font-size: 15px;
    font-weight: bold;
 
}

.reports-menu{
  
    /* margin-left: 40px;
    margin-right: 10px;
    border-radius: 5px;
    */
}

.exportDisplay{
    width:98px;
    float: right;
    background-color: #D9D9D9;
    margin-right: 5.3%;
    z-index: 10000;
    position: relative;
   margin-top: 0.5%;
    border-radius: 10px;
}

.title-of-reportpage {
    text-align: center;
    color: #4e4b4f !important;
    text-transform: capitalize !important;
    font-weight: bolder;
    margin-top: 20px;
    margin-bottom: 10px;
}
.custombtn input{

height:22px;
border:none;
background-color: #f8f9fa;
margin-top: -5px;
}

.btnexport{
   
width: 98px;
height: 39px;
 color: rgba(23, 116, 168, 0.8);
 border: none ;
    font-size:medium;
background: #D9D9D9;
border-radius: 10px;
}
.custombtn{
    height:35px;
    
}

/* Define styles for the buttons */
.custom-btn {
    height: 35px;
    background-color: #7807a5;
    color: white;
    border: none;
    padding: 0 15px;
    margin-right: 10px; /* Add some space between buttons */
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  /* Style for button on hover */
  .custom-btn:hover {
    background-color: grey;
    color: black;
  }
  

.buttonclass{
    background-color: #9328bd;
}
.custombtnreg{
   
    box-shadow:none;
}
.btn-col1 {
    background-color: #5c2572 !important;
    border-color: #173e81 !important;
    font-size: 17px !important;
}
.flex-column-report{
    background-color: rgba(0, 101, 124, 0.33);
    border-radius: 10%;
    margin-top: 20%;
}
.checkBoxWebReport {

	box-sizing: border-box;
	align-items: flex-start;
	padding-top:5%;
	margin-top: 74%;
	height: 160px;
	color: #757575;
	width:100%;
 background:#C3F0E0;
	border: 1px solid #CBCACA;
	box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	padding-bottom: 5%;
    margin-left: 2%;
}
.buttonfilter {
    color:#e4dd9f!important;
}
.buttonfilter1 a {
    color: blue!important;
}

.arrowButton{
    background-color:white;
    color:#1269B9;
    font-size: larger;
    border-radius: 25px;
    
}
/* adjusting DateRangePicker height */

    
.rt-tbody{
    overflow-x: hidden!important;
}

.download-dropdown {
    width: 50%;
    margin-left: 12rem;
  }
  
@media screen and (max-width: 768px) {
    .download-dropdown {
      width: 90%!important;
      margin-left: 2rem!important; 
    }
}