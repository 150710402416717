.report-display {
   
    padding: 15px;
   
    border-radius: 10px;
   
    /* margin-bottom: 5px; */
  
   
    /* box-shadow: inset 0px 4px 4px #dcd4ff89; */
}
       
.togglegroup{
    font-size: small !important;
}
  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color:whitesmoke !important;
    background:#1269B9 !important;
    border: 2px solid white;
    border-radius: 20px;
    width:100%;
    font-size: 15px;
    font-weight: bold;
   


 
}

.buttonfilter{
  color:whitesmoke !important;
  background:#1269B9;
  border: 2px solid white;
  border-radius: 20px;
  width:20%;
  font-size: 15px;
  font-weight: bold;
  padding:5px;

}
.buttonfilter1{
  color:blue!important;
  background:#e4dd9f !important;
  border: 2px solid white;
  border-radius: 20px;

  font-size: 15px;
  font-weight: bold;
  padding:5px
}



.reports-menu{
  
    /* margin-left: 40px;
    margin-right: 10px;
    border-radius: 5px;
    */
}
.exportDisplay{
    width:98px;
    float: right;
    background-color: #D9D9D9;
    margin-right: 5.3%;
    z-index: 10000;
    position: relative;
   margin-top: 0.5%;
    border-radius: 10px;
}
.title-of-reportpage {
    text-align: left;
    color: #4e4b4f !important;
    text-transform: capitalize !important;
    font-weight: bolder;
    margin-top: 30px;
    margin-bottom: 10px;
}
.custombtn input{

height:22px;
border:none;
background-color: #f8f9fa;
margin-top: -5px;
}

.btnexport{
   
width: 98px;
height: 39px;
 color: rgba(23, 116, 168, 0.8);
 border: none ;
    font-size:medium;
background: #D9D9D9;
border-radius: 10px;
}
.custombtn{
    height:35px;
    
}
.custombtnreg{
   
    box-shadow:none;
}
.btn-col1 {
    background-color: #5c2572 !important;
    border-color: #173e81 !important;
    font-size: 17px !important;
}
.flex-column-report{
    background-color: rgba(0, 101, 124, 0.33);
    border-radius: 10%;
    margin-top: 20%;
}
.rs-picker-menu {
  z-index: 2000;
}
.checkBoxWebReport {

	box-sizing: border-box;
	align-items: center;
	padding-top:5%;
	margin-top: 10%;
	height: 160px;
	color: #757575;
	
 background:#C3F0E0;
	border: 1px solid #CBCACA;
	box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	padding-bottom: 5%;
    margin-left: 2%;
}

.custom-button {
  font-family: inherit;
  font-size: 12px;
  background: linear-gradient(to bottom, #5bd9ec 0%, #97c3ff 100%);
  color: rgb(13, 0, 0);
  padding: 0.8em 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 10%;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.1s;
}

.custom-button:hover {
  transform: translateY(-1px);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
  padding: 0em;
  background: linear-gradient(to bottom, #5bd9ec 0%, #97c3ff 100%);
  cursor: pointer;
}

.custom-button:active {
  transform: scale(0.65);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.custom-button span {
  display: block;
  margin-left: 0.4em;
  transition: all 0.1s;
}

.custom-button:hover span {
  scale: 0;
  font-size: 0%;
  opacity: 0;
  transition: all 0.1s;
}

.custom-button svg {
  width: 18px;
  height: 18px;
  fill: white;
  transition: all 0.1s;
}

.custom-button .svg-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  transition: all 0.1s;
}

.custom-button:hover .svg-wrapper {
  background-color: rgba(43, 169, 228, 0.897);
  width: 54px;
  height: 54px;
}

.custom-button:hover svg {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  transform: rotate(45deg);
}

/* Add hover effect for year items */
.year-item {
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.year-item:hover {
  background-color: rgb(236, 181, 79);
  color:black;
  cursor:pointer;
}

  
  
  
