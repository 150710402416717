.deathreportbutton {
    width: 100%;
    height: 54px;
    color: white;
    border-radius: 10px;
    border: 1px solid #6D4482;
    margin-bottom: 1%;
    text-align: left;
}
.patientRecbutton1{
   
  border:1px solid rgba(129, 182, 182, 1);
   color: black;
   background-color: white;
   border-radius: 10px;
   margin-bottom: 1%;
   text-align: left;
   font-family: Poppins;
   font-size: 15px;
   font-weight: 700;
   line-height: 45px;
   letter-spacing: 0em;
   text-align: left;
   width:90%;
}
.patientRecbutton{
   
     border:1px solid rgba(129, 182, 182, 1);
      color: black;
      background-color: white;
      border-radius: 10px;
      margin-bottom: 1%;
      text-align: left;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 700;
      line-height: 45px;
      letter-spacing: 0em;
      text-align: left;
  }
  .patientRecbutton:hover {
    background-color:rgba(129, 182, 182, 1);
    color:white
  }
.leftdivdeathrepo {
    width: 100%;

    border-radius: 10px;
    border: 1px solid #C6C6C6;
    ;
    padding: 4%;
    background-color: #FFFFFF;
}

.searchboxdeathrepo {
    width: 100%;
    height: 34px;
    border-radius: 30px;
    border: 1px solid #BDBDBD;
    margin-bottom: 1%;
}

.deathreq_lists {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(198, 198, 198, 1);
    padding-top: 1%;
    padding-bottom: 1%;
    border-radius: 10px;
    margin-bottom: 2%;


}
.callaction_lists {
 
  border: 1px solid rgba(198, 198, 198, 1);
  padding: 1%;

  border-radius: 10px;
  margin-bottom: 2%;


}


.death-text-style-here {
    margin-top: 5%;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 11px;
    letter-spacing: 0em;
    color: rgba(109, 68, 130, 1);

}
.visitor-text-style-here {
    margin-top: 1%;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 11px;
    letter-spacing: 0em;
    color: rgba(109, 68, 130, 1);

}
.rightdivinpr{
    border:1px solid rgba(0, 0, 0, 0.25);
    box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.25);
    background: rgba(255, 255, 255, 1);
  text-align: center;
  
  }
  .new-rec-section{
    text-align: center;
      background: white;
    box-shadow: 0 0 0.71rem #cccccc;
    padding:2%;
  margin-top: 1%;
    border-radius: 10px;
    
    
 }
 .recdiv{
    text-align: center;
    color:white;
    text-transform:capitalize;
    padding-top: 1%;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
 }
 .text-style-here-patient-rec
{ 

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 15px;
color: #fefdfd;
}
.text-patient-rec
{ 

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 15px;
color: #100f0f;
text-align: left;
}
.pro_lists {
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15); */
  border: 1px solid rgba(198, 198, 198, 1);
  /* padding-top: 1%; */
  /* padding-bottom: 1%; */
  border-radius: 10px;
  margin-bottom: 0.5%;
padding:0.5%;
}