.cartcards{
    box-sizing: border-box;

/* position: absolute; */
width: 700px;
height: 259px;
margin-bottom:10px;

background: #FFFFFF;
border: 1px solid #C6C4C4;
border-radius: 10px;
}

.cartimages{

    
box-sizing: border-box;

/* position: absolute; */
width: 130px;
height: 130px;
left: 105px;
top: 233px;


border: 1px solid #BCBCBC;
border-radius: 10px;
}

.shipping{

    box-sizing: border-box;
width:352px;
/* position: absolute; */

padding-top: 1%;

font-size: 14px;
background: #FFFFFF;
border: 1px solid #DFDFDF;
border-radius: 10px;

}

.placeorder{
    box-sizing: border-box;

/* position: absolute; */
width: 350px;
height: 310px;


background: #FFFFFF;
border: 1px solid #C6C4C4;
border-radius: 10px;
}
.placeorderbutton{
    box-sizing: border-box;
font-size: 22px;
margin-left: -8%;
/* position: absolute; */
width: 345px;
height: 80px;

margin-top: 5%;
background: #F0A639;
border: 1px solid #C6C4C4;
border-radius: 10px;
color:#FFFFFF
}

.cartquantity{
    
box-sizing: border-box;


width: 60px;
height: 38px;
left: 105px;
top: 400px;

background: #FFFFFF;
border: 1px solid #ABABAB;
border-radius: 10px;
}

.cartquantittyadd{
    box-sizing: border-box;
text-align: center;

width: 60px;
height: 38px;
margin-left: 1%;

background: #FFFFFF;
border: 1px solid #ABABAB;
border-radius: 10px;
}

.cart-container {
    height: 500px; /* Set a fixed height for the container */
    width: 750px; /* Set the desired width */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Hide horizontal scrolling */
    -ms-overflow-style: none; /* Hide the scrollbar in Internet Explorer and Edge */
    scrollbar-width: none; /* Hide the scrollbar in Firefox */
  }

.addresspart{


width: 955px;

left: 0px;
top: 0px;

background: #FFFFFF;
border-radius: 20px;
}
.sideboxcart{
    box-sizing: border-box;


width: 191px;
height: 55px;


background: #6BCBB8;
border: 1px solid #DEDEDE
}

.sideboxcart1{
    box-sizing: border-box;

  
    width: 191px;
    height: 55px;
  
    
    background: #6BCBB8;
    border: 1px solid #DEDEDE;
}

.scrolbar{

   
width: 7px;
height: 927px;
margin-left: 18%;
margin-top: -12%;

background: #D9D9D9;
}


.leftpage{


width: 583px;
height: 570px;



}
.vector{
   
width: 637px;
height: 470px;

margin-top: calc(30% - 470px/2 + 37px);
background: rgba(240, 166, 57, 0.15);
}

.righttop{




width: 100px;
height: 100px;

margin-left: 25%;
margin-top: -20%;
border: 1px solid #F0A639;

border-radius: 20px;
}
.logintitle{

width: 193px;
height: 24px;
margin-left: 12%;
margin-top: 2%;
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 16.1556px;
line-height: 24px;
text-align: center;

color: #555555;
}

.emailname{

    box-sizing: border-box;


width: 350px;
height: 50.49px;



background: #EEEEEE;
border: 1px solid #6BCBB8;
border-radius: 8.07781px;
}

.passwordemail{
    box-sizing: border-box;


width: 350px;
height: 50.49px;


background: #EEEEEE;
border: 1px solid #6BCBB8;
border-radius: 8.07781px;
}

.forgot{

   
width: 126px;
height: 21px;


font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14.1362px;
line-height: 21px;
text-decoration-line: underline;

color: #1E2772;
}
.loginbutton{
  
width: 350.13px;
height: 50.49px;
margin-left:-15%;
margin-top: 2%;
background: #F0A639;
box-shadow: 0px 12.1167px 24.2334px rgba(1, 11, 253, 0.12);
border-radius: 8.07781px;
}

.line1{
   
width: 184.28px;
height: 0px;
margin-left: 15%;
margin-top: 5%;

border: 1.00973px solid #C2C2C2;
}
.registerpat{
    box-sizing: border-box;


    width: 350.13px;
    height: 50.49px;
    margin-left:-15%;
    margin-top: 2%;

border: 1.00973px solid #6BCBB8;
border-radius: 8.07781px;
}
.vertical-line {
   
    width: 5px;
    height: 100%;
    background-color: #C2C2C2;
   
 
  }

  .insideinput{


width: 50.49px;
height: 44px;


background: #F0A639;
border-radius: 8.07781px;
  }

  .otphead{

   
width: 175px;
height: 38px;
margin-left: 20%;
margin-top: 20%;
font-family: 'Titillium Web';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 38px;
/* identical to box height */


color: #182035;
  }

  .input_field_box{
    width:100%;
    height:auto;
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 10%;
  }

  .input_field_box input{
    border:none;
    max-width: 20%;
    height:60px;
    text-align: center;
    border-radius: 5px;
    background: #f0f0f0;
    font-size: 25px;
  }

  .input_field_box input::-webkit-inner-spin-button,
  .input_field_box input::-webkit-outer-spin-button{
    display:none;
  }
  .input_field_box input :focus{
outline:1.5ox solid #00b991;
outline-offset: 2px;
  }
  /* form button{
    margin-top: 25px;
    width: 92%;
    color:#525252;
    font-size: 16px;
    padding:10px 0;
    font-weight: 600;
    border: none;
    border-radius: 6px;
    pointer-events: none;
    cursor: pointer;
    background: #e9d585;
    transition: all 0.2s ease;
  } */
.rightcreate{
    width: 493px;
height: 493px;
box-shadow: 0 5px 10px rgb(30%, 0, 0);
}

.logoalign{
    width: 100px;
height: 100px;


border: 1px solid #F0A639;
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
border-radius: 20px;
}

.createaccountfont{
    width: 120px;
height: 24px;

margin-top: 5%;
font-family: 'Outfit';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;

color: #11234A;
}

.inputcontrolpat{

    width: 270px;
height: 41px;


background: #EEEEEE;
border: 1px solid #6BCBB8;
border-radius: 8.07781px;
}

.getstart{

    width: 200px;
height: 40.49px;
font-family: 'Poppins';
color:white;
background: #11234A;
box-shadow: 0px 12.1167px 24.2334px rgba(1, 11, 253, 0.12);
border-radius: 8.07781px;
}
.addAddress{
  border: 1px solid #6BCBB8;
  padding:2%;
  border-radius: 10px;
  width:60%
}

.def-button{
cursor: pointer;
}

@media (max-width: 768px) {
  .col-lg-8,
  .col-md-6 {
    width: 100%;
  }
  .col-lg-1,
  .col-md-1 {
    display: none;
  }
  .col-lg-3,
  .col-md-5 {
    width: 100%;
  }
}

.textfield{

width: 635px;
height: 46px;


font-family: 'Titillium Web';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 165%;
/* or 23px */

text-align: center;

color: rgba(0, 0, 0, 0.5);
}
.tabcart{
  height: 60px;
width: 90%;
margin-left: 5%;
top: 179px;
border-radius: 10px;
background-color: #FFFFFF;
border-color: #000000;
border: 1px solid #B5B2B2;
}
.doc-searchbtn{
  color:white;
  background: transparent linear-gradient(281deg, #007C9D 0%, #01C9C9 100%) 0% 0% no-repeat;
  width: 90px;
height: 28px;
border-radius: 10px;
align-self: center;
font-size: medium;
font-size: small;
}
.doc-searchbtn:hover {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1); 
 
  box-shadow: 1px 1px 10px;
}

.summarypage{

  box-sizing: border-box;


width: 450px;
height: auto;
margin-left: 5%;
margin-top: 2%;
margin-bottom: 10%;
background: #FFFFFF;
border: 1px solid #BCBABA;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;
}
.summaryfirst{

width: 428px;
height: 81px;
margin-top: 5%;

background: #FFFFFF;
}
.summaryinvoice{
  box-sizing: border-box;


width: 90px;
height: 35px;


background: #FFFFFF;
border: 1px solid #000000;
border-radius: 10px;
}
.summarytrack{

width: 114px;
height: 35px;


background: #099663;
border-radius: 10px;
}
.summarysubtotal{
 
width: 315px;
height: 139px;
margin-top: 10%;
margin-left: 10%;
background: #FFFFFF;

}

.summarysubtotal-item {
  display: flex;
  justify-content: space-between;
}
.summaryimage{
  
box-sizing: border-box;
margin-top: 5%;
width: 100px;
height: 89px;
margin-left: 10%;
background: #FFFFFF;
border: 1px solid #E8E8E8;
border-radius: 10px;
}
.line {
  border: none;
  height: 1px;
  background-color: #000;
  margin: 10px 0;
}
.summarybutton{
font-size: 20px;
width: 385px;
height: 67px;
margin-top: 10%;
margin-bottom: 5%;
background: #6BCBB8;
border-radius: 10px;
color: white;
}
