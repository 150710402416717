.radio-options{
	margin-right: 15px;
}
.captions-size{
	font-size: 20px !important;
}
.regform{
  margin-left: 350px;
  margin-right: 50px;
  padding: 30px;
 
}

/* .appAside {
    width: 30%;
    background-color: #5DCBBB;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3) !important;
    border-radius: 0px;
    padding: 50px 20px;
  } */
  .appAside1 {
    width: 30%;
    background-color: #e0e1f1;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3) !important;
    border-radius: 0px;
    padding: 50px 20px;
  }
  .headerdata{
    width : 50%;
    background-color:#4e3d7e;
  }
  
  
  .registartionstyle {
  flex-direction:column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3) !important;
  border-radius: 0px;
  padding: 30px 10px;
  width: 70% !important;
    background-color: #4e3d7e;
    overflow: auto;
    margin-left: 300px;
  }
  .formTitle {
    color: rgb(3, 84, 128);
    font-weight: 300;
    margin-bottom: 50px;
    font-size: 1.7em;
    justify-content: flex-start;
    margin-left: 15px;
  }