.payment-display{
    background-color: rgba(0, 101, 124, 0.33);
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    width: 95%;
    margin-bottom: 30px;
}
.payment-search-grid{
    display: grid;
    grid-template-columns:0.5fr 1fr 0.4fr ;
    align-items: center;
    gap: 20px;
}

.payment-options{
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px; */
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
}

.consult-payment-flex{
    display: flex;
    justify-content: flex-start;
    gap: 20px;
}

.option-display{
    background-color: #F5F5F5;
    border-radius: 5px;
    padding: 15px;
}
.consult-doctors{
    background-color: #fafafa;
    height: 40px !important;
}
.consult-grid{
    display: grid !important;
    grid-template-columns: 1.4fr 1fr !important;
    background-color: #FFFFFF;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 3px 3px #808080;
}
.consult-register-contain{
    background-color: #FFFFFF;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 3px 3px #808080;
}
.doc-detail-payment{
    background-color: #fafafa !important;
    height: 48px !important;
    overflow-x: auto;
}
.patient-detail-grid-view{
    display: grid;
    grid-template-columns: 1fr 3fr;
    padding: 10px;
    margin: 10px;
    /* text-align: left; */
    transition: all 0.30s ease;
    box-shadow: 1px 1px 5px rgb(0 0 0 / 30%) !important;
    background: white;
}

.patient-detail-grid-view:hover{
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.3) !important;
    cursor: pointer;
}

.hover-detail :focus{
    background-color: #C0C0C0;
    cursor: pointer;
}
.selected-patient{
    background-color: var(--theme-blue) !important;
}

.patient-detail-grid-view .each-list-link{
    align-self: center;
    text-align: left;
    color: #6c757d !important;
    font-weight: 600;
    text-transform: capitalize;   
}

.patient-detail-grid-view .details{
    display: grid;
    grid-template-columns: 1fr 1fr 1.5fr;
}

.patient-detail-grid-view .details span{
    background-color: #a4a777;
    border-radius: 20px;
    padding: 5px 10px;
    color: white;
    font-weight: bold;
    align-self: center !important;
    justify-self: center !important;
}

.patient-detail-grid-view .details p{
    background-color: var(--theme-blue);
    border-radius: 20px;
    padding: 5px 10px;
    color: white;
    font-weight: bold;
    align-self: center !important;
    justify-self: center !important;
}
.past-payment-name{

    color: var(--theme-blue);
    font-weight: bold;
}
.pad-payment{
    padding: 15px;
}
.payment-page{
    background-color: whitesmoke;
    box-shadow:0 5px 5px#696969;
    padding: 10px;
    border-radius: 8px;
}
.selected-display-payment{
    display: inline-flex;
    justify-content: center;
    background-color: var(--theme-blue);
    padding: 8px;
    font-weight: bold;
    border-radius: 8px;
    color: white;
}
.adjust-dimensions{
    width: 90%;
    height: 140px;
}
/* .fc-widget-header{
    background-color:blue;
} */
.detail-input-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}

@media only screen and (min-width: 1000px ) {
    .config-modal-width {
      max-width: 1000px;
      max-height:1000px;
    
       /* New width for default modal */
    }
    .config-modal-width {
      max-width: 1000px;
      max-height:1000px;
   
       }
  }
  .fc th {

    background-color: aqua;
  }
