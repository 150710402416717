.presc-table-holder{
	
	margin:20px 0;
}
.add-btn-presc-table{
	position: absolute;
    margin-top:-220px;
 left: 45%;
    z-index:1000 !important;
}

.select-frequency,.select-intake{
	
}
 .add-presc-page .ReactTable .rt-th, .ReactTable .rt-td {
 	overflow :visible!important;

 }
 .presc-table-holder .form-control{
 	overflow-x:hidden;
 	height:38px !important;
 }

 .presc-table-holder .rt-resizer{
 	bottom: auto;
 }
 .presc-table-holder .ReactTable .rt-td .form-control{
	
	background-color:#4181b1!important;
	color:#ececec !important;
}

.presc-table-holder .ReactTable .rt-td  .select-intake div, .presc-table-holder .ReactTable .rt-td  .select-frequency div {
	
	background-color: #4181b1!important;
	color:#ececec !important;
}

.presc-table-holder .ReactTable .rt-td:nth-child(10) .form-control{
	
	background-color: #ecedec!important;
	color: var(--flat-blue) !important;
}

.presc-table-holder .ReactTable .rt-thead{
	background-color: var(--flat-red) !important;
}

.presc-table-holder .ReactTable .rt-thead .rt-resizable-header-content{
	font-weight:bolder !important;
}
