.overlay-body{
	overflow-x:auto;
}
.outer-container{
   margin:20px;
  }

.dashboard-display{
	display:flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	
	align-items: flex-start;


}


.dashboard-display img{
	margin-bottom: 20px;
	border:2px solid grey;
	
    /*cursor: zoom-in;
	transition: transform ease-in-out 0.3s;*/
	z-index:200;
}

	
.dashboard-display img:hover{
	
	 /*transform: scale(1.2);*/
	 z-index:200;
	

}
.title-of-report{
	color:var(--theme-dark);
	font-weight: 800;
	margin-top:40px;
	margin-bottom: 50px;
}
.sub-titles{
	color:rgba(0,0,0,0.4);
	text-transform: uppercase;
	font-weight: 600;
	margin:10px;
}
.stop-btn{
	color:white;
	background-color: #dc3545;
	padding:5px 10px;
	border-radius: 5px;
	outline: none;
	border:none;

}

.stop-btn:hover, .stop-btn:focus{
	box-shadow: 0 5px 6px rgba(80, 182, 255, 0.31);
	background-color: red;
    outline:none;
}

.ReactTable .rt-thead .rt-resizable-header-content{
	font-size: medium;
	padding:10px;
	margin:0;
	color:rgb(246, 239, 239);
	font-weight: 400;
}

/* .ReactTable .rt-thead{

background-color: #007bff;
} */



.ReactTable .rt-thead.-filters input, .ReactTable .rt-thead.-filters select {
	background-color: #F2F3F4;



}
.ReactTable .rt-tbody .rt-td{
	font-size: small;
}
.ReactTable ,.dashboard-display img {
	box-shadow: 0 5px 10px rgba(0,0,0,0.3);
}

.submit-verified-btn{
	margin:60px ;
	border-radius: 6px;
    border: 0;
    padding: 6px 28px;
    
    font-size: 17px;
    font-weight: 700;
    text-decoration: none;
    cursor: pointer;
    margin-right: 10px;
    outline: none;
    transition: all 0.3s ease;
    


    
    background-color:#DA70D6;
	color:white;
    
    margin-bottom: 30px;

}
.submit-verified-btn:hover, .submit-verified-btn:focus, .submit-verified-btn:active{
	
	box-shadow: 0 0 0 rgba(80, 182, 255, 0.31);
}
/*
.date-picker-div{
  margin-top:60px;
  color:grey;
  
  box-shadow: 4px 4px 16px rgba(0,0,0,0.2);
  border-radius:2px;
  padding:20px;
}
.date-picker-div{
	color: #F0f0f0 !important;
  background-color: #4e4eff!important;
}

.react-date-picker__inputGroup__divider{
	color:black !important;
}

*/
.date-drawn-div {
 
  color: white !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: center;
}
.date-drawn-div #datedrawn, .date-drawn-div input, #verify-doctor{
  margin:5px;
  padding:10px;
}
.date-drawn-div input{
  width:120px;
}


.second-set{
	display: flex;
	flex-direction: column;
	flex-wrap:nowrap;
	justify-content: space-around;
	align-items: center;

	width:90vw;
}
.scanned-images-presc{
	display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}
.scanned-images-presc img{
	margin-right:15px;
}
.additional-btn{
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}


.add-row-btn{
  margin:20px;
}

.dr-field{
	width:auto!important;
}

.top{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 30px;
}
.date-drawn-div{
	margin-right:20px;
}

#confirm-reject{
	color:grey!important;
}

.table-header-tooltip{
	cursor: pointer;

}

.overlay-body{
	display: flex;
	flex-direction: column;
	justify-content: center;
	
}
.overlay-body button{
	align-self: center;
}

.current-table-holder .ReactTable .rt-td .form-control{
	overflow:auto !important;
	min-height: 44px;
	height:auto !important;
	/* background-color: #4181b1!important;
	color:#ececec !important; */
}




.current-table-holder .ReactTable .rt-thead{
	background-color: var(--flat-blue) !important;
	
}

.current-table-holder .ReactTable .rt-thead .rt-resizable-header-content{
	/* font-weight:bolder !important; */
}

.add-presc-page .ReactTable .rt-tbody{
	overflow:visible !important;
}

@media screen and (max-width:2500px) {
	.add-presc-container{
		max-width: 1200px;
		background-color: #ffffff;
	box-shadow:  0 1px 6px 0 rgb(0 0 0 / 5%);
	margin-right: auto;
    margin-left: auto;
	box-sizing: border-box;
	margin-bottom: 40px;
	margin-top: 40px;
	border-radius: 10px;
	}

	.select-add-presc{
		width: 400px;
	}
}

@media screen and (max-width:1600px) {
	.add-presc-container{
		max-width: 1000px;
		background-color: #ffffff;
	box-shadow:  0 1px 6px 0 rgb(0 0 0 / 5%);
	margin-right: auto;
    margin-left: auto;
	box-sizing: border-box;
	margin-bottom: 40px;
	margin-top: 40px;
	border-radius: 10px;
	}

	.select-add-presc{
		width: 400px;
	}
}

@media screen and (max-width:1168px) {
	.add-presc-container{
		max-width: 960px;
		background-color: #ffffff;
	box-shadow:  0 1px 6px 0 rgb(0 0 0 / 5%);
	margin-right: auto;
    margin-left: auto;
	box-sizing: border-box;
	margin-bottom: 40px;
	margin-top: 40px;
	}

	.select-add-presc{
		width: 400px;
	}
}

@media screen and (max-width:990px) {
	.add-presc-container{
		max-width: 720px;
		background-color: #ffffff;
	box-shadow:  0 1px 6px 0 rgb(0 0 0 / 5%);
	margin-right: auto;
    margin-left: auto;
	box-sizing: border-box;
	margin-bottom: 40px;
	margin-top: 40px;
	}

	.select-add-presc{
		width: 300px;
	}
}

@media screen and (max-width:752px) {
	.add-presc-container{
		max-width: 540px;
		background-color: #ffffff;
	box-shadow:  0 1px 6px 0 grey;
	margin-right: auto;
    margin-left: auto;
	box-sizing: border-box;
	margin-bottom: 40px;
	margin-top: 40px;
	}

	.select-add-presc{
		width: 200px;
	}
}

@media screen and (max-width:652px) {
	.add-presc-container{
		max-width: 450px;
		background-color: #ffffff;
	box-shadow:  0 1px 6px 0 grey;
	margin-right: auto;
    margin-left: auto;
	box-sizing: border-box;
	margin-bottom: 40px;
	margin-top: 40px;
	}

	.select-add-presc{
		width: 150px;
	}
}

.flex-med{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 30px;
}

.add-med-padding{
	padding: 50px;
}


.presc-input{
	outline: none;
	border: none;
	width: 100%;
	border-bottom: 2px solid gray;
}

.presc-input:focus{
	border-bottom: 2px solid red;
	box-shadow: 2px 1px 1px gainsboro;
}

.flex-add-med{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.food-time{
	opacity: 0;
  position: absolute;
  width: 0;

}
.before-food-time {
	border: 1px solid gray ;
	border-radius: 10px;
	padding: 5px 10px;
	cursor: pointer;
	margin-right: 50px;
}

.food-time:hover + .before-food-time{
	border: 2px solid red ;
}

.food-time:checked + .before-food-time{
	border: 2px solid green ;
	background-color: aliceblue;
}

.presc-validation-msg{
    color: #ffa700;
    background-color: white;
    box-shadow: 4px 4px 4px rgba(0,0,0,0.1);
    padding: 4px;
   border-radius: 4px;
    width: 100%;
}
.suggest-display{
	background-color: #F8F8F8;
	border-radius: 3px;
	padding: 10px;
	width: 60%;
	height: 200px;
	overflow: scroll;
	box-shadow: 2px 2px 2px #BEBEBE;
}
.suggest-item{
	padding: 10px;
	text-align: left;
	font-weight: 600;
}
.suggest-item:hover{
	background-color: #DCDCDC;
	cursor: pointer;
}
.add-presc-table .rt-thead .rt-th{
	background-color: var(--dark-teal)!important;
}
.presc-table-holder .ReactTable .rt-table .rt-thead {
	background-color: var(--dark-teal)!important;
}
.dur-select{
	width: 240px;
}
.presc-table-holder .ReactTable .rt-tr :nth-child(7){
	display: flex;
}
.dur-period{
	width: 120px;
}
