.leftsidewindow{
    width: 100%;
height: 274px;

border-radius: 10px;
border: 1px solid #C6C6C6;;

background-color: #FFFFFF;

}

.searchboxindside{
height: 34px;
margin-top: 15px;
border-radius: 30px;
border: 1px solid #BDBDBD;
font-size: 11px;

}
.approverequset{
height: 45px;
color:white;
border-radius: 10px;
border: 1px solid #6D4482;
background-color: #6D4482;
margin: 0px!important;
margin-top: 10px!important;
line-height: 15px !important;
}
.rejectedrequests{
height: 45px;
color:black;
border-radius: 10px;
border: 1px solid #6D4482;
background-color: #F8F8F8;
margin:0 !important;
margin-top: 10px!important;
line-height: 15px !important;

}
.patientlistview{
    
    
    height: 75px;
    
   margin-top: 10px;
    border-radius: 10px;
    border: 1px solid #C6C6C6;
    
}
.patientlistview {
display: "flex";
align-items: "center";
margin-bottom: "20px";
      }
