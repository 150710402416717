.wound_leftdiv {
    border: 1px solid rgba(198, 198, 198, 1);
    border-radius: 10px;
   padding: 4%;
  margin-top: 8%;
    

}
.maia-notification{
  display:none
}
.selected_wound{
    border:1px solid rgba(109, 68, 130, 1);
    display:flex;
    border-radius:10px;
    background-color:rgba(54, 90, 144, 1);
    padding:3%;
    cursor: default
}
.not_selected_wound{
    display:flex;
    border-radius:10px;
    padding:3%;
    cursor: default
}
.head-div{
    background-color: rgba(54, 90, 144, 1);
    color:white;
    text-align: center;
 
}
.sub-head-div{
    background-color: rgba(222, 222, 222, 1);
    padding-left:2%; 

    
}
.woundtest:first-letter  {
  text-transform: capitalize;
}

.wound-time{
    height:40px;
    border:1px solid #C3CAD1;
    background: rgba(255, 255, 255, 1);

width:100%
}
.svg-div{
    background-color:white;
    border-radius:12px;
    box-shadow: 0px 3.500000238418579px 5.500000476837158px -1px rgba(0, 0, 0, 0.1);
    padding:2%;
}
.wound-button {
    width: 130px;
    height: 40px;
    color: #FFFFFF;
    background: #1269B9;
    border-radius: 10px;
}
.woundheader{
    text-align: center;
color: white!important;
text-transform: capitalize !important;
font-weight: bolder;
margin-top: 10px;
background-color: rgba(54, 90, 144, 1);
}
.left-unit{
    border: 1px solid rgba(198, 198, 198, 1);
    border-radius: 10px;
    color:rgba(198, 198, 198, 1);
    margin-top: 2%;

}
.switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 25px;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }