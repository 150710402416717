.current-med-table{
	width:100%;
	align-items: left;
}
.current-med-table .rt-thead .rt-th{
	background-color: rgb(13, 197, 197)!important;
}

.current-med-table .rt-thead {
	width: 100%;
}




.ReactTable .rt-thead .rt-resizable-header:last-child {
    overflow: hidden !important;
}

.modal-90h{
	height: 300px !important;
    max-height: 90vw !important;
}

.modal-height{
	height: 200px !important;
}
.presc-btn{
	color:white;
	background-color: var(--dark-blue);
	padding:5px 10px;
	border-radius: 5px;
	outline: none;
	border:none;

}

.presc-btn:hover, .presc-btn:focus{
	box-shadow: 0 5px 6px rgba(80, 182, 255, 0.31);
	background-color: #3468c2;
    outline:none;
}
.prescImage-display{
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}
.prescImage-display button {
	position: relative;
	left: 370px;
	z-index: 1001;
	margin-left: 20px;
	margin-right: auto;
	margin-top: 20px;
  }

.prescImage-display img {

  transition: transform ease-in-out 0.3s;
}

.prescImage-display img:hover {

	transform: scale(1.2);
}
.date-select{
	margin-left: 9vw;
	color: #686868;
	font-weight: 600;
}

