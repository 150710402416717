.comorbidity-row,.comorbidity-add-row{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
}
.comorbidity-add-row{
	justify-content: center !important;
}
.comorb-item{
	    margin-right: 20px;
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 5px 10px;
    color: var(--theme-blue);
   
    margin-bottom: 4px;
    text-transform: capitalize;

}
.comorb-item h6{
	 font-weight: bold;
}

.comorbidity-add-row .form-control{
	width :150px;
	height:38px !important;
	border-radius: 4px 0px 0px 4px !important; 
}

.comorb-holder{
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
	overflow-y: auto;
	max-height: 250px;
	
}
.btn-add-comorbidity{
    border-radius: 0px 4px 4px 0px !important; 
}
.comorb-holder div{
	
}

.obs-div, .sym-div{
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
}

.add-observations-and-symptoms{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
}

.observations-add-section, .symptoms-add-section{
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
box-shadow: 4px 4px 14px rgba(0,0,0,0.2);
	width:45%;


}
.add-obs-sym-section{
	box-shadow: 4px 4px 14px rgba(0,0,0,0.3);
	padding:20px;
	margin:20px;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	
}
.add-obs-sym-section button.btn-submit-obs-sym{
	align-self: center;
}
.each-obs{
	padding:5px;
	margin:5px;
	box-shadow: 4px 4px 14px rgba(0,0,0,0.2);
	border-radius: 12px;
}

.obs-display{
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	
	align-items: center;
	overflow-x:auto;
	max-width:100%;
}
.allergy-table {
	width:100%;
}

/* .allergy-table .rt-thead .rt-th:nth-child(1){
	background-color: var(--flat-yellow);
}
.allergy-table .rt-thead .rt-th:nth-child(2){
	background-color: var(--flat-red);
} */
.allergy-table .rt-thead .rt-th{
	background-color: #e4dd9f;
}

/* .observation-table .rt-thead .rt-th:nth-child(1){
	background-color: var(--flat-yellow);
}
.observation-table .rt-thead .rt-th:nth-child(2){
	background-color: var(--flat-red);
}  */

.observation-table .rt-thead .rt-th{
	background-color:rgb(13, 197, 197);;
	color:black;
}
.allergy-table .rt-thead .rt-th{
	background-color:rgb(13, 197, 197);;
	color:black;
}

.observation-table{
	width:100%;
}
.observation-table.ReactTable .rt-td {
    overflow: auto!important;
    }
.obs-header{
   color:white;
}
.modal-body{
	margin:15px;
	overflow:auto;
	max-width: 100%;
}
.each-item-obs-sym{
	background-color: #f8f2f2;
	padding:8px;
	margin-bottom:4px;
	border-radius:4px;
	color:grey;
	overflow-x:auto;
}