.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20 !important;
}
.popup-inner {
  position: absolute;
  left: 25%;
  right: 15%;
  top: 25%;

  margin: auto;
  border-radius: 2px;
  background: white;
  z-index: 25 !important;
  height: auto;
}

.msg {
  color: grey !important;
  font-size: 10px;
  text-align: center !important;
  margin-top: 10px;
}

.msg-heading {
  color: rgba(0, 0, 0, 0.8) !important;
  margin-top: 40px;
  margin-bottom: 30px !important;
  text-align: center !important;
}

.popup-form {
  margin-left: 20px;
  margin-right: 20px;
  text-align: center !important;
}

.otp-input {
  width: 60% !important;
  margin: auto !important;
}

.close-btn {
  border-radius: 50% !important;
  padding: 12px 18px !important;
  margin-left: 99% !important;
}
.close-btn-div {
  margin-top: -10px !important;
  margin-right: -10px !important;
}

.click-resendotp {
  cursor: pointer;
  color: #0093ff !important;
  margin-top: 0px !important;
}

.click-resendotp:hover {
  text-decoration: underline;
}
.click-resendotp-div {
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.5);
}
