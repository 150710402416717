.favAdded {
  /* color: red; */
  fill: red;
}
.favNotAdded {
  fill: #ccccb3;
  
}

.card {
  width: 400px;
  height: 120px;
  padding: 5px;
  margin: 10px auto;
  border: 1px solid white;
  backdrop-filter: blur(6px);
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.5s;
  user-select: none;
  color: black;
}

.card:hover {
  border: 1px solid #404040;
  transform: scale(1.05);
  background: #fdfdfd;
}

.card:active {
  transform: scale(0.95) rotateZ(1.7deg);
  background: #fdfdfd;
}