.appFormShip{
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    /* box-shadow: 0 5px 10px rgb(0 0 0 / 30%) !important; */
    border-radius: 0px;
    /* margin-right: 10px; */
    padding: 30px 30px;
    width:95%;
 
    background-color: #baeef338;
 
}