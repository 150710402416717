#myChart-glucose{
	width: 100%;
	height: 100%;

}

.gogo-chart{
	
}

.gluco-table{
	width:auto !important;
	min-width:600px;
}
.food-habits-header{
	height: 70px;
}
.span-date1{
	margin-left:35% ;
}
Accordion.Collapse{
	position: absolute;
  z-index: 1;
}
 .addH5{
	color: rgb(14, 1, 1)!important
}

.food-habits-sections .card {
   border : 0px;
}

.food-habits-sections .card-header {
   border : 0px;
   padding-top: 12px;
   cursor: pointer;
   background-color: blue;
}

.svgHeader {
	margin-top: 5px;
    float: right;
}
.flex-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.profile-flex-col{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* .card-header button {
	width: 100%;
    background-color: inherit !important;
	border: 0;
    margin: 0;
}

.card-header button:focus {
	background-color: #9dd6e0 !important;
	border: 0;
	outline: 0;
    margin: 0;
	box-shadow: none;
} */

.addH5 svg {
	float: right;
    margin-top: 5px;
}
.addH5 svg.opened {
	transform: rotate(180deg);

}