.list-iteme{
    border-radius: 5px;
width:100%;
box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
padding: 15px;
margin-top:20px;
margin-bottom: -3px;
}
.list-item{
    border-radius: 5px;
width:100%;
box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
padding: 15px;

margin-bottom: 2px;
}
.list-item111{
    
   
width:100%;
border-bottom: 1px solid rgba(40, 40, 40, 0.302);
padding: 2px;

margin-bottom: 2px;
}
.title-of-page{
    margin: 30px;
    color: rgb(55, 52, 230);
    text-align: center;
  
}
.subtitle-of-page{
    margin: 30px;
    color: rgb(55, 52, 230);
    text-align: center;
}
.col{
    /* border: 1px solid black; */
    height: 50px;
    padding-top: 5px;
}.ser_header{
    background-color: var(--dark-teal)!important;
    color:aliceblue;
    border: 1px solid black;
}
.ser_body{
background-color: #ffffff5c;
border: 1px solid black;}

.checkbox1{
    width: 52px;
    height:32px;
    padding:4px;
    display: flex;
    align-items: center;
    background-color: #49454f;
    border-radius: 24px;
    border: solid 2px #938f99;
    transition: border-color 200ms, background-color 200ms;
    cursor: pointer;
}
.checkbox1::before{
    content:"";
    display:inline-block;
    width:16px;
    height:16px;
    border-radius: 12px;
    background-color: #938f99;
    transition: width 200ms,height 200ms, transform 200ms, background-color 200ms;

}
.checkbox.on{
    border-color:#D0BCFF;
    background-color:#D0BCFF ;
}
.checkbox.on::before{
    background-color: #381E72;
    width:24px;
    height:24px;
    transform:translate(18px)
}