.outer-home {
}
.outer-home img {
  margin: auto;
  margin-top: 40px;
}

.home-icon{
  vertical-align: middle;
  border-width: 5px;
  width: 120px;
  height: 120px;
  font-size: 70px !important;
  padding: 20px;
  border-style: solid;
  margin-bottom: 10px;
  transition: transform .8s;
  border-radius: 200px;
  cursor: pointer;
  color:#6edcd1 !important
}
.home-tiles{
  border-radius: 50px;
  /* cursor: pointer; */
  height: 200px;
  transition: transform .8s;
  /* width: 206px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 10px 10px;
  flex-basis: 18%;
  padding: 10px;
  /* box-shadow: 2px 2px 5px purple; */
}
.home-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px;
}

.each-title {
  transition: transform .8s;
  font-weight: 800;
  cursor: pointer;
  font-size: 18px !important;
}

.home-icon:hover {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1); 
  color:#2b69a3 !important;
  box-shadow: 1px 1px 10px;
}

.each-title:hover {
  color: #2b69a3;;
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1); 
}

.home-navbar {
  background-color:#2b69a3;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
  box-shadow: 3px 5px 10px rgba(0,0,0,0.2);
  height:65px;
  
  position: sticky;
  top:0;
  box-shadow: 5px 5px 5px rgba(0,0,0,0.2);
}

.roomsouter {

 
  height: 124px;
  flex-shrink: 0;
  border-radius: 20px;
  margin-top: 20px;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(176, 176, 176, 0.25);
}

.facilityouter{
 
height: 1200px;
background: var(--grey-lite, #FAFAFA);
}
.facilitybuttons{

display: inline-flex;
padding: 10px 20px;
flex-direction: column;
align-items: center;
gap: 10px;
border-radius: 6px;
background: #115269;
color:#FFF;
font-size: 12px;
font-family: Poppins;
font-weight: 600;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  background-color: rgba(129, 182, 182, 0.447);
}
.facilitytableouter{

  display: flex;

padding: 26px 30px 348px 29px;
flex-direction: column;
align-items: flex-start;

border-radius: 12px;
background: #FFF;
margin-top: 1%;

}
.buttonnew{
  width: 216px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #C7C7C7;
  background: #5F8D4E;
color: #FFF;
font-size: 12px;
font-family: Poppins;
font-weight: 600;

}
.buttonnew1 {

  width: 216px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #C7C7C7;
  background: #5F8D4E;
color: #1E1E1E;
font-size: 12px;
font-family: Poppins;
font-weight: 600;
  background: #FFF;

}

.toggle-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ccc;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-button.active {
  background-color: #00c853; /* Change to the desired color for the active state */
}


@media screen and (max-width: 768px) {
  .facilityouter {
    padding: 10px;
  }
  

}

.bed-button{
  width: 100px;
height: 37px;
margin-top: 5px;
margin-left: 5px;
border-radius: 10px;
border: 1px;

}
.dropBuilding:hover{
  color: #1E1E1E;
}