.footerclass {

   /* margin-bottom: -50px;
	padding-bottom:30px; */
	font-weight: bold;
	width: 100%;
	padding-top:30px;
	color:black; 
	background: #9ADFCC;
	
	/* text-align: center; */
	position:relative;
	/* margin-left:50px; */
}
	.align-content{

	align-items: flex-end;

	}
	
	
	
	h6.text-muted1 {
		color: #3909e4 !important;
		text-size-adjust:10px;
		
	}

	h6.text-muted {
		color: #00050a !important;
		
	}
	.flex-column-footer{
		display:flex;
		flex-direction:column;
		flex: 1;
		justify-content: flex-start;
		align-items: flex-start;
		color: black;
		
		
	}
	.footer-row{
		display:flex;
		flex-direction:row;
		/* justify-content: space-between !important; */
		padding-left:30px;
		padding-right:30px;
		font-weight: bold;
	}
	.footer-title{
	
		text-decoration: underline;
	}
	footer a{
		color:var(--theme-blue)!important;
	}
	footer a:hover{
	 color:black;
	}
	.mob-icon{
		margin-right:10px;
	}
	.mob2{
		
	margin-left:2px;
	}
	
	.social-media-icons{
		color:var(--theme-blue);
		font-size:32px;
		margin-right:15px;
		margin-left:15px;
	}
	.social-media-icons:hover{
		color:var(--theme-icons-grey);
	}
	.version-info{
		align-self: flex-end;
		justify-content: flex-end;
	}
	.flex-version-end{
		display:flex;
		justify-content: flex-end;
		margin-right:50px;
	
	}
	.divider-line{
		height:1px;
		background-color:grey;
		width:80vw;
		justify-content: center;
		margin:auto;
		margin-top:40px;
		margin-bottom:30px;
	}
	
	.divider-lineSection{
		height:1px;
		background-color:grey;
		width:10vw;
		justify-content: center;
	}
	
	.copy-info{
		margin-top:20px;
	}
	.logo-footer{
		height:32px;
	
	}
	.logo-footer-url{
		margin-bottom:20px;
	}
	.google-play{
		height:60px;
		width:auto;
		margin-left: 15px;
		margin-right:10px;
	}
	.app-store{
		display: inline-block;
		overflow: hidden; 
	
		width: auto; 
		height: 42px;
	}
	.app-store2{
	
		width: auto;
		height: 42px;
	}
	.footer-icons-row{
		/* align-items:center; */
	}
	.logo-footer-2{
		margin-left: 15px;
		margin-right:15px;
		
	}

	@media only screen and (max-width: 468px)  {
		.footer-row{
			display:flex;
			flex-direction:column;
			justify-content: space-between !important;
			padding-left:30px;
			padding-right:30px;
		
		}
	}