/*
Flaticon icon font: Flaticon
*/
@font-face {
	font-family: "Flaticon";
	src: url("../../assets/fonts/Flaticon.eot");
	src: url("../../assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
	url("../../assets/fonts/Flaticon.woff2") format("woff2"),
	url("../../assets/fonts/Flaticon.woff") format("woff"),
	url("../../assets/fonts/Flaticon.ttf") format("truetype"),
	url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
	font-weight: normal;
	font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: "Flaticon";
		src: url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
	}
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-heart:before { content: "\f100"; }
.flaticon-trolley:before { content: "\f101"; }
.flaticon-searh:before { content: "\f102"; }
.flaticon-next:before { content: "\f103"; }
.flaticon-left-chevron:before { content: "\f104"; }
.flaticon-view:before { content: "\f105"; }
.flaticon-free-shipping:before { content: "\f106"; }
.flaticon-headset:before { content: "\f107"; }
.flaticon-plane:before { content: "\f108"; }
.flaticon-secure-payment:before { content: "\f109"; }
.flaticon-return-box:before { content: "\f10a"; }
.flaticon-facebook-app-symbol:before { content: "\f10b"; }
.flaticon-instagram:before { content: "\f10c"; }
.flaticon-twitter:before { content: "\f10d"; }
.flaticon-linkedin:before { content: "\f10e"; }
.flaticon-pinterest:before { content: "\f10f"; }
.flaticon-copyright:before { content: "\f110"; }
.flaticon-left-quote:before { content: "\f111"; }
.flaticon-factory:before { content: "\f112"; }
.flaticon-supply-chain-management:before { content: "\f113"; }
.flaticon-fast-delivery:before { content: "\f114"; }
.flaticon-delete:before { content: "\f115"; }
.flaticon-placeholder:before { content: "\f116"; }
.flaticon-phone-ringing:before { content: "\f117"; }
.flaticon-email:before { content: "\f118"; }
.flaticon-clock:before { content: "\f119"; }
.flaticon-plus:before { content: "\f11a"; }
.flaticon-minus:before { content: "\f11b"; }
.flaticon-cancel:before { content: "\f11c"; }