

.react-tel-input .country-list {
  width: 500px !important;
  max-height: 300px !important;
}

.react-tel-input input {
  padding-left: 50px !important;
  width: 100% !important;
}

.react-tel-input {
  margin-bottom: 14px;
}

.phone-input {
  padding: 0px !important;
  margin-bottom: 14px;
  border: transparent !important;
}

.react-tel-input .form-control {
  padding-bottom: 20px !important;
  padding-top: 20px !important;
}
