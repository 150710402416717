.close-button{
	color: red;
    cursor: pointer;
}
.textbox-style{
    width: 100%;
}
.findings-body-content{
    display: flex;
    width:100%;
    gap: 5px;
}
.findings-add-button{
    color:#173e81;
}