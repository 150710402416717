.rt-thead {
    color: #0d2c49;
    font-size: 20px;
    background-color: var(--dark-teal);
}


.rt-tbody {
    background-color: #e4dd9f5c;

}

legend {
    font-size: 16px;

}

.ReactTable .rt-thead .rt-resizable-header-content {
    color: aliceblue;
}

.web {
    color:white;
    background-color:cornflowerblue;
    width: 85px;
height: 28px;
border-radius: 10px;
float:right;
margin-right: 1.5%;
font-size: medium;
}
.webpromo{
    color:white;
    background-color:cornflowerblue;
    width: 85px;
height: 28px;
border-radius: 10px;
align-self: center;
font-size: medium;
}
.webpromo:hover {
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1); 
    color:#2b69a3 !important;
    box-shadow: 1px 1px 10px;
  }
  .attrstyle{
    font-size: small;
  }