.critical-trend{
    color: red;
    font-weight: 600;
}
.high-trend{
    color: chocolate;
    font-weight: 600;
}
.medium-trend{
    color: cadetblue;
    font-weight: 600;
}
.low-trend{
    color: green;
    font-weight: 600;
}
.no-trend{
    color: grey;
    font-weight: 600;
}
.inference-table .select-wrap.-pageSizeOptions{
    display: none !important;
}
.inference-table .rt-thead .rt-th{
	background-color: #e4dd9f
}

/*
.inference-table .rt-thead .rt-th:nth-child(1){
	background-color: var(--flat-green);
}
.inference-table .rt-thead .rt-th:nth-child(2){
	background-color: var(--flat-red);
}
.inference-table .rt-thead .rt-th:nth-child(3){
	background-color: var(--flat-blue);
}
.inference-table .rt-thead .rt-th:nth-child(4){
	background-color: var(--flat-blue);
}
*/
.inference-table{
	width:100%;
}
.inference-table.ReactTable .rt-td {
    overflow: auto!important;
}

.btn.active{
    background-color: var(--theme-purple);
    color: #eee!important;
    box-shadow: 14px 10px 14px rgba(0,0,0,0.2);
    transform:scale(1.05) !important;
   
}.toggle-btns.active{ background-color:#4590b9;
    color: #eee!important;
    box-shadow: 14px 10px 14px rgba(0,0,0,0.2);
    transform:scale(1.05) !important;}

.toggle-btns{
   
    color:grey;
}

.toggle-btnsnew.active{ background-color:#F0A639 !important;
    color: #eee!important;
    box-shadow: 0px 0px 0px #6BCBB8;
    border-radius:8.08px !important;
    width:119px!important;
    height: 40px !important;
    margin-top: 1%;

    margin-left: 1% !important;
    margin-right: 1% !important;
   }

.toggle-btnsnew{
   
    color:#6BCBB8;
}

.toggle-btn-1{
     padding-right: 18px !important;
    border-radius:15rem  0 0 15rem!important;
   
}
.toggle-btn-2,.toggle-btn-3{
      padding-left: 18px !important;
      padding-right: 18px !important;
    border-radius:0 0 0 0 !important;
   
}
.toggle-btn-7{
    padding-left: 10px !important;
    padding-right: 10px !important;
    border-radius:8.08px !important;
    box-shadow: 0px 0px 0px #6BCBB8 !important;
}

.toggle-btn-4{
      padding-left: 18px !important;
    border-radius:0 15rem 15rem 0!important;
   
}
.toggle-btn-end{
    border-radius:0 15rem 15rem 0 !important;
}

.each-in-list{
    /*border-bottom: 2px solid #ececec; */
    padding:5px;
}
.each-in-list:nth-child(odd){
    background-color: #eee;
}
.medicine-badge{
    padding:5px 10px;
    background-color: #a4a777 !important;
    border-radius: 15px;
    color:#fff;
    width:100%;
    min-width: 100px;
    margin-bottom: 5px;
    text-align: center;
    box-shadow: 4px 4px 4px rgb(0 0 0 / 20%);
    margin:45px 5px;
}
.badge-holder{
    margin-bottom:15px;
}
.instr-badge{
    padding:5px 10px;
   
    border-radius: 15px;
    color:#fff;
    width:100%;
    min-width: 100px;
    margin-bottom: 5px;
    text-align: center;
    box-shadow: 4px 4px 4px rgb(0 0 0 / 20%);
    margin:45px 5px;
    color:grey;
}

.target-badge{
    background-color: #a4a777 !important;
    border-radius: 10px;
    padding: 5px 10px;
    box-shadow: 4px 4px 4px rgb(0 0 0 / 20%);
    color: white;
    width: 100%;
    text-align: center;
    margin:5px;
}
.inf-time-based-holder .sub-section{
    box-shadow: 4px 10px 14px rgba(0,0,0,0.1);
    width:600px;
    margin:10px;
}
.inference-text{
    
}
.inf-text{
    font-weight: normal !important;
}