.form-content input[type="file"] {
}
.profile-form{
  background-color: #F5F5F5 !important;
  min-width:350px;
}

#form-content-holder{
  align-items: flex-start !important;
}
.custom-file-label:after {
  content: "\f007" !important;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 600;
  padding: 0.475rem 0.75rem 0.375rem;
  color: #495057;
  background-color: transparent;
  border-left: 0;
  border-radius: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.custom-file-label::after {
  /* position: absolute; */
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}
.custom-file-label {
  z-index: 0 !important;
}

.gender-div,
.user-type {
  max-height: 40px!important;
  width: 100% !important;
  padding: 7px 5px 7px 20px !important;
  text-align: center;
  border: 0;
  outline: 0;
  border-radius: 6px;
  background-color: #fff;
  font-size: 15px;
  font-weight: 300;
  color: #8d8d8d;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-bottom: 14px;
}
.pswd {
}

.gender-div,
.user-type {
  border: 1px solid rgba(0, 149, 255, 0);
  background-color: #f7f7f7 !important;
  border:none !important;
  color: #000;
}

.gender-div label,
.gender-div span {
  margin-right: 10px;
}

.user-type label,
.user-type span {
  margin-right: 10px;
}

.muted {
  width: 100%;
  padding: 9px 20px;
  text-align: left;
  border: 0;
  outline: 0;
  border-radius: 6px;
  background-color: #fff;
  font-size: 15px;
  font-weight: 300;
  color: #8d8d8d;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-bottom: 14px;
  border: 1px solid rgba(0, 149, 255, 0);
  background-color: #f7f7f7;
  color: #000;
}

.sendotp-btn:active,
.sendotp-btn:focus .sendotp-btn:hover {
  background-color: #f7f7f7 !important;
  color: #000;
  cursor: none;
}

.form-content .form-items {
  text-align: center !important;
}
.page-links a {
  margin-right: 10px !important;
  margin-left: 10px !important;
  font-size: 20px !important;
}


.avatar {
  
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 6px solid white;
  background-color: #f0f0f0;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
}
.close-img-btn {
  font-size: x-large;
  position: relative;
  top: 27px;
  left: 125px;
  color: #007bff;
}
.close-img-btn:hover {
  color: grey;

  cursor: pointer;
}
.pic-container {
  width: 120px;
  height: 130px;

  background-color: var(--theme-lightest-grey);
  border: 1px transparent;

  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.2);
  align-self: flex-start;
}
.pic-container label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.pic-container label p {
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  margin-bottom: 5px !important;
  font-size: 16px;
  padding: 10px 4px;
}
.custom-file-input {
  display: none;
}
.pic-container .cam-icon {
  color: blue;
  font-size: 30px;
  margin: auto;
  margin-top: auto;
  color: #007bff;
  cursor: pointer;
}
.pic-container i:hover {
  color: grey;
}

.second-row {
  margin-top: -10px !important;
}
.camera-icon {
  text-align: center;
  margin: auto;
}
form.profile-form {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3) !important;
  border-radius: 8px;
  padding: 30px;
  width: 100% !important;
}
.divider {
  width: 90%;
  background-color: var(--theme-lightest-grey);
  border-radius: 10px;
  height: 2px;
  margin-bottom: 30px;
  margin-top: 30px;
}
div.dob {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}


.form-control{
  height: 44px!important;
}


.page-links a{
  background-color: #f0f0f0!important;
  padding:6px 20px!important;
  border-radius: 40px !important;
  color:grey !important;
  font-weight: bold !important;
  font-size: 18px !important;
  
}

.page-links a:after{
  display: none!important;
}


.page-links a.active {
  background-color: var(--theme-icons-purple) !important;
  box-shadow: 4px 4px 10px rgba(0,0,0,0.3);
  color:white !important;

}

.usertype-icon{
  font-size: 30px;
  color: rgba(0,0,0,0.6);
  margin:10px;
}
.grey-title{
  color: var(--theme-dark) !important;
}
.page-links a, .page-links a:active{
  margin-top: 60px !important;
}
.sub-headings{
  width:90%;
  text-align: left;

  font-weight: bold;
  color: #666666 ;
}
.form-heading{

  padding-top:30px;
}
.hosp-form .form-heading{

  
}
.first-row{
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.form-content .custom-file{
    margin-bottom: 10px;
    width: auto !important;
    margin-right: 25px;
}

.dob .react-date-picker {
    position: absolute;
    margin-left: 95px;
}
/*
.error-validation-msg{
  padding: 2px;
  
  border-radius:5px;
  box-shadow: 4px 4px 4px rgba(0,0,0,0.1);
  

}*/

.error-validation-msg{
      color: #ffa700;
    background-color: white;
    box-shadow: 4px 4px 4px rgba(0,0,0,0.1);
    padding: 4px;
   border-radius: 4px;
    z-index: 1000;
    width: 90%;
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .profile-form .profile-form-div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .form-control{
  height: 44px!important;
}
  .gender-div {
    width: 45% !important;
  }

  /*
  .user-type,
  #qualification,
  #hospital {
    width: 100% !important;
  }  */
  #qualification,
  #hospital {
    width: 100% !important;
  }
  .profile-form .form-control,
  .country-selector,
  .region-selector,
  .phone-input,
  .custom-file, .hospital-div, .qualification-div{
    width: 45%;
  }
  .form-items {
    /* max-width: 900px !important; */
  }

  .form-content .item {
    width: 45% !important;
  }
  .form-content .react-date-picker__inputGroup__year {
    width: 40px !important;
  }
  .form-content .react-date-picker__inputGroup__month {
    width: 20px !important;
  }
  .form-content .react-date-picker__inputGroup__day {
    width: 20px !important;
  }

  .spacer {
    width: 100%;
    margin-top: 6px;
  }
}
@media only screen and (min-width: 1200px) {
  .profile-form .profile-form-div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .gender-div {
    width: 45% !important;
  }
  /*
  .user-type,
  #qualification,
  #hospital {
    width: 100% !important;
  }  */
 
 #qualification,
  #hospital {
    width: 100% !important;
  }
   .profile-form .form-control,
  .country-selector,
  .region-selector,
  .phone-input,
  .custom-file,.hospital-div, .qualification-div {
    width: 45%;
  }
  .form-items {
    max-width: 900px !important;
  }

  .form-content .items {
    width: 45% !important;
    background-color: #E8E8E8;
  }
  .form-content .react-date-picker__inputGroup__year {
    width: 40px !important;
  }
  .form-content .react-date-picker__inputGroup__month {
    width: 20px !important;
  }
  .form-content .react-date-picker__inputGroup__day {
    width: 20px !important;
  }
  .spacer {
    width: 100%;
    margin-top: 6px;
  }


  @media only screen and (min-width: 600px) {
    .modal-dialog1 {
      max-width: 900px;
      
    
       /* New width for default modal */
    }
    .modal-body1 {
      max-width: 900px;
   
       }
  }

  .row-eg{
    width: 100%;
    height: 60px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
.fas fa-camera cam-icon{
  font-size: 1.5em;
}

.profile-holder{
  box-shadow:4px 4px 14px rgba(0,0,0,0.2);
   padding:15px;
}
.profile-picture{
  border-radius: 50%;
    border:4px solid gray;
    box-shadow: 5px 4px 4px rgba(0,0,0,0.1);
    width: 150px;
    height: 150px;
    margin-top: 30px;
    margin-left: 30px;
}
#cam-id{
  font-size: x-large;
  position: relative;
  bottom: 45px;
  right: 25px;
}
.flex-detail{
  display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    color:grey;
    margin-top:20px;
    width: 800px;
}
.flex-detail1{
  display: flex;
    flex-direction: column;
    
}
.details-holder{
  background: white;
    box-shadow: 0 0 0.71rem #cccccc;
    padding: 10px;
    margin: 1.71rem 0;
    border-radius: 0.21rem;
    background-color: #cccccc;
    margin-top: 80px;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    hyphens: auto;
    align-items:flex-start !important;
    margin:auto;
    margin-top:10px;
}

.bold-label{
  font-weight: bold;
  color: var(--theme-dark-blue);
  font-size: large;
}
.no-image{
  margin-top: 40px;
}
.icon-pos{
  position: relative;
  top: 10px;
  right: 10px;
}
.label-width{
  max-width: 800px;
}

.pac-container {
  z-index:99999;
}