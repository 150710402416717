.add-pharm-form-holder{
		margin-bottom:100px;

}
.pharm-btn{
  padding-top: 10px;
}

.add-pharm-form{
	
	box-shadow: 2px 3px 10px rgba(0,0,0,0.2);
	padding:20px;
	border-radius: 5px;
	width:60%;
	margin:auto;
	margin-top:40px;
	margin-bottom:10px;


}
.add-pharm-form .form-control{
	width: 100% !important;
}
.form-div{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
}

.add-pharm-div{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: 30px;
}

.add-pharm-div .form-group{
	text-align: left;
	margin-bottom:20px !important;
}


/* from ioform-style.css */
.add-pharm-form div.country-selector-pharm select,
.add-pharm-form  div.region-selector-pharm select ,.add-pharm-page .dob-div{
  width: 100%;
  padding: 9px 20px;
  text-align: left;
  border: 0;
  outline: 0;
  border-radius: 6px;
 /* background-color: #fff;*/
  font-size: 15px;
  font-weight: 300;
  /* color: #8d8d8d; */
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-bottom: 14px;
  border: 1px solid #ced4da !important;
  height: 44px;
}

  div.country-selector-pharm select:hover,
div.region-selector-pharm select:hover {
  border: 0;
  /*background-color: #ebeff8;*/
  /* color: #8d8d8d; */
}

/*from iofprm-theme4.css */
 div.country-selector-pharm select,
div.region-selector-pharm select {
  border: 1px solid rgba(0, 149, 255, 0);
 /* background-color: #f7f7f7; */
  color: #000;
}

div.country-selector-pharm select:hover,
div.region-selector-pharm select:hover {
  border: 1px solid #0093ff;
 /* background-color: #fff; */
  color: #000;
}
.liner{
	width:100%;
	
	background-color: #f0f0f0;
}

.modal-body h4{
  color: var(--green);
}
