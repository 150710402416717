

.add-dr-form  .react-date-picker {
  
  margin-left: 10px;
}

.add-dr-form .react-date-picker__wrapper {
  border-radius: 5px;
  background-color: white;
  border: transparent;
}





.gender-radio-check .form-control, .dob-check .form-control{
      background-color: #f7f7f7;
      padding: 8px 18px;
}
.gender-radio-check input[type=radio]{
    margin-right:5px;
}
.gender-radio-check label{
  margin-right: 10px;
}

@media only screen and (max-width: 468px) {
  .add-dr-form  .gender-radio-check label{
  margin-right: 4px;
}
.add-dr-form  .react-date-picker {
  
  margin-left: 4px;
  position: absolute;
}
}