.card1{
    position: relative;
    width: 300px;
    height: 300px;
    background-color: #fff;
    color: #242329;
    text-align: center;
    border: 5px solid #fff;
    overflow: hidden;
    margin-top: 120px;
}

.card__data{
    background-image: url("../../assets/images/pro_image.png");
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(-300px);
    transition: transform .5s;
     background-position:center;  
    background-size: contain; 
    background-repeat: no-repeat;
    overflow: visible;
    background-color:#fff ;

}
.card__img{
    width:100%;
    height: 100%;
    object-fit: contain;
    transition: transform .25s .35s;
    background-position: center center; 
    background-size: contain; /* or 'contain' based on your needs */
    background-repeat: no-repeat;
    overflow: visible;

}
.card__title{
    margin-top: 30px;
    font-size: 26px;
    color:rgb(27, 174, 227);
}

.card__description{

    padding: 10px;
    margin-bottom: 15px;

}
.card__btn{
    display: inline-block;
    text-decoration: none;
    padding: 5px 10px;
    color: #ffffff;
    background-color: #34998f;
    transition: background-color .25s;

}
.card__btn:hover{
    background-color: #242329;
    color:#fff;

}
.card1:hover .card__data{
    transform: translateY(0px);
}
.card1:hover .card__img{
    transform: translateX(300px);
    transition-delay: 0s;
}

.input-group{
    position: relative;
}
.input{
    padding: 10px;
    border:none;
    border-radius: 4px;
    font:inherit;
    color:#fff;
    background-color: transparent;
    outline: 2px solid #fff;
}
.input-label{
    position: absolute;
    top:0;
    left:0;
    transform: translate(10px,10px);
    transition:transform .25s;

}
.input:focus+.input-label, .input:valid+.input-label{
transform: translate(10px,-14px) scale(.8);
color:#d1c5fc;
padding-inline: 5px;
background-color: #242329;
}
.input:is(:focus, :valid){
    outline-color: #d1c5fc;
}