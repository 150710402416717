.create-lab-user-form{

	box-shadow: 4px 4px 10px rgba(0,0,0,0.3);
	padding:30px;
	margin:40px auto 40px auto;
}


.liner{
	width:100%;
	
	background-color: #f0f0f0;
}
.postfix-username{
	width:200px;
}
.pharmuser-details{
	color: grey;

}
.btn-outline-primary{
	border-color: #ffffff;
	
}