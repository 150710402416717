.leftsidewindow{
    width: 267px;
height: 274px;

border-radius: 10px;
border: 1px solid #C6C6C6;;

background-color: #FFFFFF;

}

.searchboxindside{
height: 34px;
margin-top: 15px;
border-radius: 30px;
border: 1px solid #BDBDBD;

}
.approverequset{
height: 54px;
color:white;
border-radius: 10px;
border: 1px solid #6D4482;
background-color: #6D4482;
margin-left: 15px;
margin-top: 10px;
}
.rejectedrequests{
height: 54px;
color:black;
border-radius: 10px;
border: 1px solid #6D4482;
background-color: #F8F8F8;
margin-left: 15px;
margin-top: 10px;
}
/* .{
    
    width: 100%;
    height: 75px;
   margin-left: 6%;
   margin-top: 10px;
    border-radius: 10px;
    border: 1px solid #C6C6C6;
    

} */

.admissioninputs{
  
  height:40px;
  border: 1px solid rgba(204, 204, 204, 1); 
     background: rgba(255, 255, 255, 1);
 border-radius: 8px;
 width:100%
}
.ads-button{
   
    height: 45px;
   width:100%;
    margin-bottom: 2%;
    color: #FFFFFF;
    background: #1269B9;
    border-radius: 8px;
}
.patientlistview{
    
    
  height: 705px;
  width:100%;
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid #C6C6C6;
  
}
.patientlistview {
display: "flex";
align-items: "center";
margin-bottom: "20px";
    }

/* Default styles for your container */
.container {
    width: 100%; /* Use the full width of the parent container */
    max-width: 1200px; /* Add a maximum width to prevent it from becoming too wide */
    margin: 0 auto; /* Center the container horizontally */
    padding: 20px;
    box-sizing: border-box; /* Include padding in the width */
  }
  
  /* Media query for smaller screens, e.g., tablets */
  @media (max-width: 768px) {
    .container {
      padding: 10px; /* Reduce padding for smaller screens */
    }
  }
  
  /* Media query for even smaller screens, e.g., mobile phones */
  @media (max-width: 480px) {
    .container {
      padding: 5px; /* Further reduce padding for mobile screens */
    }
  }
  