.full-div{
    
box-sizing: border-box;

margin-top: 2%;
padding:2%;
background: #FFFFFF;
border: 1px solid #B7B7B7;
border-radius: 30px;
}
.vl {
    border-left: 2px solid  #11C057;
    height: 30px;
  }
  .v2 {
    border-left: 2px solid  #696d6b;
    height: 30px;
  }

.order-devider{
    position: absolute;
width: 5px;
height: 600px;


background: #F5F5F5;
box-shadow: 4px 1px 4px rgba(0, 0, 0, 0.25);
border-radius: 30px;
}
.horizondal{
    margin-left: 2.5%;
   
}