.parent-of-lists{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  
}
.each-list-title {
  padding: 15px;

  border-radius: 5px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  text-align: left;
  transition: all 0.3s ease;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  background-color: var(--theme-blue);
  text-align: center;
  color: white !important;
 
}
.each-list-cal {
  padding: 15px;

  border-radius: 5px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  text-align: left;
  transition: all 0.3s ease;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  background-color: var(--theme-blue);
  text-align: center;
  
 
}
.each-list-title img{
  margin:20px auto 30px auto;

 
}
.boxpwd{
  /* padding-left:5px; */
  border:0px;
  margin-right:100px;
  color:#6e6767;
  box-sizing: border-box;
  width: 180px;
    height: 44px;
    background: #FFFFFF;
   
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 15px;
}
.boxpwdconfig{
  /* padding-left:5px; */
  border:0px;
  margin-right:100px;
  color:#6e6767;
  box-sizing: border-box;
  width: 165px !important;
    height: 44px;
    background: #FFFFFF;
   
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 15px;
}
.boxpwdverify{
  padding-left:5px;
  border:0px;
  margin-right:100px;
  color:#6e6767;
  box-sizing: border-box;
  
    height: 44px;
    background: #FFFFFF;
  
    border-radius: 5px;
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 15px;
}
.boxpwd1{
  border:0px;
 
  color:#6e6767;
  box-sizing: border-box;
  width: 100%;
    height: 44px;
    background: #FFFFFF;
   
    border-radius: 5px;
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 15px;
}
.boxpwd2{
  border:0px;
 
  color:#6e6767;
  box-sizing: border-box;
  width: 100%;
    height: 60px;
    background: #FFFFFF;
   
    border-radius: 5px;
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 15px;
}
.title_config{
text-align: center;
color: #7D0752!important;
text-transform: capitalize !important;
font-weight: bolder;
margin-top: 10px;
}
.title_aggr{
  text-align: center;
  text-transform: capitalize !important;
  font-weight: bolder;
  margin-top: 10px;
  }
.each-list-title h4{
  
  font-weight: bold;
}
.each-list-title h5{
  color:#f0f0f0;
}


.each-list-title:hover {
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.3);
  background-color: var(--theme-dark-blue);
}
.each-title a {
  text-transform: uppercase;
  color: #f0f0f0;
}


.each-list-title i{
  color: var(--theme-icons-purple) !important;
  font-size:35px;
  margin:10px auto 15px auto;
}

.parent-of-lists a{
  text-decoration: none;
}

.task-box{
  height:240px;
}
.staff-jumbo{
  background-color: var(--theme-lightest-grey)!important;
}

.box-container .each-list-title{
  width:250px !important;
  height:220px !important;
   margin: 30px !important;
}
.box-container .each-list-titile .each-title{
  font-size: 1.3rem !important;
}

.divide-line{
  margin: 50px auto 30px auto!important;
  width:80% !important;
}

.seperator {
  width: 100%;
  background-color: #bcbcbc;
  border-radius: 10px;
  height: 2px;
  
  margin: 20px auto;
}

.each-subtitle h6{
  font-weight:bold;
}

.general-configuration
{
  width: 1850px;
height: 1459px;

background: #FFFFFF;
}

.genaral-text{
  position: absolute;
width: 300px;
height: 18px;
left: 800px;
top: 148px;

font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 24px;

color: #7D0752;
}
.avatar1 {
  
  width: 196.96px;
  height:  99.65px;
 
  border: 6px solid white;
  background-color: #f0f0f0;

  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
}
.side-viewbar{
position: absolute;
width: 214px;
height: 257px;
left: 12px;
top: 345px;
color:#FFFFFF;
background: #7D0752;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 0px 20px 20px 0px;
}
.side-viewbar1{
 margin-top: 30%;
  width: 180px;
  height: 200px;
  margin-left: 2%;
  color:#FFFFFF;
  background: #7D0752;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 20px 20px 0px;
  }
.side-viewbar1 .general{
  background-color:#c9c1c1 !important;
}

.genaral-infobar{
   position: absolute;
  width: 214px;
  height: 42px;
  left: 1px;
  top: 47px;
  
  background: #FFFFFF;
  border-radius: 0px;
}
.genaral-infobar2{
  position: absolute;
 width: 214px;
 height: 42px;
 left: 1px;
 top: 110px;
 
 background: #FFFFFF;
 border-radius: 0px;
}

.general-info{

width: 200px;
height: 13.26px;
left: 57px;
top: 450px;

font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 18px;

color: #8A8A8A;
}
.span-reset{
  background-color:white;
   color: #7e7e7e;
}
.second-part{
 
  
  background: #f4f2f2;
  /* box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 20px 20px 20px 20px;
}
.second-part2{
  text-align: center;
 width: 500px;
  height:250px;
  background: #f4f4f4;
  
  border-radius: 20px 20px 20px 20px;
}
.second-part1{
  
  box-shadow: 4px 0px 0px #967169;
  /* border-radius: 20px 20px 20px 20px; */
  /* border-radius: 20px 20px 20px 20px; */
}

.text-style-here
{ 

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 15px;
color: #000000;
}
.inputbox1{
  position: absolute;
box-sizing: border-box;


width: 237px;
height: 31px;
left: 200px;
top: 70px;

background: #FFFFFF;
box-shadow: 0px 0px 8px rgba(188, 188, 188, 0.25);
border-radius: 5px;
}

.inputbox2{
box-sizing: border-box;

position: absolute;
width: 237px;
height: 31px;
left: 572px;
top: 70px;

background: #FFFFFF;
box-shadow: 0px 0px 8px rgba(188, 188, 188, 0.25);
border-radius: 5px;
}

.text-style-here1{
position: absolute;
width: 215px;
height: 10px;
left: 578px;
top: 50px;

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 15px;

color: #000000;
}

.text-style-here2{
position: absolute;
width: 250px;
height: 10px;
left: 200px;
top: 120px;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 15px;

color: #000000;
}
.form-controlconf{
  position: absolute;
/* width: 237px;
height: 31px; */
margin:20px;

background: #FFFFFF;
box-shadow: 0px 0px 8px rgba(188, 188, 188, 0.25);
border-radius: 5px;
}
.inputbox3{

position: absolute;
width: 237px;
height: 31px;
left: 200px;
top: 140px;

background: #FFFFFF;
box-shadow: 0px 0px 8px rgba(188, 188, 188, 0.25);
border-radius: 5px;
}

.text-style-here3{
  position: absolute;
  width: 200px;
  height: 10px;
  left: 578px;
  top: 120px;
  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 15px;
  
  color: #000000;
  }
  
  .inputbox4{
  
    position: absolute;
    width: 237px;
    height: 31px;
    left: 572px;
    top: 140px;
    
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(188, 188, 188, 0.25);
    border-radius: 5px;
  }



        .text-style-here4{
          position: absolute;
          width: 47px;
          height: 10px;
          left: 200px;
          top: 180px;
          
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 15px;
          
          color: #000000;
          
          }
          
          .inputbox5{
          
            position: absolute;
            width: 504px;
            height: 30px;
            left: 200px;
            top: 200px;
            
            background: #FFFFFF;
            box-shadow: 0px 0px 8px rgba(188, 188, 188, 0.25);
            border-radius: 5px;
          }

          .text-style-here5{
            position: absolute;
            width: 200px;
            height: 10px;
            left: 200px;
            top: 250px;
            
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 15px;
            
            color: #000000;
            }
            
            .inputbox6{
            
              position: absolute;
              width: 504px;
              height: 125px;
              left: 200px;
              top: 270px;
              
              background: #FFFFFF;
              box-shadow: 0px 0px 8px rgba(188, 188, 188, 0.25);
              border-radius: 5px;
            }

            .text-style-here6{
              position: absolute;
              width: 66px;
              height: 10px;
              left: 200px;
              top: 400px;
              
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 400;
              font-size: 20px;
              line-height: 15px;
              
              color: #000000;
              }
              
              .inputbox7{
              
                position: absolute;
                width: 237px;
                height: 30px;
                left: 200px;
                top: 420px;
                
                background: #FFFFFF;
                box-shadow: 0px 0px 8px rgba(188, 188, 188, 0.25);
                border-radius: 5px;
              }


              .central-line{
                position: absolute;
width: 440px;
height: 0px;
margin-right: 8%;
top: 224px;

border: 4px solid #c9c1c1;
transform: rotate(90deg);
              }
           

.appName-bar{
  position: absolute;
width: 200px;
height: 10px;
left: 1150px;
top: 50px;

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 15px;

color: #000000;
}


.inputbox9{
              
  box-sizing: border-box;

position: absolute;
width: 265px;
height: 31px;
left: 1150px;
top: 70px;

background: #FFFFFF;
box-shadow: 0px 0px 8px rgba(188, 188, 188, 0.25);
border-radius: 5px;
}


.apps-logobar{
  
width: 196.96px;
height: 99.65px;


background: #C6C6C6;
border-radius: 10px 10px 0px 0px;
}

.apps-logobar-inside{

  left: 0.01%;
  right: -0.01%;
  
  background: #747474;
}



.apps-logo-bottom{
 border:none;
width: 196.96px;
height: 31.23px;

color:#FFFFFF;
background: #7D0752;
border-radius: 0px 0px 10px 10px;
}

.apps-logo-head{
  position: absolute;
width: 200px;
height: 9px;
left: 1200px;
top: 180px;

font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 15px;

color: #000000;
}

.apps-logo-head2{
  position: absolute;
  width: 200px;
  height: 10px;
  left: 1200px;
  top: 350px;
  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 15px;
  
  color: #000000;
}
.nos{
  height: 25px;
width: 70px;
border-radius: 15px;
background:#7D0752;
color: #ffffff;
padding-left: 15px;

}

.apps-logobar1{
  width: 197px;
height: 110.65px;;
color:#7D0752;
border: solid 2px #7D0752; 
  background: #ffffff;
  border-radius: 10px;
}
.apps-logobar2{
  width: 197px;
  height: 110.65px;;
  color:#C6C6C6 ;
border: solid 2px #C6C6C6; 
  background: #ffffff;
  border-radius: 10px;
}

.apps-logobar-inside1{
  position: absolute;
left: 0.01%;
right: -0.01%;
top: 6.25%;
bottom: 6.25%;

background: #747474;
}



.apps-logo-bottom1{
  position: absolute;
  width: 196.96px;
  height: 31.23px;
  left: 1150px;
  top: 480px;
  color:#FFFFFF;
  background: #7D0752;
  border-radius: 0px 0px 10px 10px;
}

.app-logo-side{
  position: absolute;
width: 89px;
height: 24px;
left: 1400px;
top: 250px;

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 15px;

color: #000000;
}
.app-logo-side2{
  position: absolute;
width: 89px;
height: 23px;
left: 1400px;
top: 420px;

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 15px;

color: #000000;
}

.backofficde-sec{
  position: absolute;
width: 187px;
height: 18px;
left: 820px;
top: 800px;

font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 24px;

color: #7D0752;
}


.username-head{
  position: absolute;
width: 104px;
height: 10px;
left:470px;
top: 850px;

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 15px;

color: #000000;

}

.user-name-box{

  box-sizing: border-box;

position: absolute;
width: 237px;
height: 31px;
left: 470px;
top: 870px;

background: #FFFFFF;
box-shadow: 0px 0px 8px rgba(188, 188, 188, 0.25);
border-radius: 5px;
}

.password-head{

position: absolute;
width: 104px;
height: 10px;
left: 780px;
top: 850px;

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 15px;

color: #000000;
}

.password-box{
  box-sizing: border-box;

position: absolute;
width: 237px;
height: 31px;
left:780px;
top: 870px;

background: #FFFFFF;
box-shadow: 0px 0px 8px rgba(188, 188, 188, 0.25);
border-radius: 5px;
}

.confirm-pass{
  position: absolute;
width: 200px;
height: 10px;
left: 1080px;
top: 850px;

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 15px;

color: #000000;
}

.confirm-box{

box-sizing: border-box;

position: absolute;
width: 237px;
height: 31px;
left: 1080px;
top: 870px;

background: #FFFFFF;
box-shadow: 0px 0px 8px rgba(188, 188, 188, 0.25);
border-radius: 5px;
}
.save{
 border:none;
  width: 94px;
  height: 44px;
  color:#fcfcfc;
  background: #7D0752;
  box-shadow: 0px 0px 8px rgba(188, 188, 188, 0.25);
  }
.save-button{
 width:"40px ";
border: none;
height: 44px;
background:#ffffff;
color: #077d58e9;
box-shadow: 0px 0px 8px rgba(188, 188, 188, 0.25);
}


.save-button1{
  width:"40px ";
  border: none;
  height: 60px;
  background:#ffffff;
color: #077d58e9;
  box-shadow: 0px 0px 8px rgba(188, 188, 188, 0.25);
  }

.edit{
 border:none;
  min-width: 94px;
  height: 44px;
  color:#f0f0f0;
  background: #967169;
  box-shadow: 0px 0px 8px rgba(188, 188, 188, 0.25);
  }

.edit-button{
  width:"40px ";
  border: none;
height: 44px;
background:#ffffff;
color: #0350f6a7;
box-shadow: 0px 0px 8px rgba(188, 188, 188, 0.25);
}
.edit-button1{
  width:"40px ";
  border: none;
  height: 60px;
  background:#ffffff;
  color: #0350f6a7;
  box-shadow: 0px 0px 8px rgba(188, 188, 188, 0.25);
  }
.pwd-button{
 
  border: none;
height: 44px;
color:#565656;
background: #ffffff;
/* box-shadow: 0px 0px 8px rgba(188, 188, 188, 0.25); */
}
.save-h3{

position: absolute;
width: 31px;
height: 12px;
left: 649px;
top: 891px;

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 15px;

color: #FFFFFF;
}

.contact-inside{
  position: absolute;
width: 25px;
height: 7px;
left: 587px;
top: 326px;

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 15px;

color: #000000;

}