/* from ioform-style.css */
div.country-selector select,
div.region-selector select {
  width: 100%;
  padding: 9px 20px;
  text-align: left;
  border: 0;
  outline: 0;
  border-radius: 6px;
  background-color: #fff;
  font-size: 15px;
  font-weight: 300;
  color: #8d8d8d;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-bottom: 14px;
}

div.country-selector select:hover,
div.region-selector select:hover {
  border: 0;
  background-color: #ebeff8;
  color: #8d8d8d;
}

/*from iofprm-theme4.css */
div.country-selector select,
div.region-selector select {
  border: 1px solid rgba(0, 149, 255, 0);
  background-color: #f7f7f7;
  color: #000;
}

div.country-selector select:hover,
div.region-selector select:hover {
  border: 1px solid #0093ff;
  background-color: #fff;
  color: #000;
}
