.home-icon {
  color: var(--theme-purple);
  font-size: 25px;
  margin: 25px 20px;
  display: inline-block;
}
.home-icon-div {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 10px;
}
.home-icon p {
  font-family: "Roboto", sans-serif;
  display: inline-block;
  text-transform: uppercase;
  font-size: 18px;
  margin-left: 5px;
  font-weight: 600;
}

.home-icon:hover {
  cursor: pointer;
  color: var(--theme-icons-purple);
}


.hosp-navbar .btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  margin-right: 20px;
  align-items: center;
}
.hosp-navbar .btns button{
  margin-right: 20px;
 
}
.hosp-navbar .btns button:hover{
  
  background-color: transparent !important;
}
.hosp-navbar {
  background-color:#9ADFCC;
  /*background-image: linear-gradient(white, rgba(0,0,0,0.1)); */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
  box-shadow: 3px 5px 10px rgba(0,0,0,0.2);
  height:65px;
  
  position: sticky;
  top:0;
  box-shadow: 5px 5px 5px rgba(0,0,0,0.2);
  z-index:1000;
 
}

.icon-user{
  font-size:25px !important;
  color: var(--theme-purple) !important;
 
  
}

.icon-menu{
   font-size:25px !important;
 color: var(--theme-purple) !important;
  
}

.icon-menu:hover{
  transition: 0.2s ease-in;
   color: var(--theme-icons-purple) !important;
  cursor: pointer;
}

.icon-user:hover{
   color: var(--theme-icons-purple) !important;
  cursor: pointer;

}




.nav-btn{
  background-color:#9adfcc;
  box-shadow: none !important;
  
}


/* .nav-btn a:hover, .nav-btn a:active{
  color: var(--theme-blue) !important;
   
}

.active{
   color: var(--theme-blue) !important;
}
.inactive{
  color: #fff;
} */

.notification {

  display: inline-block;
  text-decoration: none;
  position: relative;

}



.notification .badge {
  position: absolute;
  top: -8px;
  right: -6px;
  border-radius: 50%;
  font-size: small;
  font-weight: normal;
  background-color: red;
  color: white;
}