.service-req-table{
	
	margin:auto;
	width:auto;
	max-width:98vw;
	
}
.service-req-table .rt-body{
	width:100%;
}
.service-table-holder{
margin: auto;

}
.flex-col{
	width: 96%;
}
.SR-basic-table{
	max-width:100% !important;
	width:100% !important;
}
.service-req-table input{
	width: 97% !important;
}

.service-req-table .rt-td, .service-req-table .rt-th,.service-req-table .rt-td *{
 overflow: visible;
 font-size: small;
}

.service-req-table .rt-thead .rt-th{
	background-color: var(--dark-teal)!important;
}

.service-req-table .rt-thead:nth-child(1){
	background-color: var(--dark-teal)!important;
}
.service-req-table .rt-thead .rt-tr:nth-child(1){
	background-color: var(--dark-teal)!important;
}

.service-req-table .rt-td >div {
	
	margin: auto;
}


.status{
	
	padding:3px;

	padding:8px;
	color:white;
}

.status-select{
	background-color: grey;
}
.new-status .css-yk16xz-control {
	background-color: rgb(9, 120, 184);;

}
.ongoing-status .css-yk16xz-control{
	background-color: var(--flat-green);
		

}
.accepted-status .css-yk16xz-control{
	background-color: var(--flat-yellow);
		

}
.completed-status .css-yk16xz-control{
	background-color: green;
		

}
.rejected-status .css-yk16xz-control{
	background-color: red;
		

}
.delivered-status .css-yk16xz-control{
	background-color: rgb(212, 95, 193);
		

}
.pending-status .css-yk16xz-control{
	background-color: #dedede;
		

}
.cancelled-status .css-yk16xz-control{
	background-color: var(--flat-red);
		

}
.accepted-status-2{
	background-color: orange;
	color:white !important;
	border-radius: 4px;
}

.new-status-2 {
	background-color: rgb(9, 120, 184);;
	color:white !important;
	border-radius: 4px;
}
.ongoing-status-2{
	background-color: var(--flat-green);
   color:white !important;
	border-radius: 4px;		
  
}
.accepted-status-2{
	background-color: orange;
	color:white !important;
	border-radius: 4px;
}



.completed-status-2{
	background-color: green;
	color:white !important;
	border-radius: 4px;
}

.rejected-status-2 {
	background-color: red;
	color:white !important;
	border-radius: 4px;
}
.pending-status-2 {
	background-color: rgba(0,0,0,0.2);
	color:white !important;
	border-radius: 4px;
}
.cancelled-status-2 {
	background-color: var(--flat-red);
	color:white !important;
	border-radius: 4px;
}
 .accepted-status .css-1uccc91-singleValue, .new-status .css-1uccc91-singleValue,.ongoing-status .css-1uccc91-singleValue{
   	
 }
.priority{
	
	font-weight: bolder;
}
.high-priority .css-1uccc91-singleValue{
	color:var(--flat-red);
		font-weight: bolder;
	
	
	

}
.very-high-priority .css-1uccc91-singleValue{
	color:var(--flat-red);
		font-weight: bolder;
	
	
	

}
.medium-priority .css-1uccc91-singleValue{
	color: orange;
		font-weight: bolder;

	
	
}


.low-priority .css-1uccc91-singleValue{
	color:var(--flat-blue);
		font-weight: bolder;
	
	
	
	
}
.critical-priority  .css-1uccc91-singleValue{
	color:red;
	font-weight: bolder;
	
	
	
}
.high-priority-2 {
	color:var(--flat-red);
		font-weight: bolder;
	
	
	

}
.very-high-priority-2 {
	color:var(--flat-red);
		font-weight: bolder;
	
	
	

}
.medium-priority-2 {
	color: orange;
		font-weight: bolder;

	
	
}


.low-priority-2 {
	color:var(--flat-blue);
		font-weight: bolder;
	
	
	
	
}
.critical-priority-2{
	color:red;
	font-weight: bolder;
	
	
	
}
.critical-priority  .css-1uccc91-singleValue::before{
	content:"";
	
	
	
}
.service-req-table .rt-td .status-select{
	overflow:visible !important;
}

.service-req-table .rt-td  .css-tlfecz-indicatorContainer, .service-req-table .css-1gtu0rj-indicatorContainer{
 padding:2px !important;
}
.each-option-suggestion{
	margin-bottom:3px;
	/* border-bottom: 2px solid grey; */
	cursor: pointer;
	background-color: var(--flat-blue);
	color:white !important;
}


.text-area-remarks{
	height:auto;
	min-height: 70px;
	max-height: 140px;
}
.overlay-address{
	color:var(--flat-blue);
	text-decoration: underline;
	cursor: pointer;
}
.info-notes-sr{

}
.info-div{
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-items: flex-start;
	background-color: rgba(0,0,0,0.1);
	margin:10px;
	padding:10px;
	border-radius: 5px;

}
.bold-text{
	font-weight: bolder;
}

.suggestions-box-holder{ position:relative; }
.btn-suggestion { position:absolute; left:80%; top:0px }


  .service-req-table .rt-td {
	 min-height:40px;
}
.service-req-table .rt-td:nth-child(1) {
	 height:40px;
}
 
  
   .service-req-table{
   	position:relative;
   
   }
   .service-req-table .rt-th:nth-child(2),.service-req-table .rt-td:nth-child(2) {
   
    /* margin-left:50px; */
  }

.info-div-2{
	box-shadow: 4px 4px 14px rgba(0,0,0,0.1);
	background-color: white !important;
}
.service-req-table .rt-table .rt-tbody .status-select .css-26l3qy-menu{
	max-height:180px !important;
	overflow: auto;
	z-index:2000 !important;
}
.service-req-table{
	overflow: visible!important;
}
.add-remark{
	color: white;
	background-color: red;
	border-radius: 50%;
	padding: 5px;
	font-size: 30px;
	cursor: pointer;
}
.initial-remarks{
	/* background-color: var(--theme-blue); */
	border-radius: 5px;
	box-shadow: 4px 4px 14px rgb(0 0 0 / 20%);
	padding: 5px 20px;
	min-width: 100px;
	max-width: 400px;
	font-size: large;
	color: white;
}
.remark-edit{
	width: 80%;
	height: 100px;
}
.remark-add{
	width: 80%;
	height: 150px;
}

@media only screen and (min-width: 600px) {
    .remark-width {
      max-width: 600px;
      
    
       /* New width for default modal */
    }
    .remark-width {
      max-width: 600px;
   
       }
  }

  .add-remark-btn{
	color:white;
	background-color: var(--dark-blue);
	padding:6px 10px;
	border-radius: 50%;
	outline: none;
	border:none;
}
.add-remark-btn:hover, .add-remark-btn:focus{
	box-shadow: 0 5px 6px rgba(80, 182, 255, 0.31);
	background-color: #3464b6;
    outline:none;
}
.comment-head{
	color: var(--theme-dark)!important;
	font-weight: 600;
	text-align: left;
}
.comment-container{
	background-color: #F0F0F0;
	box-shadow: 0 5px 6px #BEBEBE;
	border-radius: 8px;
	padding: 15px;
	margin: 10px;
	width: 100%;
}
.comment-container span{
	color: var(--theme-blue);
	font-weight: 600;
}
.comment-container i{
	color: var(--theme-card-dark-blue);
}
.comment-container .comment-span{
	color: var(--theme-dark);
	font-weight: 500;
	margin-top: 20px !important;
	float: left;
}
.comment-flex{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
}
.comment-flex .current-comment{
	font-weight: 600;
	color: var(--theme-dark);
	font-size: 18px;
}
.comment-history{
	font-weight: 600;
	color: var(--theme-dark);
	font-size: 18px;
	align-self: flex-end;
	margin-top: -30px;
}
.comment-flex .fa-arrow-circle-right{
	font-weight: 600;
	color: var(--theme-blue);
	font-size: 25px;
	cursor: pointer;
}
.comment-flex .fa-arrow-circle-right:hover{
	color: #033841;
}
.arrow-ic{
	font-weight: 600;
	color: var(--theme-blue);
	font-size: 25px;
	cursor: pointer;
}
.arrow-ic:hover{
	color: #033841;
}
.fixed-co{
	overflow: auto !important;
}
.pharmacybuttons{
	width: 120px;
height: 40px;
top: 167px;
left: 82px;
border-radius: 5px;

}