.rt-td {
    border-left: 2px solid #cdcdcd;
}
.addH5 svg {
	float: right;
    margin-top: 5px;
}
.addH5 svg.opened {
	transform: rotate(180deg);

}

.span-date1{
	margin-left:35% ;
}
Accordion.Collapse{
	position: absolute;
  z-index: 1;
}
 .addH5{
	color: rgb(1, 18, 19)!important;
}

.food-habits-sections .card {
   border : 0px;
}

.food-habits-sections .card-header {
   border : 0px;
   padding-top: 12px;
   cursor: pointer;
   background-color: rgb(125, 216, 228);
  
}

.svgHeader {
	margin-top: 5px;
    float: right;
}

.addbppage{
    width:20px;
}
