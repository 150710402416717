.inventory-table .rt-thead {

    background-color: rgb(219, 227, 227);
    color: black !important;
}

.inventory-table .rt-thead .rt-resizable-header-content {
    color: black !important;
}

.inventory-table .rt-tbody {
    background-color: rgb(255, 255, 255) !important;
}

.inv-button {
    width: 130px;
    height: 40px;
    float: right;
    margin-right: 2%;
    color: #FFFFFF;
    background: #1269B9;
    border-radius: 10px;
}

.inv-button:hover {
    font-size: large;
    box-shadow: 0px 4px 4px rgba(43, 37, 211, 0.25);

}

.add-inventory {

    background: #E4DD9F;
   
    border-radius: 16px;
     padding: 2% 2% 2% 2%;
}
