.procedure-navbar {
    background-color:whitesmoke;
    /*background-image: linear-gradient(white, rgba(0,0,0,0.1)); */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    box-shadow: 3px 5px 10px rgba(0,0,0,0.2);
    height:65px;
    
    top:0;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.2);
    z-index:1000;
    
  }
  .procedure-navbar1 {
    background-color: #e4dd9f!important;
    /*background-image: linear-gradient(white, rgba(0,0,0,0.1)); */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    box-shadow: 3px 5px 10px rgba(0,0,0,0.2);
    height:100px;
    
    top:0;
   
    z-index:1000;
    
  }
  .css-1s2u09g-control1{
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0!important;
    position: relative;
    -webkit-transition: all 300ms;
    box-sizing: border-box;
    transition: all 300ms linear;
  }
  .procedure-auto-suggest1 {
    width: 598px !important;
    position: fixed;
    top: 175px;   
    z-index: 1900;
}

  
  .procedure-form-width{
    width: 150px !important;
    height: 40px !important;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  
  .procedure-form{
    display: flex !important;
    flex-flow: row wrap;
    align-items: center;
  }
  
  .procedure-auto-suggest {
    width: 250px !important;
    position: absolute;
    top: 355px;
    margin-left: 170px;
    z-index: 1900;
  }
  
  .select-currency .css-26l3qy-menu {
    z-index: 1900;
  }
  .focused {
    border: 1px solid #0093FF;
    background-color: #EBEFF8;
    color: #000;
  }
  .suggest-display{
	background-color: #F8F8F8;
	border-radius: 3px;
	padding: 0px;
	width: 100%;
	height: 200px;
	overflow: scroll;
  margin-top: 1%;
	box-shadow: 2px 2px 2px #BEBEBE;
}

.rt-thead {
    color: rgb(254, 255, 254) !important;
    font-size: 20px;
    background-color: rgb(13, 197, 197);
}

.suggest-item{
	padding: 10px;
	text-align: left;
	font-weight: 600;
}
.suggest-item:hover{
	background-color: #DCDCDC;
	cursor: pointer;
}