.each-card{
    flex: 1 1 auto;
    padding: 1.5rem 1.5rem;
    background-color: white;
   border-radius: 20px;
    margin-bottom: 1%;
}
.each-card-nurse{
    padding:2%;
    background-color: white;
   border-radius: 20px;
    margin-bottom: 1%;
}
#sidebar-menu .menu-title {
   
    letter-spacing: .05em;
    pointer-events: none;
    cursor: default;
    font-size: 14px;
    text-transform: uppercase;
    color: lightslategray;
    font-weight: 600;
}
#sidebar-menu li {
    display: list-item;
    text-align: -webkit-match-parent;
    padding: 10px 20px;
    margin-left: 1%;
}
#sidebar-menu>ul {
    list-style: none;
    padding: 0;
}
.collapse:not(.show) {
    display: revert;
}
.wrapper {
    border: 3px solid blue;
  }
  
  
  
  .barCompleted {
    background-color: lightblue;
    width: 80%;
  }
  
  .label {
    font-size: 20px;
    color: green;
  }
  

  .inbox-widget {
    .inbox-item {
        border-bottom: 1px solid black;
        overflow: hidden;
        padding: 0.75rem 0;
        position: relative;
        &:last-of-type {
            border-bottom: none;
        }
        .inbox-item-img {
            display: block;
            float: left;
            margin-right: 15px;
            width: 40px;
            img {
                width: 40px;
            }
        }
        .inbox-item-author {
            display: block;
            margin-bottom: 3px;
        }
        .inbox-item-text {
            /* color: $text-muted; */
            display: block;
            font-size: 0.8125rem;
            margin: 0;
            overflow: hidden;
        }
        .inbox-item-date {
            /* color: #{map-get($grays, "600")}; */
            font-size: 0.6875rem;
            position: absolute;
            right: 5px;
            top: 10px;
        }
    }
}
.icons-right{
   background-color: rgb(43, 147, 160);
   text-align: center;
   color:white;
   padding:4%;
   border-radius: 10px;

}
.icons-right-not{
   border:2px solid rgb(43, 147, 160);
   background-color:white;
    text-align: center;
    color:rgb(43, 147, 160);
    padding:4%;
    border-radius: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
 }
 .ct-series-a .ct-slice-pie{
    fill:#83f783 !important;
 }
 .ct-series-d .ct-slice-pie{
    fill:#1dc7ea !important;
 }
 .tree-leaf-list-item {
    list-style-type: none;
 }
 .tree-branch-wrapper{
    list-style-type: none;
 }
 .basic.tree {
    list-style: none;
    margin: 0;
    padding: 20px;
  }
  .basic .tree-node,
  .basic .tree-node-group {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .basic .tree-branch-wrapper,
  .basic .tree-node__leaf {
    outline: none;
  }
  
  .basic .tree-node--focused {
    outline-color: rgb(77, 144, 254);
    outline-style: auto;
    outline-width: 2px;
    display: block;
  }
  
  .basic .tree-node__branch {
    display: block;
  }
  
  .basic .tree-node {
    cursor: pointer;
  }
  ul.tree{
    padding:0px 0px 0px 3px;
  }
 